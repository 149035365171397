export const calculateDiscountedPrice = (
  price: number,
  saleDiscount: number
) => {
  const x = price! * (1 - saleDiscount! / 100)
  const finalprice = x.toFixed(2)
  return `${finalprice}`
}

export const divideValueBy100 = (value: number) => value / 100
export const multiplyValueBy100 = (value: number) => value * 100

export const transformNameIntoKebabName = (name: string) => {
  return name
    .toLowerCase()
    .split(' - ')
    .map((section) => section.split(' ').join('-'))
    .join('-')
}

export const createLinkToProduct = (id: string, name: string) => {
  const currentUrl = window.location.hostname

  const websiteUrl =
    process.env.NODE_ENV === 'development' ||
    currentUrl === 'staging.backoffice.styleyield.com' ||
    currentUrl === 'staging.styleyield.com' ||
    currentUrl === 'localhost'
      ? 'https://staging.styleyield.com/product/'
      : 'https://www.styleyield.com/product/'

  const productLinkString = transformNameIntoKebabName(name)

  return `${websiteUrl}${productLinkString}-id-${id}`
}

export const createStringWithPriceRange = (
  sources: Array<{ price: number; currency: string }>,
  label: string
) => {
  const currency = sources.map((source) => source.currency)[0]

  let priceRange = '-'

  const prices = sources.map((source) => divideValueBy100(source.price))

  const minPrice = `${Math.min(...prices)}${currency}`

  const maxPrice = `${Math.max(...prices)}${currency}`

  priceRange =
    minPrice === maxPrice ? minPrice : `${minPrice} ${label} ${maxPrice}`

  return priceRange
}
