import React, { FC } from 'react'

interface Props {
  isHighlighted?: boolean
}

const DashboardSmall: FC<Props> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 7H7V6H12V7Z" fill="hsla(0, 0%, 65%, 1)" />
    <path d="M7 10H10V9H7V10Z" fill="hsla(0, 0%, 65%, 1)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H13ZM6 3H13V13H6V3ZM5 13H3L3 3H5V13Z"
      fill="hsla(0, 0%, 65%, 1)"
    />
  </svg>
)

DashboardSmall.defaultProps = {}

export default DashboardSmall
