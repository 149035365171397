import React, { FC } from 'react'

interface Props {
  isDisabled?: boolean
}

const TableChevronLeftDouble: FC<Props> = ({ isDisabled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={isDisabled ? '0.2' : '1'}
    >
      <path d="M11.5 8L7.5 12L11.5 16" stroke="hsl(0, 0%, 10%)" />
      <path d="M16.5 8L12.5 12L16.5 16" stroke="hsl(0, 0%, 10%)" />
      <rect x="0.5" y="0.5" width="23" height="23" stroke="hsl(0, 0%, 20%)" />
    </svg>
  )
}

TableChevronLeftDouble.defaultProps = {}

export default TableChevronLeftDouble
