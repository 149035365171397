import React, { FC } from 'react'

import LogoHorizontal from '../Logos/LogoHorizontal'

import css from './loadingIndicator.module.css'

interface Props {}

const LoadingIndicator: FC<Props> = () => (
  <div className={css.host}>
    <LogoHorizontal mode={'dark'} />
  </div>
)

LoadingIndicator.defaultProps = {}

export default LoadingIndicator
