import React, { FC } from 'react'

interface Props {
  mode: 'dark' | 'light'
}

const LogoHorizontal: FC<Props> = ({ mode }) => {
  return (
    <svg
      width="180"
      height="23"
      viewBox="0 0 180 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 4.65952C12.4147 3.2872 12.7977 2.17019 13.1169 1.49998C11.4573 0.478719 9.79776 0 7.88289 0C3.44677 0 0.510632 2.55316 0.510632 6.41482C0.510632 9.09564 1.85104 10.6914 5.52121 12.4786C8.1382 13.7232 8.3616 13.8509 9.12755 14.5211C9.82967 15.1275 10.1807 15.9573 10.1807 16.9785C10.1807 19.2125 8.45735 20.8721 6.12759 20.8721C3.82974 20.8721 1.88296 19.3083 1.08509 16.819H0.63829C0.574461 18.1594 0.319145 20.0104 0 21.0955C2.04253 22.0848 3.54251 22.4359 5.64887 22.4359C8.17012 22.4359 10.085 21.7976 11.5531 20.4253C12.9573 19.1168 13.7871 17.2338 13.7871 15.319C13.7871 13.7871 13.2445 12.4147 12.2233 11.4254C11.4573 10.6914 10.5956 10.1488 8.68075 9.25521C6.35099 8.17012 5.99993 7.97863 5.29781 7.468C4.4042 6.79779 3.89357 5.87227 3.89357 4.85101C3.89357 2.99997 5.42547 1.62764 7.468 1.62764C9.38287 1.62764 10.9786 2.80848 11.6169 4.65952H11.9999Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M26.5523 2.52125C28.7225 2.52125 31.116 2.68082 32.5841 2.90422C32.4884 2.39359 32.4565 2.10636 32.4565 1.69147C32.4565 1.18084 32.4884 0.925522 32.5841 0.446805C29.0735 0.638292 27.5097 0.670206 24.3183 0.670206C21.1906 0.670206 19.6906 0.638292 16.2758 0.446805C16.3715 0.989351 16.4035 1.27658 16.4035 1.7553C16.4035 2.17019 16.3715 2.4255 16.2758 2.90422C18.1907 2.6489 19.2758 2.58508 22.4353 2.52125C22.563 5.42547 22.5949 7.59566 22.5949 9.86159C22.5949 14.8403 22.4353 20.5849 22.2438 21.9891C23.3289 21.8934 23.8395 21.8614 24.414 21.8614C25.0204 21.8614 25.531 21.8934 26.7118 21.9891C26.4884 20.17 26.3608 15.9253 26.3608 10.8509C26.3608 8.04246 26.4246 5.2659 26.5523 2.52125Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M41.9552 14.0743C41.9552 16.9785 41.8275 20.2338 41.6041 21.9891C42.6892 21.8934 43.1998 21.8614 43.7743 21.8614C44.3806 21.8614 44.8913 21.8934 46.0721 21.9891C45.8806 20.521 45.7849 17.6168 45.7849 14.2977C45.7849 13.8509 45.7849 13.4041 45.8168 12.5743C47.1891 9.76584 51.0827 3.06379 52.8699 0.446805C52.1997 0.542547 51.8806 0.574462 51.4018 0.574462C50.955 0.574462 50.604 0.542547 49.9976 0.446805C49.104 2.58508 47.0296 6.67014 44.8275 10.5637C43.2636 7.72331 40.3913 1.91487 39.8488 0.446805C38.636 0.542547 38.0616 0.574462 37.3914 0.574462C36.7212 0.574462 36.1467 0.542547 34.9659 0.446805C35.4765 1.27658 35.668 1.59573 36.8807 3.76591C37.4871 4.81909 37.9339 5.61696 38.5722 6.79779C39.0828 7.6914 39.5935 8.55309 40.0722 9.4467C41.1892 11.4254 41.3488 11.7126 41.9552 12.7339V14.0743Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M56.3188 0.446805C56.5741 3.79783 56.638 5.52121 56.638 10.6914C56.638 15.7339 56.5103 19.7232 56.3188 21.9891C58.0103 21.9253 61.2975 21.8614 62.2868 21.8614C63.1804 21.8614 66.8187 21.9253 68.4463 21.9891C68.3187 21.5423 68.2868 21.3189 68.2868 20.9678C68.2868 20.6168 68.3187 20.2976 68.4463 19.6913C66.5315 19.9466 63.7549 20.1061 61.6805 20.1061C61.4251 20.1061 61.2975 20.1061 60.5954 20.0742C60.4996 18.2232 60.4358 14.7126 60.4358 11.585C60.4358 6.1595 60.4996 3.67017 60.7549 0.446805C59.9571 0.542547 59.5103 0.574462 58.7762 0.574462C57.9784 0.574462 57.4358 0.542547 56.3188 0.446805Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M77.0474 11.5531C78.8027 11.5531 82.0899 11.6807 83.7495 11.8084C83.6218 11.3297 83.5899 11.0743 83.5899 10.6914C83.5899 10.2765 83.6218 10.0212 83.7495 9.47861C81.9304 9.63819 79.1219 9.76584 77.1751 9.76584C77.0793 9.76584 76.7283 9.76584 76.3453 9.73393C76.3772 7.21268 76.4091 5.55313 76.5368 2.48933C80.0793 2.55316 81.9304 2.61699 84.1644 2.84039C84.0686 2.29785 84.0367 2.04253 84.0367 1.62764C84.0367 1.24467 84.0686 0.957437 84.1644 0.446805C82.7601 0.542547 80.3027 0.638292 78.3559 0.638292C75.8985 0.638292 73.7283 0.574462 72.2283 0.446805C72.4198 2.68082 72.5475 6.79779 72.5475 11.0105C72.5475 14.9998 72.4198 18.9891 72.1964 21.9891C73.473 21.9253 76.6645 21.8614 78.3878 21.8614C79.3772 21.8614 82.2176 21.9253 84.1005 21.9891C84.0048 21.4785 83.9729 21.1912 83.9729 20.8083C83.9729 20.4253 84.0048 20.17 84.1005 19.6593C82.5367 19.8508 79.2814 20.0423 77.3027 20.0423C77.1751 20.0423 76.824 20.0423 76.473 20.0104C76.4091 18.3508 76.3134 14.0743 76.3134 11.9999C76.3134 11.936 76.3134 11.8084 76.3453 11.5531H77.0474Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M102.505 14.0743C102.505 16.9785 102.377 20.2338 102.154 21.9891C103.239 21.8934 103.749 21.8614 104.324 21.8614C104.93 21.8614 105.441 21.8934 106.622 21.9891C106.43 20.521 106.335 17.6168 106.335 14.2977C106.335 13.8509 106.335 13.4041 106.366 12.5743C107.739 9.76584 111.632 3.06379 113.42 0.446805C112.749 0.542547 112.43 0.574462 111.951 0.574462C111.505 0.574462 111.154 0.542547 110.547 0.446805C109.654 2.58508 107.579 6.67014 105.377 10.5637C103.813 7.72331 100.941 1.91487 100.398 0.446805C99.1857 0.542547 98.6112 0.574462 97.941 0.574462C97.2708 0.574462 96.6963 0.542547 95.5155 0.446805C96.0261 1.27658 96.2176 1.59573 97.4304 3.76591C98.0367 4.81909 98.4835 5.61696 99.1218 6.79779C99.6325 7.6914 100.143 8.55309 100.622 9.4467C101.739 11.4254 101.898 11.7126 102.505 12.7339V14.0743Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M116.868 0.446805C117.092 2.87231 117.219 6.19142 117.219 10.5318C117.219 15.3509 117.092 19.5955 116.868 21.9891C117.858 21.8934 118.4 21.8614 119.102 21.8614C119.805 21.8614 120.315 21.8934 121.305 21.9891C121.113 19.9785 120.985 15.8615 120.985 11.0105C120.985 6.82971 121.081 3.2872 121.305 0.446805C120.443 0.542547 119.964 0.574462 119.23 0.574462C118.464 0.574462 117.922 0.542547 116.868 0.446805Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M131.956 11.5531C133.711 11.5531 136.998 11.6807 138.658 11.8084C138.53 11.3297 138.498 11.0743 138.498 10.6914C138.498 10.2765 138.53 10.0212 138.658 9.47861C136.839 9.63819 134.03 9.76584 132.084 9.76584C131.988 9.76584 131.637 9.76584 131.254 9.73393C131.286 7.21268 131.318 5.55313 131.445 2.48933C134.988 2.55316 136.839 2.61699 139.073 2.84039C138.977 2.29785 138.945 2.04253 138.945 1.62764C138.945 1.24467 138.977 0.957437 139.073 0.446805C137.669 0.542547 135.211 0.638292 133.264 0.638292C130.807 0.638292 128.637 0.574462 127.137 0.446805C127.328 2.68082 127.456 6.79779 127.456 11.0105C127.456 14.9998 127.328 18.9891 127.105 21.9891C128.381 21.9253 131.573 21.8614 133.296 21.8614C134.286 21.8614 137.126 21.9253 139.009 21.9891C138.913 21.4785 138.881 21.1912 138.881 20.8083C138.881 20.4253 138.913 20.17 139.009 19.6593C137.445 19.8508 134.19 20.0423 132.211 20.0423C132.084 20.0423 131.732 20.0423 131.381 20.0104C131.318 18.3508 131.222 14.0743 131.222 11.9999C131.222 11.936 131.222 11.8084 131.254 11.5531H131.956Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M143.793 0.446805C144.048 3.79783 144.112 5.52121 144.112 10.6914C144.112 15.7339 143.984 19.7232 143.793 21.9891C145.484 21.9253 148.772 21.8614 149.761 21.8614C150.654 21.8614 154.293 21.9253 155.92 21.9891C155.793 21.5423 155.761 21.3189 155.761 20.9678C155.761 20.6168 155.793 20.2976 155.92 19.6913C154.005 19.9466 151.229 20.1061 149.154 20.1061C148.899 20.1061 148.771 20.1061 148.069 20.0742C147.974 18.2232 147.91 14.7126 147.91 11.585C147.91 6.1595 147.974 3.67017 148.229 0.446805C147.431 0.542547 146.984 0.574462 146.25 0.574462C145.452 0.574462 144.91 0.542547 143.793 0.446805Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
      <path
        d="M159.67 0.446805C159.894 3.2872 160.022 7.34034 160.022 11.2658C160.022 14.9679 159.862 19.9466 159.67 21.9891C160.724 21.8934 161.362 21.8614 162.319 21.8614C162.926 21.8614 163.692 21.8934 164.713 21.9253C165.926 21.9572 167.234 21.9891 167.904 21.9891C171.575 21.9891 174.16 21.1593 176.298 19.2764C178.691 17.2019 180 14.1381 180 10.6275C180 7.27651 178.787 4.4042 176.585 2.58508C174.734 1.02126 172.596 0.446805 168.798 0.446805C167.872 0.446805 166.692 0.478719 165.511 0.510633C163.915 0.542547 162.83 0.574462 162.319 0.574462C161.298 0.574462 160.692 0.542547 159.67 0.446805ZM163.947 20.2976C163.851 18.3508 163.787 14.3615 163.787 11.1063C163.787 8.71266 163.883 3.734 163.947 2.23402C165.032 2.07445 165.766 2.01062 166.883 2.01062C170.107 2.01062 171.926 2.55316 173.298 3.92549C174.83 5.42547 175.628 7.85097 175.628 10.8509C175.628 14.1062 174.702 16.7232 173.011 18.4147C171.543 19.8508 169.628 20.4572 166.5 20.4572C165.575 20.4572 164.936 20.4253 163.947 20.2976Z"
        fill={mode === 'dark' ? 'hsla(0, 0%, 4%, 1)' : 'hsl(0, 0%, 100%)'}
      />
    </svg>
  )
}

LogoHorizontal.defaultProps = {}

export default LogoHorizontal
