export const parseBackendTime = (backendTime: string) =>
  backendTime.slice(0, -3).replace(':', 'h')

export const parseBackendDate = (backendDate: string) => {
  const formattedDate = new Date(backendDate).toLocaleString(
    window.navigator.language || 'en',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
  )

  const date = formattedDate.slice(0, 10)

  return date
}

/*
 * Output example: 15h16 | 20/10/2021
 */
export const parseBackendDateTime = (backendDateTime: string) => {
  const formattedDate = new Date(backendDateTime).toLocaleString(
    window.navigator.language || 'en',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }
  )

  const date = formattedDate.slice(0, 10)
  const time = formattedDate.slice(12).replace(':', 'h')

  return `${time} | ${date}`
}

export const parseBackendDateTimeShort = (backendDateTime: string) =>
  new Date(backendDateTime).toLocaleString(window.navigator.language || 'en', {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
