import React, { FC } from 'react'
import css from './inputMultiline.module.css'

interface Props {
  name: string
  label: string
  placeholderText?: string
  value: any
  errorMessage?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
}

const Input: FC<Props> = ({
  name,
  label,
  placeholderText,
  value,
  errorMessage,
  onChange,
}) => {
  return (
    <div className={css.host}>
      <span className={css.label}>{label}</span>

      <textarea
        name={name}
        value={value}
        placeholder={placeholderText}
        className={`${css.input} ${value && css.text} ${
          errorMessage && css.error
        }`}
        onChange={onChange}
      ></textarea>

      {errorMessage && <span className={css.errorMessage}>{errorMessage}</span>}
    </div>
  )
}

Input.defaultProps = {}

export default Input
