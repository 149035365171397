import React, { FC } from 'react'

import TableChevronLeftSingle from '../Icons/TableChevronLeftSingle'
import TableChevronLeftDouble from '../Icons/TableChevronLeftDouble'
import TableChevronRightSingle from '../Icons/TableChevronRightSingle'
import TableChevronRightDouble from '../Icons/TableChevronRightDouble'

import css from './pagination.module.css'

interface Props {
  totalEntries: number
  onPreviousPage?: Function
  onNextPage?: Function
  onFirstPage?: Function
  onLastPage?: Function
  entityName: string
  ofLabel: string
  isOnFirstPage: boolean
  isOnLastPage: boolean
  totalPages: number
  validateInputValue: Function
  handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>
  inputPageNumber: string | number
}

const Pagination: FC<Props> = ({
  totalEntries,
  onPreviousPage,
  onFirstPage,
  onNextPage,
  onLastPage,
  entityName,
  ofLabel,
  isOnFirstPage,
  isOnLastPage,
  totalPages,
  validateInputValue,
  handleKeyDown,
  inputPageNumber,
}) => {
  let pageNumberDisplayed = inputPageNumber

  if (inputPageNumber! < 0) {
    pageNumberDisplayed = 1
  }

  if (inputPageNumber! > totalPages) {
    pageNumberDisplayed = totalPages
  }

  return (
    <div className={`${css.host}`}>
      <p className={css.numberOfItems}>
        {totalEntries} {entityName}
      </p>

      <div
        className={`${css.pageActions} ${isOnFirstPage && css.noEvents}`}
        onClick={(evt) => onFirstPage && onFirstPage(evt)}
      >
        <TableChevronLeftDouble isDisabled={isOnFirstPage} />
      </div>
      <div
        className={`${css.pageActions} ${isOnFirstPage && css.noEvents}`}
        onClick={(evt) => onPreviousPage && onPreviousPage(evt)}
      >
        <TableChevronLeftSingle isDisabled={isOnFirstPage} />
      </div>

      <input
        type="number"
        value={pageNumberDisplayed}
        className={css.pageInput}
        onChange={(evt) => validateInputValue(evt)}
        onKeyDown={handleKeyDown}
      />
      <div className={css.paginationLimit}>
        {ofLabel} {totalPages}
      </div>

      <div
        className={`${css.pageActions} ${isOnLastPage && css.noEvents}`}
        onClick={(evt) => onNextPage && onNextPage(evt)}
      >
        <TableChevronRightSingle isDisabled={isOnLastPage} />
      </div>
      <div
        className={`${css.pageActions} ${isOnLastPage && css.noEvents}`}
        onClick={(evt) => onLastPage && onLastPage(evt)}
      >
        <TableChevronRightDouble isDisabled={isOnLastPage} />
      </div>
    </div>
  )
}

export default Pagination
