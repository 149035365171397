import { FC } from 'react'
import css from './mainContainer.module.css'

interface Props {
  hasExtraPadding?: boolean
  fullWidth?: boolean
}

const MainContainer: FC<Props> = ({ hasExtraPadding, fullWidth, children }) => {
  return (
    <div
      className={`${css.host} ${hasExtraPadding && css.extraPadding} ${
        fullWidth && css.fullWidth
      }`}
    >
      <div className={css.children}>{children}</div>
    </div>
  )
}

MainContainer.defaultProps = {
  hasExtraPadding: false,
  fullWidth: false,
}

export default MainContainer
