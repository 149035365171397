import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  emailValidator,
  stringRequiredValidator,
  passwordValidator,
} from '../../../../helpers/FormValidators'
import { showErrorToast, showSuccessToast } from '../../../../services/Toaster'

import {
  useMeQuery,
  useMeEditMutation,
} from '../../../../queries/autogenerate/hooks'
import {
  MeEditMutation,
  MeEditMutationVariables,
} from '../../../../queries/autogenerate/operations'

import MainContainer from '../../components/MainContainer'
import Avatar from '../../../../components/Avatar'
import Input from '../../../../components/Input'
import FormSectionTitle from '../../../../components/FormSectionTitle'
import ButtonAdd from '../../../../components/ButtonAdd'
import Button from '../../../../components/Button'

import css from './account.module.css'

interface Props {}

const Account: React.FC<Props> = () => {
  const { t } = useTranslation(['accountSettings', 'form', 'general'])
  const history = useHistory()

  const { loading: loadingMe, data: meData } = useMeQuery()
  const [meEditMutation, { loading: loadingEdit }] = useMeEditMutation({
    onCompleted: (data: MeEditMutation) => {
      showSuccessToast(t('update-success-message'))
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [isPasswordOpen, setIsPasswordOpen] = useState(false)
  const [initialValues, setInitialValues] = useState<MeEditMutationVariables>({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    oldPassword: '',
    newPassword: '',
  })

  const EditSchema = Yup.object().shape({
    name: stringRequiredValidator(t),
    surname: stringRequiredValidator(t),
    email: emailValidator(t),
    phoneNumber: stringRequiredValidator(t),
    oldPassword: isPasswordOpen ? stringRequiredValidator(t) : Yup.string(),
    newPassword: isPasswordOpen ? passwordValidator(t) : Yup.string(),
  })

  const switchPasswordState = () => {
    setIsPasswordOpen(!isPasswordOpen)
  }

  const handleSubmit = (values: MeEditMutationVariables) => {
    meEditMutation({ variables: values })
  }

  const handleCancel = () => {
    history.push('/')
  }

  useEffect(() => {
    setInitialValues((oldValue: MeEditMutationVariables) => ({
      ...oldValue,
      ...meData?.me,
    }))
  }, [meData])

  return (
    <MainContainer hasExtraPadding>
      <div className={css.host}>
        <div className={css.grayBar}>
          <div className={css.photo}>
            {meData?.me && <Avatar name={meData?.me?.name} isBig />}
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={EditSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form className={css.form}>
              <div className={css.inputRow}>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  errorMessage={
                    touched.name && errors.name ? errors.name : undefined
                  }
                  label={t('name-input-label')}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="surname"
                  value={values.surname}
                  errorMessage={
                    touched.surname && errors.surname
                      ? errors.surname
                      : undefined
                  }
                  label={t('surname-input-label')}
                  onChange={handleChange}
                />
              </div>

              <div className={css.inputRow}>
                <Input
                  type="text"
                  name="email"
                  value={values.email}
                  errorMessage={
                    touched.email && errors.email ? errors.email : undefined
                  }
                  label={t('form:email-input-label')}
                  onChange={handleChange}
                />
                <Input
                  type="tel"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  errorMessage={
                    touched.phoneNumber && errors.phoneNumber
                      ? errors.phoneNumber
                      : undefined
                  }
                  label={t('form:phone-input-label')}
                  onChange={handleChange}
                />
              </div>

              <div className={css.account}>
                <div className={css.row}>
                  <FormSectionTitle title="Account" />
                </div>

                <div className={`${css.row} ${isPasswordOpen && css.hide}`}>
                  <ButtonAdd
                    label={t('show-password-button')}
                    onClick={switchPasswordState}
                  />
                </div>

                <div className={`${!isPasswordOpen && css.hide}`}>
                  <div className={css.inputRow}>
                    <Input
                      type="password"
                      name="oldPassword"
                      value={values.oldPassword}
                      errorMessage={
                        touched.oldPassword && errors.oldPassword
                          ? errors.oldPassword
                          : undefined
                      }
                      label={t('old-password-label')}
                      onChange={handleChange}
                    />
                    <Input
                      type="password"
                      name="newPassword"
                      value={values.newPassword}
                      errorMessage={
                        touched.newPassword && errors.newPassword
                          ? errors.newPassword
                          : undefined
                      }
                      label={t('new-password-label')}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className={css.buttons}>
                  <Button
                    type="submit"
                    isLoading={loadingMe || loadingEdit}
                    label={t('save-button')}
                    loadingLabel={t('general:loading')}
                  />
                  <Button
                    label={t('general:cancel')}
                    onClick={handleCancel}
                    disabled={loadingMe || loadingEdit}
                    secondary
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MainContainer>
  )
}

export default Account
