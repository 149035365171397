import { FC } from 'react'

interface Props {}

const CheckmarkIcon: FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5L6 12L3 9"
        stroke="hsl(0, 0%, 10%)"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

CheckmarkIcon.defaultProps = {}

export default CheckmarkIcon
