import React, { FC } from 'react'

interface Props {}

const EmptySquare: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3H3v10h10V3zM3 2c-.55228 0-1 .44772-1 1v10c0 .5523.44772 1 1 1h10c.5523 0 1-.4477 1-1V3c0-.55228-.4477-1-1-1H3z"
        fill="#949CA8"
      />
    </svg>
  )
}

EmptySquare.defaultProps = {}

export default EmptySquare
