import React, { FC } from 'react'
import css from './input.module.css'

interface Props {
  name: string
  isBig?: boolean
  label?: string
  value?: any
  type?: 'date' | 'password' | 'number' | 'tel' | 'text'
  placeholderText?: string
  errorMessage?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
}

const Input: FC<Props> = ({
  name,
  label,
  isBig,
  value,
  placeholderText,
  errorMessage,
  type,
  onChange,
  disabled,
  onKeyPress,
}) => {
  return (
    <div className={css.host}>
      {label && <span className={css.label}>{label}</span>}
      <input
        name={name}
        value={value}
        type={type}
        placeholder={placeholderText}
        className={`${css.input} ${isBig && css.isBig} ${value && css.text} ${
          errorMessage && css.error
        } ${disabled && css.disabled}`}
        onChange={onChange}
        disabled={disabled}
        onKeyPress={onKeyPress}
      ></input>

      {errorMessage && <span className={css.errorMessage}>{errorMessage}</span>}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  isBig: false,
}

export default Input
