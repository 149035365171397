import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../../../components/Checkbox'
import RadioButton from '../../../../components/RadioButton'

import css from './matchingListItem.module.css'

interface Props {
  product: {
    id: string
    name?: string | undefined
    image: string
    color?: string | null | undefined
    brand?: { name: string } | null | undefined
    sources?:
      | {
          feed: { merchant: string; network: string }
          price?: number | null | undefined
          currency?: string | null | undefined
        }[]
      | null
  }
  isMatchingResult?: boolean
  isChecked: boolean
  handleChange: Function
}

const MatchingListItem: FC<Props> = ({
  product,
  isMatchingResult,
  isChecked,
  handleChange,
}) => {
  const { t } = useTranslation(['matching'])
  const { id, name, image, color, sources } = product

  const brand = product?.brand!.name

  return (
    <div className={css.host} onClick={() => handleChange()}>
      <div className={css.productMainInfoWrapper}>
        <div
          className={css.image}
          style={{ backgroundImage: `url(${image})` }}
        />
        <p className={css.brandName}>{brand ?? '-'}</p>
        <p className={css.productName}>{name ?? '-'}</p>
      </div>
      <div className={css.sourcesWrapper}>
        {sources &&
          sources.map((source, key) => {
            const { price, currency } = source
            const { merchant, network } = source.feed
            // const percentageOfMatch =  product.percentageOfMatch

            return (
              <div key={key} className={css.sourceWrapper}>
                <p className={css.sourceLabel}>{t('matching:source-label')}</p>
                <p className={css.source}>{`${merchant ?? '-'} (${
                  network ?? '-'
                })`}</p>
                <p className={css.colorLabel}>{t('matching:color-label')}</p>
                <p className={css.color}>{color ?? '-'}</p>
                <p className={css.priceLabel}>{t('matching:price-label')}</p>
                <p className={css.price}>{`${price ?? 0} ${
                  currency ?? 'GBP'
                }`}</p>

                {/* {isMatchingResult  && (
                  <p
                    className={css.percentageOfMatch}
                  >{`${percentageOfMatch}%`}</p>
                )} */}
              </div>
            )
          })}
      </div>

      {!isMatchingResult && (
        <div className={css.checkboxWrapper}>
          <RadioButton
            name="product-to-match"
            value={id}
            isChecked={isChecked}
            onChange={() => handleChange()}
          />
        </div>
      )}
      {isMatchingResult && (
        <div className={css.checkboxWrapper}>
          <Checkbox
            name="selected-match"
            value={id}
            isChecked={isChecked}
            onChange={() => handleChange()}
          />
        </div>
      )}
    </div>
  )
}

export default MatchingListItem
