import React, { FC } from 'react'

interface Props {}

const ListSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 3H2v1h1V3zM14 3H5v1h9V3zM5 6h9v1H5V6zM14 9H5v1h9V9zM5 12h9v1H5v-1zM3 9H2v1h1V9zM2 6h1v1H2V6zM3 12H2v1h1v-1z"
        fill="hsla(0, 0%, 65%, 1)"
      />
    </svg>
  )
}

ListSmall.defaultProps = {}

export default ListSmall
