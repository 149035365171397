import * as Yup from 'yup'
import { TFunction } from 'react-i18next'

/**
 * @Warning please note that the i18Next t parameter must have the form translation json loaded
 */

export const emailValidator = (t: TFunction<string[]>) =>
  Yup.string()
    .email(t('form:error-invalid-email'))
    .required(t('form:error-required'))

export const referenceIdValidator = (t: TFunction<string[]>) =>
  Yup.string().required(t('form:error-required')).uuid()

export const stringRequiredValidator = (t: TFunction<string[]>) =>
  Yup.string().required(t('form:error-required'))

export const integerRequiredValidator = (t: TFunction<string[]>) =>
  Yup.number()
    .strict()
    .required(t('form:error-required'))
    .positive(t('form:min-value-error'))
    .integer()

export const passwordValidator = (t: TFunction<string[]>) =>
  Yup.string()
    .required(t('form:error-required'))
    .min(8, t('form:error-password-min-chars'))
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\S]{8,}$/,
      t('form:error-password-regex')
    )

export const arrayOfStringValidator = (t: TFunction<string[]>) =>
  Yup.array(Yup.string())

export const booleanValidator = (t: TFunction<string[]>) =>
  Yup.boolean().required(t('form:error-required'))

export const imageUrlRequiredValidator = (t: TFunction<string[]>) =>
  Yup.string()
    .required(t('form:error-required'))
    .matches(/https?:\/\//g, t('form:error-required'))

export const imageUrlRequiredWhenActiveBooleanValidator = (
  validator: string,
  t: TFunction<string[]>
) =>
  Yup.string().when(validator, {
    is: true,
    then: Yup.string()
      .required(t('form:error-required'))
      .matches(/https?:\/\//g, t('form:error-required')),
  })

export const urlRequiredValidator = (t: TFunction<string[]>) =>
  Yup.string()
    .required(t('form:error-required'))
    .matches(/https?:\/\//g, t('form:error-required'))

export const percentageValidator = (t: TFunction<string[]>) =>
  Yup.number()
    .required(t('form:error-required'))
    .min(1, t('form:min-value-error'))
    .max(100, t('form:max-value-error'))
