// import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useMostPopularProductsQuery,
  useMostPopularProductsByCategoryQuery,
  useMostPopularCategoriesQuery,
  useFeedsStatusCountQuery,
  useTotalProductsQuery,
} from '../../../../queries/autogenerate/hooks'

import { dashboardSectionsNrItems } from '../../../../helpers/Constants'

import { createLinkToProduct } from '../../../../helpers/Functions'

import ListItem from '../../../../components/ListItem'
import FeedStatus from '../../../../components/FeedStatus'

import css from './dashboard.module.css'

export interface ListItemString {
  viewOnWebsiteLabel: string
  clicksLabel: string
}

const Dashboard = () => {
  const { t } = useTranslation(['dashboard', 'feeds'])

  /*
   * QUERIES
   */
  const {
    data: products,
    loading: producstLoading,
    error: productsError,
  } = useMostPopularProductsQuery({
    variables: { limit: dashboardSectionsNrItems },
  })

  const { data: totalProducts } = useTotalProductsQuery()

  const {
    data: productsByCategory,
    loading: productsByCategoryLoading,
    error: productsByCategoryError,
  } = useMostPopularProductsByCategoryQuery({
    variables: { limit: dashboardSectionsNrItems },
  })

  const {
    data: popularCategories,
    loading: popularCategoriesLoading,
    error: popularCategoriesError,
  } = useMostPopularCategoriesQuery({
    variables: { limit: dashboardSectionsNrItems },
  })

  const {
    data: statusCount,
    loading: statusCountLoading,
    error: statusCountError,
  } = useFeedsStatusCountQuery()

  /*
   * DATA SUBSETTING
   */

  const productsData =
    products?.productsTop &&
    products.productsTop!.map((product) => ({
      id: product.id,
      category: product?.category?.category!
        ? product?.category?.category!.name
        : '',
      subCategory: product?.category ? product?.category!.name : '',
      image: product.image,
      brand: product.brand! ? product.brand!.name : '',
      product: product.name,
      clicks: product.clicks,
      link: createLinkToProduct(product.id, product.name),
    }))

  const productsByCategoryData =
    productsByCategory?.productsTopByCategory &&
    productsByCategory.productsTopByCategory!.map((product) => {
      return {
        id: product.id,
        category: product.category!.category!.name,
        subCategory: product?.category!.name,
        image: product.image,
        brand: product.brand!.name,
        product: product.name,
        clicks: product.clicks,
        link: createLinkToProduct(product.id, product.name),
      }
    })

  const totalProductData =
    totalProducts?.products && totalProducts!.products!.totalEntries
      ? `(${totalProducts!.products!.totalEntries})`
      : ''

  const categoriesData =
    popularCategories?.categoriesTop &&
    popularCategories.categoriesTop!.map((category) => ({
      id: category.category.id,
      subCategory: category.category!.name,
      clicks: category!.clicks,
    }))

  const statusData =
    statusCount?.feedsStatusCount &&
    statusCount?.feedsStatusCount!.map((status) => ({
      code: status.status!,
      count: status.count,
    }))

  const totalStatusCount = statusData
    ? `(${statusData.map((status) => status.count).reduce((a, b) => a + b)})`
    : ''

  const listItemStrings: ListItemString = {
    viewOnWebsiteLabel: t('dashboard:view-on-website-label'),
    clicksLabel: t('dashboard:clicks-label'),
  }

  const hasErrors =
    productsError ||
    productsByCategoryError ||
    popularCategoriesError ||
    statusCountError

  /*
   * HELPERS
   */
  const statusDataFinder = (statusCode: string) =>
    statusData?.find((item) => item.code === statusCode)?.count ?? 0

  /*
   * UI
   */
  return (
    <div className={css.host}>
      {/* ERROR STATE */}
      {hasErrors ? (
        <p className={css.errorMessage}>{t('general:error-message')}</p>
      ) : (
        <>
          <div>
            <h1 className={css.pageTitle}>
              {`${t('dashboard:products-section-title')} ${totalProductData}`}
            </h1>
            <div className={css.wrapper}>
              {/* MOST POPULAR PRODUCTS  */}
              <div className={css.container}>
                <h2 className={css.containerTitle}>
                  {t('dashboard:most-popular-heading')}
                </h2>
                {producstLoading
                  ? Array.from(Array(dashboardSectionsNrItems), (_, index) => (
                      <ListItem id={index} key={index} loading />
                    ))
                  : productsData &&
                    productsData!.map((product, index) => {
                      return (
                        <ListItem
                          item={product}
                          id={index}
                          key={product.id}
                          listItemStrings={listItemStrings}
                        />
                      )
                    })}
              </div>

              {/* MOST POPULAR BY CATEGORIES  */}
              <div className={css.container}>
                <h2 className={css.containerTitle}>
                  {t('dashboard:most-popular-by-category-heading')}
                </h2>

                {productsByCategoryLoading
                  ? Array.from(Array(dashboardSectionsNrItems), (_, index) => (
                      <ListItem id={index} key={index} loading />
                    ))
                  : productsByCategoryData &&
                    productsByCategoryData!.map((item, key) => {
                      return (
                        <ListItem
                          item={item}
                          id={key}
                          key={key}
                          listItemStrings={listItemStrings}
                        />
                      )
                    })}
              </div>
            </div>
          </div>

          <div className={css.horizontalWrapper}>
            {/* CATEGORIES  */}
            <div className={css.smallContainer}>
              <h1 className={css.smallPageTitle}>
                {`${t('dashboard:categories-section-title')}`}
              </h1>
              <div className={css.wrapper}>
                <div className={css.container}>
                  <h2 className={css.smallContainerTitle}>
                    {t('dashboard:most-popular-this-week-heading')}
                  </h2>

                  {popularCategoriesLoading
                    ? Array.from(
                        Array(dashboardSectionsNrItems),
                        (_, index) => (
                          <ListItem id={index} key={index} loading />
                        )
                      )
                    : categoriesData &&
                      categoriesData!.map((category, index) => {
                        return (
                          <ListItem
                            item={category}
                            id={index}
                            key={index}
                            small
                            listItemStrings={listItemStrings}
                          />
                        )
                      })}
                </div>
              </div>
            </div>

            {/* STATUS COUNT  */}
            {
              <div className={css.smallContainer}>
                <h1 className={css.smallPageTitle}>
                  {`${t('dashboard:feeds-section-title')} ${totalStatusCount}`}
                </h1>
                <div className={css.wrapper}>
                  <div className={css.container}>
                    <h2 className={css.smallContainerTitle}>
                      {t('dashboard:all-by-status')}
                    </h2>

                    <div className={css.statusContainersWrapper}>
                      <FeedStatus
                        isLoading={statusCountLoading}
                        statusCode={'error'}
                        totalCases={statusDataFinder('error')}
                        isBig
                      />
                      <FeedStatus
                        isLoading={statusCountLoading}
                        statusCode={'start'}
                        totalCases={statusDataFinder('start')}
                        isBig
                      />
                      <FeedStatus
                        isLoading={statusCountLoading}
                        statusCode={'finish'}
                        totalCases={statusDataFinder('finish')}
                        isBig
                      />
                      <FeedStatus
                        isLoading={statusCountLoading}
                        isDisabled
                        statusCode={'disabled'}
                        totalCases={statusDataFinder('disabled')}
                        isBig
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard
