import toast from 'react-hot-toast'
import Toast from '../components/Toast'

/**
 * Assumes that the Toaster element from the react-hot-toast is added to the dom (in our case on
 * the app.tsx)
 */
const showToast = (message: string, isError: boolean) => {
  toast.custom(<Toast message={message} isError={isError} />)
}

export const showErrorToast = (message: string) => {
  showToast(message, true)
}

export const showSuccessToast = (message: string) => {
  showToast(message, false)
}
