import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form, FieldArray } from 'formik'
import { ApolloError } from '@apollo/client'
import * as Yup from 'yup'

import {
  useBrandsAllLazyQuery,
  useCategoriesAllLazyQuery,
  useProductCreateMutation,
  useProductEditMutation,
  useProductLazyQuery,
} from '../../../../../queries/autogenerate/hooks'

import { Maybe, Source } from '../../../../../queries/autogenerate/schemas'

import {
  ProductCreateMutationVariables,
  SourceCreateMutationVariables,
  SourceEditMutationVariables,
} from '../../../../../queries/autogenerate/operations'

import {
  stringRequiredValidator,
  booleanValidator,
  arrayOfStringValidator,
} from '../../../../../helpers/FormValidators'

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../services/Toaster'

import Button, { ButtonSize } from '../../../../../components/Button'
import Checkbox from '../../../../../components/Checkbox'
import CloseIcon from '../../../../../components/Icons/Close'
import Dropdown from '../../../../../components/Dropdown'
import Input from '../../../../../components/Input'
import InputMultiline from '../../../../../components/InputMultiline'
import NewSourceModal from '../../../components/NewSourceModal'
import SourcesTable from '../../../components/SourcesTable'
import Switch from '../../../../../components/Switch'

import css from './product.module.css'

interface ExtendedProductType extends ProductCreateMutationVariables {
  parentId: String | undefined
}

const Product = () => {
  /*
   ** SETTINGS **
   */
  const { t } = useTranslation(['products', 'general', 'form', 'table'])
  const history = useHistory()

  const getIdValueFromParam = new URLSearchParams(useLocation().search).get(
    'id'
  )

  /*
   ** STATE DECLARATIONS **
   *  Set initial state values and functions to alter them
   */
  const [chosenCategory, setChosenCategory] = useState('')
  const [viewingProductId, setViewingProductId] = useState('')
  const [categoryIdGotFromProduct, setCategoryIdGotFromProduct] = useState('')
  const [isSourceModalVisible, setIsSourceModalVisible] = useState(false)
  const [sourceModalPrefill, setSourceModalPrefill] =
    useState<{ source: SourceEditMutationVariables; index: number }>()

  const [initialValues, setInitialValues] = useState<ExtendedProductType>({
    brandId: '',
    categoryId: '',
    parentId: '',
    name: '',
    description: '',
    lastArrival: false,
    image: '',
    images: [''],
    condition: 'New',
    gender: 'Female',
    color: '',
    standard: t('products:one-size-input'),
    type: t('products:regular-size-type-input'),
    value: t('products:one-size-input'),
    isVisible: false,
    locationId: '',
    clicks: 0,
    sources: [],
  })

  /*
   ** YUP SCHEMA **
   *  Rules for form validation
   */
  const ProductSchema = Yup.object().shape({
    brandId: stringRequiredValidator(t),
    categoryId: stringRequiredValidator(t),
    parentId: stringRequiredValidator(t),
    name: stringRequiredValidator(t),
    description: stringRequiredValidator(t),
    lastArrival: booleanValidator(t),
    image: stringRequiredValidator(t),
    images: arrayOfStringValidator(t),
    condition: stringRequiredValidator(t),
    gender: stringRequiredValidator(t),
    color: stringRequiredValidator(t),
    isVisible: booleanValidator(t),
    type: stringRequiredValidator(t),
    value: stringRequiredValidator(t),
    standard: stringRequiredValidator(t),
  })

  /*
   ** GETTERS **
   *  Hooks for retrieving, editing or deleting data from the server
   */
  const [getProduct, { data: productData }] = useProductLazyQuery({
    variables: { id: viewingProductId },
  })
  const [getBrands, { data: brandsData }] = useBrandsAllLazyQuery()
  const [getCategories, { data: categoriesData }] = useCategoriesAllLazyQuery()
  const [productCreateMutation] = useProductCreateMutation({
    onCompleted: () => {
      showSuccessToast(
        `${t('products:product-creation-successful')}. ${t(
          'products:redirection-message'
        )}.`
      )
      setTimeout(() => {
        history.push('/products/')
      }, 2000)
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })
  const [productEditMutation] = useProductEditMutation({
    onCompleted: () => {
      showSuccessToast(
        `${t('products:product-edit-successful')}. ${t(
          'products:redirection-message'
        )}.`
      )
      setTimeout(() => {
        history.push('/products/')
      }, 2000)
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  /*
   ** HANDLERS **
   *  Functions usefull for interactivity in this scene
   */

  const displaySourceModal = () => {
    setIsSourceModalVisible(!isSourceModalVisible)
    // Assure previous data is cleared
    setSourceModalPrefill(undefined)
  }

  const handleSourceEdit = (
    editedSource: SourceEditMutationVariables,
    sourceIndex: number,
    sources: Array<SourceEditMutationVariables>
  ) => {
    // replace the old object with updated object
    sources[sourceIndex] = editedSource
  }
  const handleSourceCreate = (
    newSource: SourceCreateMutationVariables,
    sources: Array<SourceCreateMutationVariables>
  ) => {
    const existentFeeds = sources.map((source) => source.feedId)

    // Only add the new feed if it is not yet in the sources list
    if (existentFeeds.indexOf(newSource.feedId) === -1) {
      sources.push(newSource)
    } else {
      showErrorToast(t('feeds:duplicated-source-error-message'))
    }
  }

  const handleSubmit = (values: ProductCreateMutationVariables) => {
    if (viewingProductId) {
      const valuesWithId = { id: viewingProductId, ...values }

      productEditMutation({ variables: valuesWithId })
    } else {
      productCreateMutation({ variables: values })
    }
    setSourceModalPrefill(undefined)
  }

  /*
   ** DATA SUBSET **
   *  Select and refactor server data to be displayed in the UI
   */

  const availableBrands = brandsData?.brandsAll.map((brand) => ({
    title: brand.name,
    value: brand.id,
  }))

  const availableCategories = categoriesData?.categoriesAll.map((category) => ({
    title: category.name,
    value: category.id,
  }))

  const availableSubCategories = (chosenCategory: string) => {
    const getSubCategories = categoriesData?.categoriesAll.find(
      (category) => category.id === chosenCategory
    )

    if (getSubCategories) {
      return getSubCategories!.subCategories.map((subCategory) => ({
        title: subCategory.name || '',
        value: subCategory.id || '',
      }))
    }
  }

  /*
   ** USE EFFECTS **
   *  Actions to be executed on component did mount
   */
  useEffect(() => {
    if (getIdValueFromParam) {
      setViewingProductId(getIdValueFromParam)
      getProduct({ variables: { id: getIdValueFromParam } })
    }
    getBrands()
    getCategories()
  }, [getBrands, getCategories, getIdValueFromParam, getProduct])

  useEffect(() => {
    if (productData && categoriesData) {
      setInitialValues((oldValues: ExtendedProductType) => ({
        ...oldValues,
        ...productData.product!,
        ...productData.product!.sizes,
        brandId: productData!.product!.brand?.id || '',
        categoryId: productData!.product!.category?.id || '',
        parentId: productData!.product!.category?.categoryId || '',
        condition: productData.product?.condition || 'New',
        sources: productData.product!.sources
          ? ([...productData.product!.sources] as Maybe<Array<Source>>)
          : [],
      }))

      if (productData?.product?.category?.id) {
        setChosenCategory(productData!.product!.category!.categoryId!)
        setCategoryIdGotFromProduct(productData!.product!.category!.categoryId!)
      }
    }
  }, [categoriesData, productData])

  /*
   ** UI **
   *  HTML AND CSS
   */
  return (
    <div className={css.host}>
      <h1 className={css.sectionTitle}>
        {getIdValueFromParam
          ? t('products:edit-product-title')
          : t('products:new-product-title')}
      </h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ProductSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <>
            <Form className={css.form}>
              <div className={css.formContainer}>
                {/* GENERAL INFO */}
                {availableBrands && (
                  <Dropdown
                    label={t('form:brand-input-label')}
                    name="brandId"
                    value={values.brandId}
                    placeholderText={
                      viewingProductId &&
                      availableBrands.find(
                        (brand) => brand.value === initialValues.brandId
                      )
                        ? availableBrands.find(
                            (brand) => brand.value === initialValues.brandId
                          )!.title
                        : t('form:choose-brand-input-label')
                    }
                    onChange={handleChange}
                    options={availableBrands}
                    errorMessage={
                      touched.brandId && errors.brandId
                        ? errors.brandId
                        : undefined
                    }
                  />
                )}

                {availableCategories && (
                  <Dropdown
                    label={t('form:category-input-label')}
                    name="parentId"
                    value={values.parentId}
                    placeholderText={
                      viewingProductId &&
                      availableCategories.find(
                        (category) =>
                          category.value === categoryIdGotFromProduct
                      )
                        ? availableCategories.find(
                            (category) =>
                              category.value === categoryIdGotFromProduct
                          )!.title
                        : t('form:choose-category-input-label')
                    }
                    onChange={(e) => {
                      setChosenCategory(e.target.value)
                      handleChange(e)
                    }}
                    options={availableCategories}
                    errorMessage={
                      touched.parentId && errors.parentId
                        ? errors.parentId
                        : undefined
                    }
                  />
                )}

                {chosenCategory !== '' &&
                  availableSubCategories(chosenCategory) && (
                    <Dropdown
                      label={t('form:subcategory-input-label')}
                      name="categoryId"
                      value={values.categoryId}
                      placeholderText={
                        viewingProductId &&
                        availableSubCategories(chosenCategory)!.find(
                          (category) =>
                            category.value === categoryIdGotFromProduct
                        )
                          ? availableSubCategories(chosenCategory)!.find(
                              (category) =>
                                category.value === categoryIdGotFromProduct
                            )!.title
                          : t('form:choose-subcategory-input-label')
                      }
                      onChange={handleChange}
                      options={availableSubCategories(chosenCategory)!}
                      errorMessage={
                        touched.categoryId && errors.categoryId
                          ? errors.categoryId
                          : undefined
                      }
                    />
                  )}

                <Input
                  type="text"
                  name="name"
                  label={t('form:name-input-label')}
                  value={values.name}
                  errorMessage={
                    touched.name && errors.name ? errors.name : undefined
                  }
                  onChange={handleChange}
                />
                <InputMultiline
                  placeholderText=""
                  name="description"
                  value={values.description}
                  errorMessage={
                    touched.description && errors.description
                      ? errors.description
                      : undefined
                  }
                  label={t('form:description-input-label')}
                  onChange={handleChange}
                />

                <Checkbox
                  name="lastArrival"
                  value={values.lastArrival}
                  label={t('form:latest-arrival-input-label')}
                  isChecked={values.lastArrival}
                  isDisabled={false}
                  onChange={handleChange}
                />

                <hr className={css.divider} />
                {/* IMAGES */}
                <div>
                  <Input
                    type="text"
                    name="image"
                    label={t('form:imageUrl-input-label')}
                    value={values.image}
                    errorMessage={
                      touched.image && errors.image ? errors.image : undefined
                    }
                    onChange={handleChange}
                  />
                  <p className={css.hint}>
                    {t('form:image-displayed-as-thumbnail-label')}.{' '}
                    {t('form:suggested-size-label')}: 1000x1000 px.
                  </p>
                </div>
                {values.image !== '' && !errors.image && (
                  <div
                    className={css.mainImage}
                    style={{ backgroundImage: `url(${values.image})` }}
                  />
                )}
                <div>
                  {/* PROPERTIES */}
                  <div className={css.smallLabel}>
                    {t('form:gallery-label')}
                  </div>
                  <FieldArray name="images">
                    {({ remove, push }) => (
                      <>
                        <div className={css.galleryFieldsContainer}>
                          {(values.images as Array<String>).map(
                            (galleryImage, index) => (
                              <div
                                className={css.galleryInputField}
                                key={index}
                              >
                                <Input
                                  type="text"
                                  name={`images.${index}`}
                                  value={galleryImage}
                                  errorMessage={
                                    touched.images &&
                                    Array.isArray(errors.images) &&
                                    errors.images[index] &&
                                    errors.images[index]
                                      ? errors.image
                                      : undefined
                                  }
                                  onChange={handleChange}
                                />
                                <button
                                  onClick={() => {
                                    remove(index)
                                  }}
                                >
                                  <CloseIcon />
                                </button>
                              </div>
                            )
                          )}
                        </div>
                        <p className={css.hint}>
                          {t('form:suggested-size-label')}: 1000x1334 px.
                        </p>
                        <div className={css.addMoreButton}>
                          <Button
                            label="Add more"
                            onClick={() => {
                              push('')
                            }}
                            secondary
                            size={ButtonSize.Small}
                          />
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
                {values?.images &&
                  values.images.length > 0 &&
                  values.images[0] !== '' && (
                    <div className={css.gallery}>
                      {(values.images as Array<String>).map(
                        (galleryImage, index) => (
                          <div
                            key={index}
                            className={css.galleryImage}
                            style={{ backgroundImage: `url(${galleryImage})` }}
                          />
                        )
                      )}
                    </div>
                  )}
              </div>
              <div className={css.formContainer}>
                <p className={css.formSectionTitle}>
                  {t('form:properties-label')}
                </p>
                <div className={css.horizontal}>
                  <Input
                    type="text"
                    name="condition"
                    label={t('form:condition-input-label')}
                    value={values.condition}
                    errorMessage={
                      touched.condition && errors.condition
                        ? errors.condition
                        : undefined
                    }
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    name="gender"
                    label={t('form:gender-input-label')}
                    value={values.gender}
                    errorMessage={
                      touched.gender && errors.gender
                        ? errors.gender
                        : undefined
                    }
                    onChange={handleChange}
                  />
                </div>
                <Input
                  type="text"
                  name="color"
                  label={t('form:color-input-label')}
                  value={values.color}
                  errorMessage={
                    touched.color && errors.color ? errors.color : undefined
                  }
                  onChange={handleChange}
                />
                <div className={css.horizontal}>
                  <Input
                    type="text"
                    name="standard"
                    label={t('form:size-standard-input-label')}
                    value={values.standard}
                    errorMessage={
                      touched.standard && errors.standard
                        ? errors.standard
                        : undefined
                    }
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    name="type"
                    label={t('form:size-type-input-label')}
                    value={values.type}
                    errorMessage={
                      touched.type && errors.type ? errors.type : undefined
                    }
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    name="value"
                    label={t('form:size-value-input-label')}
                    value={values.value}
                    errorMessage={
                      touched.value && errors.value ? errors.value : undefined
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={css.formContainer}>
                {/* AVAILABILITY & IDENTIFIERS */}
                <p className={css.formSectionTitle}>
                  {t('form:availability-and-identifiers-label')}
                </p>

                <Switch
                  name="isVisible"
                  labelActive={t('form:visibility-label')}
                  labelNotActive={t('form:visibility-label')}
                  isActive={values.isVisible}
                  onChange={handleChange}
                />
              </div>
              <div className={css.formContainer}>
                {/* SOURCES */}
                <p className={css.formSectionTitle}>
                  {t('form:sources-label')}
                </p>
                <div>
                  <FieldArray name="sources">
                    {({ remove }) =>
                      values.sources && (
                        <div className={css.sourcesContainer}>
                          <SourcesTable
                            arrayOfSources={values.sources! as Array<Source>}
                            displayActions={true}
                            setSourceModalPrefill={setSourceModalPrefill}
                            setIsSourceModalVisible={setIsSourceModalVisible}
                            remove={remove}
                          />
                        </div>
                      )
                    }
                  </FieldArray>
                  <Button
                    label={t('form:add-source-label')}
                    onClick={() => displaySourceModal()}
                    secondary
                    size={ButtonSize.Small}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  size={ButtonSize.Small}
                  label={
                    viewingProductId !== ''
                      ? t('form:save-changes-label')
                      : t('create-product-label')
                  }
                  loadingLabel={t('general:loading')}
                />
              </div>
            </Form>

            {/* ADD NEW SOURCE MODAL */}
            <NewSourceModal
              isVisible={isSourceModalVisible}
              sourceInput={sourceModalPrefill?.source}
              onCloseModal={() => displaySourceModal()}
              onSave={(editedSource) =>
                sourceModalPrefill &&
                handleSourceEdit(
                  editedSource,
                  sourceModalPrefill.index,
                  values.sources as Array<SourceEditMutationVariables>
                )
              }
              onCreate={(newSource) =>
                handleSourceCreate(
                  newSource,
                  values.sources as Array<SourceCreateMutationVariables>
                )
              }
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default Product
