import Cookies, { CookieAttributes } from 'js-cookie'

const AuthKey = 'IsSignedIn'

// same options need to be passed when setting / removing
const cookieOptions: CookieAttributes = { sameSite: 'strict', secure: true }

class Auth {
  static login = () => Cookies.set(AuthKey, 'true', cookieOptions)

  static isLogedIn = () => Cookies.get(AuthKey) ? true : false

  static logout = () => Cookies.remove(AuthKey, cookieOptions)
}

export default Auth
