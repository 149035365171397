import React, { FC } from 'react'

interface Props {}

const ChevronUpSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.237 10L8 6.4346 4.76297 10 4 9.15963l3.61851-3.98558c.21069-.23207.55229-.23207.76298 0L12 9.15963 11.237 10z"
        fill="#0E111B"
      />
    </svg>
  )
}

ChevronUpSmall.defaultProps = {}

export default ChevronUpSmall
