import { Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'

import {
  useBrandsAllQuery,
  useMatchResetMutation,
  useMatchsAllLazyQuery,
} from '../../../../../queries/autogenerate/hooks'

import {
  showSuccessToast,
  showErrorToast,
} from '../../../../../services/Toaster'

import Button from '../../../../../components/Button'
import SearchBox, { SearchBoxSize } from '../../../../../components/SearchBox'
import DropdownBulk from '../../../../../components/DropdownBulk'

import css from './suggestions.module.css'

const Suggestions = () => {
  const { t } = useTranslation(['matching', 'products'])

  const [searchTerm, setSearchTerm] = useState('')
  const [filteredMatches, setFilteredMatches] = useState<any>(undefined)
  const [chosenBrand, setChosenBrand] = useState('')

  const [matchResetMutation] = useMatchResetMutation({
    onCompleted: () => {
      showSuccessToast(t('matching:matching-reset'))
      showSuccessToast(t('matching:take-your-time'))
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [getMatchsAll, { data, loading: loadingData }] = useMatchsAllLazyQuery()

  const { data: brandsAllData } = useBrandsAllQuery()

  const availableBrands = brandsAllData?.brandsAll.map((brand) => {
    return {
      title: brand.name,
      value: brand.id,
    }
  })

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleOnSearch = () => {
    const lowercaseSearchTerm = searchTerm.toLowerCase()
    const filterMatchesWithValue = data?.matchsAll.filter(
      (match) =>
        match.sourceOne.brand?.toLowerCase().includes(lowercaseSearchTerm) ||
        match.sourceOne.product?.name
          .toLowerCase()
          .includes(lowercaseSearchTerm) ||
        match.sourceTwo.brand?.toLowerCase().includes(lowercaseSearchTerm) ||
        match.sourceTwo.product?.name
          .toLowerCase()
          .includes(lowercaseSearchTerm)
    )

    setFilteredMatches(filterMatchesWithValue)
  }

  const handleBulkAction = (e: React.ChangeEvent<any>) => {
    setChosenBrand(e.target.value)
  }

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredMatches(data?.matchsAll)
    }
  }, [data, searchTerm])

  useEffect(() => {
    getMatchsAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={css.host}>
      <div className={css.actionsContainer}>
        <div className={css.dropdownContainer}>
          {availableBrands && (
            <div className={css.dropdown}>
              <DropdownBulk
                options={availableBrands}
                placeholderText={t('matching:pick-your-brand')}
                value={chosenBrand}
                onChange={handleBulkAction}
              />
            </div>
          )}
          <Button
            label={t('matching:refresh-matching')}
            onClick={() =>
              matchResetMutation({
                variables: {
                  brand: availableBrands
                    ?.filter((brand) => brand.value === chosenBrand)[0]
                    .title.toUpperCase(),
                },
              })
            }
            disabled={chosenBrand === ''}
          />
        </div>
        <SearchBox
          size={SearchBoxSize.Small}
          inputName="search"
          placeholder={t('products:search-product-placeholder')}
          value={searchTerm}
          onChange={handleOnChangeSearch}
          onClick={handleOnSearch}
        />
      </div>

      <div className={css.mainWrapper}>
        {!loadingData &&
          filteredMatches &&
          filteredMatches.map((match: any, index: Key) => (
            <div key={index} className={css.matchContainer}>
              <div className={css.singleMatchContainer}>
                <div className={css.mainInfoContainer}>
                  <div
                    className={css.image}
                    style={{
                      backgroundImage: `url(${match.sourceOne.product?.image})`,
                    }}
                  />
                  <div className={css.productInfoContainer}>
                    <p className={css.brandName}>{match.sourceOne.brand}</p>
                    <p className={css.productName}>
                      {match.sourceOne.product?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className={css.divider} />
              <div className={css.singleMatchContainer}>
                <div className={css.mainInfoContainer}>
                  <div
                    className={css.image}
                    style={{
                      backgroundImage: `url(${match.sourceTwo.product?.image})`,
                    }}
                  />
                  <div className={css.productInfoContainer}>
                    <p className={css.brandName}>{match.sourceTwo.brand}</p>
                    <p className={css.productName}>
                      {match.sourceTwo.product?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {loadingData && (
          <>
            <div className={css.loadingDiv} />
            <div className={css.loadingDiv} />
            <div className={css.loadingDiv} />
          </>
        )}

        {!loadingData &&
          (!data || !filteredMatches || filteredMatches.length === 0) && (
            <div className={css.noResultsContainer}>
              <p>{t('matching:no-results-were-found')}</p>
            </div>
          )}
      </div>
    </div>
  )
}

export default Suggestions
