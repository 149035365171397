import { FC, MouseEventHandler, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import PillAvatar from '../PillAvatar/index'
import List from '../Icons/List'

import css from './header.module.css'

interface Props {
  avatarName: string
  avatarSurname: string
  avatarUrl?: string
  avatarLinks: { onClick?: MouseEventHandler; label: string }[]
  breadcrumbIcon?: ReactElement
  breadcrumbLinks: { link: string; showingTitle: string }[]
}

const Header: FC<Props> = ({
  avatarName,
  avatarSurname,
  avatarUrl,
  avatarLinks,
  breadcrumbIcon,
  breadcrumbLinks,
}) => {
  const breadcrumbSize = breadcrumbLinks.length

  return (
    <div className={css.host}>
      <div className={css.leftContent}>
        <div className={css.breadcrumbIcon}>{breadcrumbIcon}</div>

        {breadcrumbLinks.map(({ link, showingTitle }, index) => {
          const isLastPath = index === breadcrumbSize - 1

          return (
            <span key={index}>
              <NavLink
                to={link}
                className={`${isLastPath ? css.lastPath : css.path}`}
              >
                {showingTitle}
              </NavLink>

              {!isLastPath && <span className={css.pathMarker}>/</span>}
            </span>
          )
        })}
      </div>

      <PillAvatar
        avatarName={avatarName}
        avatarSurname={avatarSurname}
        imageUrl={avatarUrl}
        links={avatarLinks}
      />
    </div>
  )
}

Header.defaultProps = {
  breadcrumbIcon: <List />,
}

export default Header
