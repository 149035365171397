export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any
}

export type Application = {
  __typename?: 'Application'
  /** The application version */
  version?: Maybe<Scalars['String']>
}

/** The brand object */
export type Brand = {
  __typename?: 'Brand'
  countProducts?: Maybe<Scalars['Int']>
  description: Scalars['String']
  highlighted: Scalars['Boolean']
  highlightedImageUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  isFeatured: Scalars['Boolean']
  name: Scalars['String']
  products: Array<Product>
  trending: Scalars['Boolean']
  trendingImageUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type BrandInput = {
  description: Scalars['String']
  highlighted: Scalars['Boolean']
  highlightedImageUrl?: Maybe<Scalars['String']>
  isFeatured: Scalars['Boolean']
  name: Scalars['String']
  trending: Scalars['Boolean']
  trendingImageUrl?: Maybe<Scalars['String']>
}

/** The brand page object to return on paginated list */
export type BrandPage = {
  __typename?: 'BrandPage'
  entries: Array<Brand>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The brand sort fields */
export enum BrandSortFields {
  Name = 'NAME',
  Trending = 'TRENDING',
}

export type BrandSortInput = {
  direction: SortDirections
  field: BrandSortFields
}

/** The category object */
export type Category = {
  __typename?: 'Category'
  category?: Maybe<Category>
  categoryId?: Maybe<Scalars['ID']>
  countProducts?: Maybe<Scalars['Int']>
  description: Scalars['String']
  featuredBrands: Array<Brand>
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  name: Scalars['String']
  products: Array<Product>
  subCategories: Array<Category>
  updatedAt: Scalars['DateTime']
}

export type CategoryFilterInput = {
  categoryId?: Maybe<Scalars['ID']>
}

export type CategoryInput = {
  categoryId?: Maybe<Scalars['ID']>
  description: Scalars['String']
  name: Scalars['String']
}

/** The category page object to return on paginated list */
export type CategoryPage = {
  __typename?: 'CategoryPage'
  entries: Array<Category>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The category sort fields */
export enum CategorySortFields {
  Name = 'NAME',
}

export type CategorySortInput = {
  direction: SortDirections
  field: CategorySortFields
}

/** The category top object */
export type CategoryTop = {
  __typename?: 'CategoryTop'
  category: Category
  clicks?: Maybe<Scalars['Int']>
}

/** The feed object */
export type Feed = {
  __typename?: 'Feed'
  countSources?: Maybe<Scalars['Int']>
  frequency: FeedFrequencyValues
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  isEnabled: Scalars['Boolean']
  lastRun?: Maybe<Scalars['Date']>
  merchant: Scalars['String']
  merchantId: Scalars['String']
  network: FeedNetworkValues
  productCount: Scalars['Int']
  productLoaded: Scalars['Int']
  scrapeLogs?: Maybe<Array<ScrapeLog>>
  updatedAt: Scalars['DateTime']
}

/** The feed frequency values */
export enum FeedFrequencyValues {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type FeedInput = {
  frequency: FeedFrequencyValues
  isEnabled: Scalars['Boolean']
  merchant: Scalars['String']
  merchantId: Scalars['String']
  network: FeedNetworkValues
}

/** The network values */
export enum FeedNetworkValues {
  Partnerize = 'PARTNERIZE',
  Rakuten = 'RAKUTEN',
}

/** The feeds page object to return on paginated lists */
export type FeedPage = {
  __typename?: 'FeedPage'
  entries: Array<Feed>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The feed sort fields */
export enum FeedSortFields {
  Merchant = 'MERCHANT',
  MerchantId = 'MERCHANT_ID',
  Network = 'NETWORK',
}

export type FeedSortInput = {
  direction: SortDirections
  field: FeedSortFields
}

/** The feed status object */
export type FeedStatus = {
  __typename?: 'FeedStatus'
  count: Scalars['Int']
  status: Scalars['String']
}

/** The location object */
export type Location = {
  __typename?: 'Location'
  countProducts?: Maybe<Scalars['Int']>
  description: Scalars['String']
  id: Scalars['ID']
  imageUrl: Scalars['String']
  insertedAt: Scalars['DateTime']
  name: Scalars['String']
  products: Array<Product>
  updatedAt: Scalars['DateTime']
}

export type LocationInput = {
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
}

/** The location page object to return on paginated list */
export type LocationPage = {
  __typename?: 'LocationPage'
  entries: Array<Location>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The location sort fields */
export enum LocationSortFields {
  Name = 'NAME',
}

export type LocationSortInput = {
  direction: SortDirections
  field: LocationSortFields
}

export type LoggedUserInput = {
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  newPassword?: Maybe<Scalars['String']>
  oldPassword?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  surname?: Maybe<Scalars['String']>
}

/** The match object */
export type Match = {
  __typename?: 'Match'
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  sourceOne: Source
  sourceOneId: Scalars['ID']
  sourceTwo: Source
  sourceTwoId: Scalars['ID']
  updatedAt: Scalars['DateTime']
}

/** The matchs page object to return on paginated lists */
export type MatchPage = {
  __typename?: 'MatchPage'
  entries: Array<Match>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

export type PaginationInput = {
  page: Scalars['Int']
  pageSize: Scalars['Int']
}

export type PriceFilterInput = {
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
}

/** The product object */
export type Product = {
  __typename?: 'Product'
  brand: Brand
  brandId: Scalars['ID']
  category: Category
  categoryId: Scalars['ID']
  clicks: Scalars['Int']
  color?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  countSources?: Maybe<Scalars['Int']>
  description: Scalars['String']
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image: Scalars['String']
  images: Array<Scalars['String']>
  insertedAt: Scalars['DateTime']
  isVisible: Scalars['Boolean']
  lastArrival: Scalars['Boolean']
  location?: Maybe<Location>
  locationId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  sizes?: Maybe<Array<ProductSize>>
  sources?: Maybe<Array<Source>>
  updatedAt: Scalars['DateTime']
}

export type ProductFilterInput = {
  brandId?: Maybe<Scalars['ID']>
  categoryId?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
  feedId?: Maybe<Scalars['ID']>
  price?: Maybe<PriceFilterInput>
  visibility?: Maybe<Scalars['Boolean']>
}

export type ProductInput = {
  brandId: Scalars['ID']
  categoryId: Scalars['ID']
  clicks: Scalars['Int']
  color?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  description: Scalars['String']
  gender?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  images?: Maybe<Array<Scalars['String']>>
  isVisible: Scalars['Boolean']
  lastArrival: Scalars['Boolean']
  locationId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  sizes?: Maybe<Array<ProductSizeInput>>
  sources?: Maybe<Array<SourceInput>>
}

/** The product page object to return on paginated list */
export type ProductPage = {
  __typename?: 'ProductPage'
  entries: Array<Product>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The product size object */
export type ProductSize = {
  __typename?: 'ProductSize'
  standard?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ProductSizeInput = {
  standard?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** The product sort fields */
export enum ProductSortFields {
  BrandId = 'BRAND_ID',
  CategoryId = 'CATEGORY_ID',
  Clicks = 'CLICKS',
  Color = 'COLOR',
  Condition = 'CONDITION',
  Description = 'DESCRIPTION',
  Gender = 'GENDER',
  IsVisible = 'IS_VISIBLE',
  LastArrival = 'LAST_ARRIVAL',
  LocationId = 'LOCATION_ID',
  Name = 'NAME',
}

export type ProductSortInput = {
  direction: SortDirections
  field: ProductSortFields
}

export type RootMutationType = {
  __typename?: 'RootMutationType'
  /** Create a brand */
  brandCreate?: Maybe<Brand>
  /** Delete a brand */
  brandDelete?: Maybe<Brand>
  /** Delete brands */
  brandDeleteAll: Array<Scalars['ID']>
  /** Edit a brand */
  brandEdit?: Maybe<Brand>
  /** Create a category */
  categoryCreate?: Maybe<Category>
  /** Delete a category */
  categoryDelete?: Maybe<Category>
  /** Delete categories */
  categoryDeleteAll: Array<Scalars['ID']>
  /** Edit a category */
  categoryEdit?: Maybe<Category>
  /** Edit a category */
  categoryEditFeaturedBrands?: Maybe<Category>
  /** Create the feed */
  feedCreate?: Maybe<Feed>
  /** Delete the feed */
  feedDelete?: Maybe<Feed>
  /** Edit the feed */
  feedEdit?: Maybe<Feed>
  /** Scrape the feed */
  feedScrape?: Maybe<Feed>
  /** Create a location */
  locationCreate?: Maybe<Location>
  /** Delete a location */
  locationDelete?: Maybe<Location>
  /** Edit a location */
  locationEdit?: Maybe<Location>
  /** Match reset */
  matchReset: Scalars['String']
  /**
   * Me edit.
   *       Edits the current user
   */
  meEdit?: Maybe<User>
  /** Click a product */
  productClick?: Maybe<Product>
  /** Create a product */
  productCreate?: Maybe<Product>
  /** Delete a product */
  productDelete?: Maybe<Product>
  /** Delete products */
  productDeleteAll: Array<Scalars['ID']>
  /** Edit a product */
  productEdit?: Maybe<Product>
  /** Product match */
  productMatch?: Maybe<Product>
  /** Create the source */
  sourceCreate?: Maybe<Source>
  /** Delete the source */
  sourceDelete?: Maybe<Source>
  /** Edit the source */
  sourceEdit?: Maybe<Source>
  /** Create a subscriber */
  subscriberCreate?: Maybe<Subscriber>
  /** Delete a subscriber */
  subscriberDelete?: Maybe<Subscriber>
  /** Edit a subscriber */
  subscriberEdit?: Maybe<Subscriber>
  /**
   * Create the initial owner account.
   *       Returns errors:
   *       * password is not strong (1 Digit, 1 Lower, 1 Upper and size minimum 8)
   *       * email not unique
   *       * email not properlly formatted
   *       * unauthorized if there's an owner already created
   */
  userCreate?: Maybe<User>
  /**
   * Sign in with a user.
   *       Returns erros:
   *         * email or password doesn't match any user
   */
  userSignIn?: Maybe<User>
  /**
   * Sign out from an account. Only works if users are logged in and with
   *       valid cookie. Expected to work always with no error if users are
   *       already logged in.
   */
  userSignOut?: Maybe<User>
}

export type RootMutationTypeBrandCreateArgs = {
  input: BrandInput
}

export type RootMutationTypeBrandDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeBrandDeleteAllArgs = {
  ids: Array<Scalars['ID']>
}

export type RootMutationTypeBrandEditArgs = {
  id: Scalars['ID']
  input: BrandInput
}

export type RootMutationTypeCategoryCreateArgs = {
  input: CategoryInput
}

export type RootMutationTypeCategoryDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeCategoryDeleteAllArgs = {
  ids: Array<Scalars['ID']>
}

export type RootMutationTypeCategoryEditArgs = {
  id: Scalars['ID']
  input: CategoryInput
}

export type RootMutationTypeCategoryEditFeaturedBrandsArgs = {
  brandIds: Array<Scalars['ID']>
  id: Scalars['ID']
}

export type RootMutationTypeFeedCreateArgs = {
  input: FeedInput
}

export type RootMutationTypeFeedDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeFeedEditArgs = {
  id: Scalars['ID']
  input: FeedInput
}

export type RootMutationTypeFeedScrapeArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeLocationCreateArgs = {
  input: LocationInput
}

export type RootMutationTypeLocationDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeLocationEditArgs = {
  id: Scalars['ID']
  input: LocationInput
}

export type RootMutationTypeMatchResetArgs = {
  brand?: Maybe<Scalars['String']>
}

export type RootMutationTypeMeEditArgs = {
  input?: Maybe<LoggedUserInput>
}

export type RootMutationTypeProductClickArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeProductCreateArgs = {
  input: ProductInput
}

export type RootMutationTypeProductDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeProductDeleteAllArgs = {
  ids: Array<Scalars['ID']>
}

export type RootMutationTypeProductEditArgs = {
  id: Scalars['ID']
  input: ProductInput
}

export type RootMutationTypeProductMatchArgs = {
  id: Scalars['ID']
  ids: Array<Scalars['ID']>
}

export type RootMutationTypeSourceCreateArgs = {
  input: SourceInput
}

export type RootMutationTypeSourceDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeSourceEditArgs = {
  id: Scalars['ID']
  input: SourceInput
}

export type RootMutationTypeSubscriberCreateArgs = {
  input: SubscriberInput
}

export type RootMutationTypeSubscriberDeleteArgs = {
  id: Scalars['ID']
}

export type RootMutationTypeSubscriberEditArgs = {
  id: Scalars['ID']
  input: SubscriberInput
}

export type RootMutationTypeUserCreateArgs = {
  input: UserInput
}

export type RootMutationTypeUserSignInArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type RootQueryType = {
  __typename?: 'RootQueryType'
  /** Application information */
  application?: Maybe<Application>
  /** Get brand by id */
  brand?: Maybe<Brand>
  /** Get highlighted brand */
  brandHighlighted?: Maybe<Brand>
  /** List brands with search, sort and pagination */
  brands: BrandPage
  /** List brands (all) with search and sort */
  brandsAll: Array<Brand>
  /** List brands by category */
  brandsByCategory: Array<Brand>
  /** List featured brands */
  brandsFeatured: Array<Brand>
  /** List trending brands */
  brandsTrending: Array<Brand>
  /** List categories with search, sort and pagination */
  categories: CategoryPage
  /** List categories (all) with search and sort */
  categoriesAll: Array<Category>
  /** Top categories with limit */
  categoriesTop: Array<CategoryTop>
  /** Get category by id */
  category?: Maybe<Category>
  /** Get feed by id */
  feed?: Maybe<Feed>
  /** List feeds with pagination, search and sort */
  feeds: FeedPage
  /** List feeds (all) with search and sort */
  feedsAll: Array<Feed>
  /** List status count */
  feedsStatusCount: Array<FeedStatus>
  /** Get location by id */
  location?: Maybe<Location>
  /** List locations with search, sort and pagination */
  locations: LocationPage
  /** List matchs with pagination */
  matchs: MatchPage
  /** List matchs (all) */
  matchsAll: Array<Match>
  /**
   * Get current user
   *       It will retrieve the current user (logged)
   */
  me?: Maybe<User>
  /** Get product by id */
  product?: Maybe<Product>
  /** List products with search, sort and pagination */
  products: ProductPage
  /** List products (all) with search and sort */
  productsAll: Array<Product>
  /** Top products by category with limit */
  productsLastArrival: Array<Product>
  /** Top products with limit */
  productsTop: Array<Product>
  /** Top products by category with limit */
  productsTopByCategory: Array<Product>
  /** Get source by id */
  source?: Maybe<Source>
  /** List source currencies */
  sourceCurrencies: Array<Scalars['String']>
  /** List sources with pagination, search and sort */
  sources: SourcePage
  /** List sources (all) with search and sort */
  sourcesAll: Array<Source>
  /** Get subscriber by id */
  subscriber?: Maybe<Subscriber>
  /** List subscribers with search, sort and pagination */
  subscribers: SubscriberPage
}

export type RootQueryTypeBrandArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeBrandsArgs = {
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<BrandSortInput>
}

export type RootQueryTypeBrandsAllArgs = {
  search?: Maybe<Scalars['String']>
  sort?: Maybe<BrandSortInput>
}

export type RootQueryTypeBrandsByCategoryArgs = {
  categoryId: Scalars['ID']
}

export type RootQueryTypeCategoriesArgs = {
  filter?: Maybe<CategoryFilterInput>
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<CategorySortInput>
}

export type RootQueryTypeCategoriesAllArgs = {
  filter?: Maybe<CategoryFilterInput>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<CategorySortInput>
}

export type RootQueryTypeCategoriesTopArgs = {
  limit: Scalars['Int']
}

export type RootQueryTypeCategoryArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeFeedArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeFeedsArgs = {
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<FeedSortInput>
}

export type RootQueryTypeFeedsAllArgs = {
  search?: Maybe<Scalars['String']>
  sort?: Maybe<FeedSortInput>
}

export type RootQueryTypeLocationArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeLocationsArgs = {
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<LocationSortInput>
}

export type RootQueryTypeMatchsArgs = {
  pagination: PaginationInput
}

export type RootQueryTypeProductArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeProductsArgs = {
  filter?: Maybe<ProductFilterInput>
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<ProductSortInput>
}

export type RootQueryTypeProductsAllArgs = {
  search?: Maybe<Scalars['String']>
  sort?: Maybe<ProductSortInput>
}

export type RootQueryTypeProductsTopArgs = {
  limit: Scalars['Int']
}

export type RootQueryTypeProductsTopByCategoryArgs = {
  limit: Scalars['Int']
}

export type RootQueryTypeSourceArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeSourcesArgs = {
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<SourceSortInput>
}

export type RootQueryTypeSourcesAllArgs = {
  search?: Maybe<Scalars['String']>
  sort?: Maybe<SourceSortInput>
}

export type RootQueryTypeSubscriberArgs = {
  id: Scalars['ID']
}

export type RootQueryTypeSubscribersArgs = {
  pagination: PaginationInput
  search?: Maybe<Scalars['String']>
  sort?: Maybe<SubscriberSortInput>
}

/** The feed scrape log object */
export type ScrapeLog = {
  __typename?: 'ScrapeLog'
  errorReason?: Maybe<Scalars['String']>
  errorStep?: Maybe<Scalars['String']>
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  pid: Scalars['String']
  status: Scalars['String']
  statusHistory?: Maybe<Array<Scalars['String']>>
  updatedAt: Scalars['DateTime']
}

export enum SortDirections {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** The source object */
export type Source = {
  __typename?: 'Source'
  barcode?: Maybe<Scalars['String']>
  brand?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  directUrl?: Maybe<Scalars['String']>
  externalId: Scalars['Int']
  feed: Feed
  feedId: Scalars['ID']
  finalPrice?: Maybe<Scalars['Int']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  images?: Maybe<Array<Scalars['String']>>
  inStock?: Maybe<Scalars['Int']>
  insertedAt: Scalars['DateTime']
  mpn?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  onSale?: Maybe<Scalars['Boolean']>
  price?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  productId?: Maybe<Scalars['ID']>
  refUrl?: Maybe<Scalars['String']>
  saleDiscount?: Maybe<Scalars['Int']>
  salePrice?: Maybe<Scalars['Int']>
  shippingCost?: Maybe<Scalars['Int']>
  shippingCostCurrency?: Maybe<Scalars['String']>
  sizes?: Maybe<Array<ProductSize>>
  sku?: Maybe<Scalars['String']>
  timeUpdated?: Maybe<Scalars['DateTime']>
  upc?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type SourceInput = {
  barcode?: Maybe<Scalars['String']>
  brand?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  directUrl?: Maybe<Scalars['String']>
  externalId: Scalars['Int']
  feedId: Scalars['ID']
  gender?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  inStock?: Maybe<Scalars['Int']>
  mpn?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  onSale?: Maybe<Scalars['Boolean']>
  price?: Maybe<Scalars['Int']>
  productId?: Maybe<Scalars['ID']>
  refUrl?: Maybe<Scalars['String']>
  saleDiscount?: Maybe<Scalars['Int']>
  shippingCost?: Maybe<Scalars['Int']>
  shippingCostCurrency?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  upc?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** The sources page object to return on paginated lists */
export type SourcePage = {
  __typename?: 'SourcePage'
  entries: Array<Source>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The source sort fields */
export enum SourceSortFields {
  ExternalId = 'EXTERNAL_ID',
  InStock = 'IN_STOCK',
  Name = 'NAME',
  Price = 'PRICE',
  Url = 'URL',
}

export type SourceSortInput = {
  direction: SortDirections
  field: SourceSortFields
}

/** The subscriber object */
export type Subscriber = {
  __typename?: 'Subscriber'
  email: Scalars['String']
  id: Scalars['ID']
  insertedAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type SubscriberInput = {
  email: Scalars['String']
}

/** The subscriber page object to return on paginated list */
export type SubscriberPage = {
  __typename?: 'SubscriberPage'
  entries: Array<Subscriber>
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

/** The subscriber sort fields */
export enum SubscriberSortFields {
  Email = 'EMAIL',
}

export type SubscriberSortInput = {
  direction: SortDirections
  field: SubscriberSortFields
}

/** The user object */
export type User = {
  __typename?: 'User'
  email: Scalars['String']
  id: Scalars['ID']
  isEnabled: Scalars['Boolean']
  name: Scalars['String']
  phoneNumber: Scalars['String']
  surname: Scalars['String']
  userType: UserType
}

export type UserInput = {
  email: Scalars['String']
  isEnabled: Scalars['Boolean']
  name: Scalars['String']
  password: Scalars['String']
  phoneNumber: Scalars['String']
  surname: Scalars['String']
}

/** The user types */
export enum UserType {
  Owner = 'OWNER',
}
