import React, { FC } from 'react'
import css from './buttonAdd.module.css'

import AddIcon from '../Icons/Add'

interface Props {
  label: string
  onClick: Function
}

const ButtonAdd: FC<Props> = ({ label, onClick }) => {
  return (
    <button type="button" className={css.host} onClick={(evt) => onClick(evt)}>
      <AddIcon isHighlighted />
      <span className={css.label}>{label}</span>
    </button>
  )
}

ButtonAdd.defaultProps = {}

export default ButtonAdd
