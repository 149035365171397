import React, { FC } from 'react'

import css from './centeredModal.module.css'

export enum ModalSize {
  Default = 'medium',
  Large = 'large',
  Small = 'small',
}

interface Props {
  isVisible: boolean
  onClick: Function
  size?: ModalSize
}
const CenteredModal: FC<Props> = ({ isVisible, onClick, children, size }) => {
  let sizeClass = css.medium

  switch (size) {
    case ModalSize.Default:
      sizeClass = css.medium
      break

    case ModalSize.Large:
      sizeClass = css.large
      break
  }

  return (
    <div className={`${css.host} ${sizeClass} ${!isVisible && css.hidden}`}>
      <div className={css.gray} onClick={(evt) => onClick(evt)}></div>

      <div className={css.modal}>{children}</div>
    </div>
  )
}

CenteredModal.defaultProps = {}

export default CenteredModal
