import React, { FC, useRef } from 'react'
import css from './checkbox.module.css'

import EmptySquare from '../Icons/EmptySquare'
import CheckedSquareSmall from '../Icons/CheckedSquareSmall'

interface Props {
  name: string
  value: any
  label?: String
  isDisabled?: boolean
  isChecked: boolean
  onChange?: (e: React.ChangeEvent<any>) => void
}

const Checkbox: FC<Props> = ({
  name,
  value,
  label,
  isChecked,
  onChange,
  isDisabled,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null)

  return (
    <div
      onClick={() => {
        checkboxRef.current?.click()
      }}
      className={`${css.host}  ${isDisabled && css.disabled}`}
    >
      <input
        ref={checkboxRef}
        type="checkbox"
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
      ></input>

      {isChecked ? <CheckedSquareSmall /> : <EmptySquare />}

      {label && <span className={css.label}>{label}</span>}
    </div>
  )
}

Checkbox.defaultProps = { isDisabled: false }

export default Checkbox
