import React, { FC } from 'react'
import css from './toast.module.css'

interface Props {
  message: string
  isError?: boolean
}

const Button: FC<Props> = ({ message, isError }) => (
  <div className={`${css.host} ${isError ? css.error : css.success}`}>
    {message}
  </div>
)

Button.defaultProps = {
  isError: false,
}

export default Button
