/**
 * Based on the solution provided at
 * https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
 */
import { useState, useEffect } from 'react'

const useDetectOutsideClick = (elementRef: React.RefObject<HTMLElement>, initialState: boolean) => {
  const [isActive, setIsActive] = useState(initialState)

  useEffect(() => {
    const pageClickEvent = (event: MouseEvent) => {
      if (!elementRef.current) {
        return
      }

      // If the active element exists and is clicked outside of
      if (elementRef.current.contains(event.target as Node) === false) {
        setIsActive(!isActive)
      }
    }

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent)
    }

    return () => {
      window.removeEventListener('click', pageClickEvent)
    }

  }, [isActive, elementRef])

  return [isActive, setIsActive] as const
}

export default useDetectOutsideClick