import { FC } from 'react'

import css from './tablePlaceholder.module.css'

interface Props {
  numberOfPlaceholderColumns: number
  numberOfPlaceholderRows: number
  numberOfPlaceholderActions: number
  isPaginated?: boolean
  isEmpty?: boolean
  emptyStatusText?: string
}

const TablePlaceholder: FC<Props> = ({
  numberOfPlaceholderColumns,
  numberOfPlaceholderRows,
  numberOfPlaceholderActions,
  isPaginated,
  isEmpty,
  emptyStatusText,
}) => {
  return (
    <div className={css.host}>
      {isPaginated && (
        <div className={css.paginationPlaceholderContainer}>
          <div
            className={`${css.paginationPlaceholderElement} ${
              isEmpty ? css.elementWithPlainBackground : css.loadingStatus
            }`}
          />
        </div>
      )}

      <div className={css.tableContainer}>
        {isEmpty && (
          <div className={css.emptyStatusContainer}>
            <p className={css.emptyStatusText}>{emptyStatusText}</p>
          </div>
        )}
        <table className={css.table}>
          <thead>
            <tr>
              {Array.from(Array(numberOfPlaceholderColumns), (_, index) => (
                <th key={index}>
                  <div
                    className={`${css.tablePlaceholderElement} ${
                      isEmpty
                        ? css.elementWithPlainBackground
                        : css.loadingStatus
                    }`}
                  />
                </th>
              ))}
              {Array.from(Array(numberOfPlaceholderActions), (_, index) => (
                <th key={index}></th>
              ))}
            </tr>
          </thead>

          <tbody>
            {Array.from(Array(numberOfPlaceholderRows), (_, index) => (
              <tr key={index}>
                {Array.from(Array(numberOfPlaceholderColumns), (_, index) => (
                  <td key={index}>
                    <div
                      className={`${css.tablePlaceholderElement} ${
                        isEmpty
                          ? css.elementWithPlainBackground
                          : css.loadingStatus
                      }`}
                    />
                  </td>
                ))}
                {Array.from(Array(numberOfPlaceholderActions), (_, index) => (
                  <td
                    className={css.tablePlaceholderActionsContainer}
                    key={index}
                  >
                    <div
                      className={`${css.tablePlaceholderAction} ${
                        isEmpty
                          ? css.elementWithPlainBackground
                          : css.loadingStatus
                      }`}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isPaginated && (
        <div className={css.paginationPlaceholderContainer}>
          <div
            className={`${css.paginationPlaceholderElement} ${
              isEmpty ? css.elementWithPlainBackground : css.loadingStatus
            }`}
          />
        </div>
      )}
    </div>
  )
}

export default TablePlaceholder
