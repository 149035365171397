import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeedsAllLazyQuery } from '../../../../queries/autogenerate/hooks'
import {
  SourceCreateMutationVariables,
  SourceEditMutationVariables,
} from '../../../../queries/autogenerate/operations'

import {
  calculateDiscountedPrice,
  divideValueBy100,
} from '../../../../helpers/Functions'

import Button, { ButtonSize } from '../../../../components/Button'
import Table from '../../../../components/Table'

import css from './sourcesTable.module.css'

interface Props {
  arrayOfSources: Array<
    SourceCreateMutationVariables | SourceEditMutationVariables
  >
  displayActions: boolean
  setSourceModalPrefill?: Function
  setIsSourceModalVisible?: Function
  remove?: Function
}

const SourcesTable: FC<Props> = ({
  arrayOfSources,
  displayActions,
  setSourceModalPrefill,
  setIsSourceModalVisible,
  remove,
}) => {
  const { t } = useTranslation(['general', 'form', 'table'])

  const [getFeeds, { data: feedsData }] = useFeedsAllLazyQuery()

  // Convert values from server
  const convertedArray = arrayOfSources.map((item) => ({
    ...item,
    price: divideValueBy100(item.price!),
    saleDiscount: divideValueBy100(item.saleDiscount!),
    shippingCost: divideValueBy100(item.shippingCost!),
  }))

  useEffect(() => {
    getFeeds()
  }, [getFeeds])

  const findFeedName = (feedId: string) =>
    feedsData?.feedsAll.find((feed) => feed.id === feedId)

  return (
    <Table
      entityName={t('form:sources-table')}
      pageNumber={1}
      entriesPerPage={10}
    >
      <tbody>
        {convertedArray.map(
          (
            source: SourceCreateMutationVariables | SourceEditMutationVariables,
            index: number
          ) => {
            let {
              feedId,
              currency,
              price,
              onSale,
              saleDiscount,
              shippingCost,
              shippingCostCurrency,
              inStock,
            } = source

            return (
              <tr key={index}>
                <td>
                  <span className={css.boldText}>
                    {findFeedName(feedId)?.merchant}
                  </span>{' '}
                  / {findFeedName(feedId)?.network}
                </td>
                <td>
                  <span className={css.sourceAttributeTitle}>
                    {t('table:currency-label')}
                  </span>
                  <br />
                  {currency}
                </td>
                <td>
                  <span className={css.sourceAttributeTitle}>
                    {t('table:price-label')}
                  </span>
                  <br />
                  {price}
                </td>
                <td className={css.noWrapText}>
                  <span className={css.sourceAttributeTitle}>
                    {t('table:final-price-label')}
                  </span>
                  <br />
                  {onSale && price && saleDiscount ? (
                    <span className={css.boldText}>
                      {calculateDiscountedPrice(price, saleDiscount)}
                    </span>
                  ) : (
                    <span className={css.boldText}>{price}</span>
                  )}{' '}
                  {onSale && saleDiscount && saleDiscount > 0 ? (
                    <span>{`(-${saleDiscount} %)`}</span>
                  ) : (
                    ''
                  )}
                  {onSale && saleDiscount && saleDiscount > 0 ? (
                    <span className={css.onSaleBadge}>
                      {t('table:on-sale-label')}
                    </span>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <span className={css.sourceAttributeTitle}>
                    {t('table:shipping-cost-label')}
                  </span>
                  <br />
                  {`${shippingCost} ${shippingCostCurrency}`}
                </td>
                <td>
                  <span className={css.sourceAttributeTitle}>
                    {t('table:stock-label')}
                  </span>
                  <br />
                  {inStock ? `${inStock}` : '-'}
                </td>

                <td className={css.buttonsCell}>
                  {displayActions &&
                    setSourceModalPrefill &&
                    setIsSourceModalVisible &&
                    remove && (
                      <>
                        <Button
                          size={ButtonSize.Small}
                          label={t('general:edit')}
                          /*
                           * On click, the source present in the original (non-coverted) array is used to re-fill values
                           * This is needed, because the prefilled information would otherwise be populated with values divided by 100
                           * The NewSourceModal component, in fact, divides values by 100 before displaying them
                           * *
                           */
                          onClick={() => {
                            setSourceModalPrefill({
                              source: arrayOfSources[index] as
                                | SourceEditMutationVariables
                                | SourceCreateMutationVariables,
                              index: index,
                            })
                            setIsSourceModalVisible(true)
                          }}
                        />
                        <Button
                          size={ButtonSize.Small}
                          label={t('general:delete')}
                          secondary
                          onClick={() => {
                            remove(index)
                          }}
                        />
                      </>
                    )}
                </td>
              </tr>
            )
          }
        )}
      </tbody>
    </Table>
  )
}

export default SourcesTable
