import React, { FC } from 'react'

interface Props {}

const List: FC<Props> = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4h1v1H3V4zM6 4h15v1H6V4zM6 9h15v1H6V9zM6 14h15v1H6v-1zM6 19h15v1H6v-1zM3 14h1v1H3v-1zM3 9h1v1H3V9zM3 19h1v1H3v-1z"
        fill="hsla(0, 0%, 65%, 1)"
      />
    </svg>
  )
}

List.defaultProps = {}

export default List
