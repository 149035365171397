import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
  matchPath,
} from 'react-router-dom'

import Auth from '../../services/Auth'
import { showSuccessToast } from '../../services/Toaster'
import graphQLClient from '../../services/GraphQLClient'
import { allRoutes, sidebarOptions } from '../../services/Routes'

import { useLogoutMutation, useMeQuery } from '../../queries/autogenerate/hooks'

import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import List from '../../components/Icons/List'

import css from './home.module.css'

interface Props {}

const Home: React.FC<Props> = () => {
  const { t } = useTranslation(['home', 'general'])
  const history = useHistory()
  const { pathname } = useLocation()

  const { data: meData } = useMeQuery()
  const accountData = meData?.me

  const routes = allRoutes(t)
  const breadcrumbOptions = routes
    .filter((route) => matchPath(pathname, { path: route.link, exact: true }))
    .map((route) => ({
      ...route,
      label: route.breadCrumbtitle,
      onclick: undefined,
    }))

  const currentSelectedRoute = breadcrumbOptions.find((route) =>
    matchPath(pathname, { path: route.link, exact: true })
  )

  const [logoutMutation] = useLogoutMutation({
    onError: () => {
      // we don't care if there is an error we still logout at the UI level
    },
  })

  const handleSettingsClick = () => {
    history.push('/account-settings')
  }

  const handleLogoutClick = () => {
    showSuccessToast(t('general:logout-success'))

    logoutMutation()

    Auth.logout()

    // make sure the cache is cleared
    graphQLClient.clearStore()

    history.push('/login')
  }

  const avatarOptions = [
    { label: t('general:settings-title'), onClick: handleSettingsClick },
    { label: t('general:logout'), onClick: handleLogoutClick },
  ]

  return (
    <div className={css.host}>
      <Sidebar sections={sidebarOptions(t)} />

      <div className={css.rightContent}>
        <div className={css.header}>
          {/* TODO: pass proper avatar to the header */}
          <Header
            avatarName={accountData ? accountData?.name : t('general:loading')}
            avatarSurname={accountData ? accountData?.surname : ''}
            avatarLinks={avatarOptions}
            breadcrumbIcon={
              currentSelectedRoute ? currentSelectedRoute.iconBig : <List />
            }
            breadcrumbLinks={breadcrumbOptions}
          />
        </div>

        <Switch>
          {routes.map(({ breadCrumbtitle, link, component }, index) => (
            <Route exact path={link} key={index}>
              {component ? component : <p>[{breadCrumbtitle}] To be done</p>}
            </Route>
          ))}

          {/* fallback redirect */}
          <Redirect to="/dashboard" />
        </Switch>
      </div>
    </div>
  )
}

export default Home
