import React, { FC } from 'react'

interface Props {}

const CheckedSquareSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2071 6.5L10.5 5.79289l-3.5 3.5-1.5-1.5L4.79289 8.5l1.85356 1.8536c.19526.1952.51184.1952.7071 0L11.2071 6.5z"
        fill="hsla(0, 0%, 34%, 1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3c0-.55228.44772-1 1-1h10c.5523 0 1 .44772 1 1v10c0 .5523-.4477 1-1 1H3c-.55228 0-1-.4477-1-1V3zm1 0h10v10H3V3z"
        fill="hsla(0, 0%, 34%, 1)"
      />
    </svg>
  )
}

CheckedSquareSmall.defaultProps = {}

export default CheckedSquareSmall
