import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  useFeedsAllLazyQuery,
  useCurrenciesLazyQuery,
} from '../../../../queries/autogenerate/hooks'

import {
  SourceCreateMutationVariables,
  SourceEditMutationVariables,
} from '../../../../queries/autogenerate/operations'

import {
  stringRequiredValidator,
  integerRequiredValidator,
  referenceIdValidator,
  urlRequiredValidator,
} from '../../../../helpers/FormValidators'

import {
  calculateDiscountedPrice,
  divideValueBy100,
  multiplyValueBy100,
} from '../../../../helpers/Functions'

import CenteredModal, { ModalSize } from '../../../../components/CenteredModal'
import Checkbox from '../../../../components/Checkbox'
import FormSectionTitle from '../../../../components/FormSectionTitle'
import Input from '../../../../components/Input'
import Dropdown from '../../../../components/Dropdown'
import Button from '../../../../components/Button'

import css from './newSourceModal.module.css'

interface Props {
  isVisible: boolean
  sourceInput?: SourceCreateMutationVariables | SourceEditMutationVariables
  onCloseModal: Function
  onSave: (editedSource: SourceEditMutationVariables) => void
  onCreate: (newSource: SourceCreateMutationVariables) => void
}

const NewSourceModal: FC<Props> = ({
  isVisible,
  sourceInput,
  onCloseModal,
  onSave,
  onCreate,
}) => {
  const { t } = useTranslation(['products', 'form', 'feeds'])

  const [getFeeds, { data: feedsData }] = useFeedsAllLazyQuery()
  const [getCurrencies, { data: currenciesData }] = useCurrenciesLazyQuery()
  const [initialValues, setInitialValues] = useState<
    SourceCreateMutationVariables | undefined
  >(undefined)

  const NewSourceSchema = Yup.object().shape({
    externalId: integerRequiredValidator(t),
    feedId: referenceIdValidator(t),
    currency: stringRequiredValidator(t),
    price: integerRequiredValidator(t),
    url: urlRequiredValidator(t),
    directUrl: urlRequiredValidator(t),
    refUrl: urlRequiredValidator(t),
    inStock: integerRequiredValidator(t),
  })

  const handleSubmit = (
    values: SourceCreateMutationVariables | SourceEditMutationVariables
  ) => {
    const newSourceData = { ...initialValues, ...values }

    /*
     * If the  item is not on sale, delete the sale discount
     */

    newSourceData.saleDiscount = newSourceData.onSale
      ? newSourceData.saleDiscount
      : 0
    /*
     * Whether it is a new price or the same as before,
     * values must be converted back to server-format before submission
     */

    newSourceData.price = multiplyValueBy100(
      newSourceData.price !== initialValues!.price
        ? newSourceData.price!
        : initialValues!.price!
    )

    newSourceData.saleDiscount = multiplyValueBy100(
      newSourceData.saleDiscount !== initialValues!.saleDiscount
        ? newSourceData.saleDiscount!
        : initialValues!.saleDiscount!
    )

    newSourceData.shippingCost = multiplyValueBy100(
      newSourceData.shippingCost !== initialValues!.shippingCost
        ? newSourceData.shippingCost!
        : initialValues!.shippingCost!
    )

    if (sourceInput?.feedId) {
      onSave(newSourceData as SourceEditMutationVariables)
    } else {
      onCreate(newSourceData as SourceCreateMutationVariables)
    }

    setInitialValues(undefined)
    onCloseModal()
  }

  useEffect(() => {
    if (sourceInput) {
      setInitialValues({
        ...sourceInput,
        price: sourceInput.price && divideValueBy100(sourceInput.price),
        saleDiscount:
          sourceInput.saleDiscount &&
          divideValueBy100(sourceInput.saleDiscount),
        shippingCost:
          sourceInput.shippingCost &&
          divideValueBy100(sourceInput.shippingCost),
        barcode: sourceInput.barcode ?? '',
        mpn: sourceInput.mpn ?? '',
        upc: sourceInput.upc ?? '',
        sku: sourceInput.sku ?? '',
      })
    } else {
      setInitialValues({
        externalId: 0,
        feedId: '',
        currency: 'GBP',
        price: 0,
        onSale: false,
        saleDiscount: 0,
        shippingCost: 0,
        shippingCostCurrency: 'GBP',
        url: '',
        directUrl: '',
        refUrl: '',
        inStock: 0,
        barcode: '',
        mpn: '',
        upc: '',
        sku: '',
      })
    }

    getFeeds()
    getCurrencies()
  }, [getFeeds, getCurrencies, sourceInput, setInitialValues, isVisible])

  const generateFeedName = (merchant: string, network: string) =>
    `${merchant} (${network})`

  const availableFeeds = feedsData?.feedsAll.map((feed) => ({
    title: generateFeedName(feed.merchant, feed.network),
    value: feed.id,
  }))

  const availableCurrencies =
    currenciesData?.sourceCurrencies &&
    currenciesData.sourceCurrencies.length > 0
      ? currenciesData?.sourceCurrencies!.map((currency: string) => {
          return {
            title: currency,
            value: currency,
          }
        })
      : [{ title: 'GBP', value: 'GBP' }]

  return (
    <CenteredModal
      isVisible={isVisible}
      onClick={() => {
        onCloseModal()
        setInitialValues(undefined)
      }}
      size={ModalSize.Large}
    >
      {initialValues && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={NewSourceSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange }) => {
            return (
              <Form>
                <div className={css.formRow}>
                  <FormSectionTitle title={t('products:new-source-title')} />
                </div>
                <div className={css.formFieldsContainer}>
                  {availableFeeds && initialValues && (
                    <>
                      <Dropdown
                        label={t('form:feed-input-label')}
                        options={availableFeeds}
                        name="feedId"
                        value={values.feedId}
                        placeholderText={values.feedId ? values.feedId : ''}
                        errorMessage={
                          touched.feedId && errors.feedId
                            ? errors.feedId
                            : undefined
                        }
                        onChange={handleChange}
                      />
                      <Input
                        type="number"
                        name="externalId"
                        value={values.externalId}
                        label={t('form:externalId-input-label')}
                        onChange={handleChange}
                        errorMessage={
                          touched.externalId && errors.externalId
                            ? errors.externalId
                            : undefined
                        }
                      />
                    </>
                  )}
                  {/* PRICE SECTION */}
                  <div className={css.subsectionWrapper}>
                    <h2 className={css.sectionSubtitle}>{t('form:price')}</h2>

                    <Checkbox
                      name="onSale"
                      value="onSale"
                      label={t('form:on-sale')}
                      isChecked={values.onSale!}
                      onChange={handleChange}
                    />
                    <div className={css.sideInputWrapper}>
                      {availableCurrencies && (
                        <Dropdown
                          label={t('form:currency')}
                          options={availableCurrencies}
                          name="currency"
                          value={values.currency}
                          placeholderText={
                            values.currency
                              ? values.currency[0]
                              : t('feeds:select-currency-placeholder')
                          }
                          errorMessage={
                            touched.currency && errors.currency
                              ? errors.currency
                              : undefined
                          }
                          onChange={handleChange}
                        />
                      )}
                      <Input
                        type="number"
                        name="price"
                        value={values.price}
                        label={t('form:price')}
                        onChange={handleChange}
                        errorMessage={
                          touched.price && errors.price
                            ? errors.price
                            : undefined
                        }
                      />
                    </div>

                    <div className={css.sideInputWrapper}>
                      {values.onSale && (
                        <>
                          <Input
                            type="number"
                            name="saleDiscount"
                            value={values.saleDiscount}
                            label={t('form:sale-discount')}
                            onChange={handleChange}
                          />
                          <Input
                            type="number"
                            name="finalprice"
                            placeholderText={
                              values.saleDiscount
                                ? calculateDiscountedPrice(
                                    values.price!,
                                    values.saleDiscount!
                                  )
                                : '0.00'
                            }
                            label={t('form:final-price')}
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div className={css.sideInputWrapper}>
                      <Input
                        type="text"
                        name="shippingCostCurrency"
                        value={values.shippingCostCurrency}
                        label={t('form:shipping-cost-currency')}
                        onChange={handleChange}
                      />
                      <Input
                        type="number"
                        name="shippingCost"
                        value={values.shippingCost}
                        label={t('form:shipping-cost')}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <hr className={css.divider} />

                  {/* AVAILABILITY AND IDENTIFIERS */}
                  <div className={css.subsectionWrapper}>
                    <h2 className={css.sectionSubtitle}>
                      {t('form:availability-and-identifiers-label')}
                    </h2>

                    <Input
                      type="number"
                      name="inStock"
                      value={values.inStock}
                      label={t('form:stock-input-label')}
                      onChange={handleChange}
                      errorMessage={
                        touched.inStock && errors.inStock
                          ? errors.inStock
                          : undefined
                      }
                    />

                    <div className={css.sideInputWrapper}>
                      <Input
                        type="text"
                        name="barcode"
                        value={values.barcode}
                        label={t('form:barcode-input-label')}
                        onChange={handleChange}
                        errorMessage={
                          touched.barcode && errors.barcode
                            ? errors.barcode
                            : undefined
                        }
                      />
                      <Input
                        type="text"
                        name="mpn"
                        value={values.mpn}
                        label={t('form:mpn-input-label')}
                        onChange={handleChange}
                        errorMessage={
                          touched.mpn && errors.mpn ? errors.mpn : undefined
                        }
                      />
                    </div>

                    <div className={css.sideInputWrapper}>
                      <Input
                        type="text"
                        name="upc"
                        value={values.upc}
                        label={t('form:upc-input-label')}
                        onChange={handleChange}
                        errorMessage={
                          touched.upc && errors.upc ? errors.upc : undefined
                        }
                      />
                      <Input
                        type="text"
                        name="sku"
                        value={values.sku}
                        label={t('form:sku-input-label')}
                        onChange={handleChange}
                        errorMessage={
                          touched.sku && errors.sku ? errors.sku : undefined
                        }
                      />
                    </div>
                  </div>

                  <hr className={css.divider} />

                  {/* URLS SECTION */}
                  <div className={css.subsectionWrapper}>
                    <Input
                      type="text"
                      name="url"
                      value={values.url}
                      label={t('form:url')}
                      onChange={handleChange}
                      errorMessage={
                        touched.url && errors.url ? errors.url : undefined
                      }
                    />
                    <Input
                      type="text"
                      name="directUrl"
                      value={values.directUrl}
                      label={t('form:directUrl')}
                      onChange={handleChange}
                      errorMessage={
                        touched.directUrl && errors.directUrl
                          ? errors.directUrl
                          : undefined
                      }
                    />
                    <Input
                      type="text"
                      name="refUrl"
                      value={values.refUrl}
                      label={t('form:refUrl')}
                      onChange={handleChange}
                      errorMessage={
                        touched.refUrl && errors.refUrl
                          ? errors.refUrl
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className={css.buttons}>
                  <Button
                    type="button"
                    label={t('general:cancel')}
                    loadingLabel={t('general:cancel')}
                    onClick={() => {
                      onCloseModal()
                    }}
                    secondary
                  />
                  <Button
                    type="submit"
                    label={
                      sourceInput?.feedId
                        ? t('form:save-changes-label')
                        : t('feeds:add-feed-label')
                    }
                    loadingLabel={t('general:loading')}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </CenteredModal>
  )
}

export default NewSourceModal
