import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { useMeLazyQuery } from './queries/autogenerate/hooks'

import { Toaster } from 'react-hot-toast'

import GraphQLClient from './services/GraphQLClient'
import Auth from './services/Auth'

import Login from './scenes/Login'
import Home from './scenes/Home'

import css from './App.module.css'

interface PrivateRouteProps {
  path: string
  children: React.ReactElement
}

const Loader = () => <div></div>

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...props }) => (
  <Route
    {...props}
    render={() => (Auth.isLogedIn() ? children : <Redirect to="/login" />)}
  />
)

const Page: React.FC<{}> = () => {
  const [getMe, { error, loading }] = useMeLazyQuery()
  const userHasCookie = Auth.isLogedIn()

  // on initial load: if we have a cookie we need to validate that it is still valid
  useEffect(() => {
    if (userHasCookie) {
      getMe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // our cookie is not valid
  if (error) {
    Auth.logout()
  }

  // still trying to get the user
  if (loading) {
    return <Loader />
  }

  return (
    <div className={css.host}>
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default function App() {
  return (
    <ApolloProvider client={GraphQLClient}>
      <Page />
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        containerStyle={{ bottom: 24 }}
      />
    </ApolloProvider>
  )
}
