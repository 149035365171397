import React, { FC } from 'react'

interface Props {
  isDisabled?: boolean
}

const TableChevronRightSingle: FC<Props> = ({ isDisabled }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={isDisabled ? '0.2' : '1'}
    >
      <path d="M11.8 16L15.8 12L11.8 8" stroke="hsl(0, 0%, 10%)" />
      <rect
        x="1.30005"
        y="0.5"
        width="23"
        height="23"
        stroke="hsl(0, 0%, 20%)"
      />
    </svg>
  )
}

TableChevronRightSingle.defaultProps = {}

export default TableChevronRightSingle
