import React, { FC } from 'react'

interface Props {
  isDisabled?: boolean
}

const TableChevronLeftSingle: FC<Props> = ({ isDisabled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={isDisabled ? '0.2' : '1'}
    >
      <path d="M13 8L9 12L13 16" stroke="hsl(0, 0%, 10%)" />
      <rect x="0.5" y="0.5" width="23" height="23" stroke="hsl(0, 0%, 20%)" />
    </svg>
  )
}

TableChevronLeftSingle.defaultProps = {}

export default TableChevronLeftSingle
