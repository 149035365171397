import React, { FC } from 'react'

interface Props {}

const CircleFilledSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11c1.65685 0 3-1.34315 3-3S9.65685 5 8 5 5 6.34315 5 8s1.34315 3 3 3z"
        fill="hsla(0, 0%, 34%, 1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8c0 3.3137-2.6863 6-6 6-3.31371 0-6-2.6863-6-6 0-3.31371 2.68629-6 6-6 3.3137 0 6 2.68629 6 6zm-1 0c0 2.7614-2.2386 5-5 5-2.76142 0-5-2.2386-5-5 0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5z"
        fill="hsla(0, 0%, 34%, 1)"
      />
    </svg>
  )
}

CircleFilledSmall.defaultProps = {}

export default CircleFilledSmall
