import React, { FC } from 'react'
import css from './formSectionTitle.module.css'

interface Props {
  title: string
}

const FormSectionTitle: FC<Props> = ({ title }) => {
  return <div className={css.host}>{title}</div>
}
FormSectionTitle.defaultProps = {}

export default FormSectionTitle
