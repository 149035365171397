import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'

import {
  useSearchAmongProductsLazyQuery,
  useProductMatchMutation,
} from '../../../../../queries/autogenerate/hooks'
import { MatchingListItemFragment } from '../../../../../queries/autogenerate/operations'

import {
  showSuccessToast,
  showErrorToast,
} from '../../../../../services/Toaster'

import Button from '../../../../../components/Button'
import MatchingListItem from '../../../components/MatchingListItem'
import SearchBox, { SearchBoxSize } from '../../../../../components/SearchBox'
import TablePlaceholder from '../../../../../components/TablePlaceholder'

import css from './manual.module.css'

const Manual = () => {
  const { t } = useTranslation(['general', 'table', 'matching'])

  /*
   * State
   */
  const [searchInput, setSearchInput] = useState('')
  const [idOfProductToMatch, setIdOfProductToMatch] = useState('')
  // This is a provisory useState which was created to induce a products search by brand with a useSearchAmongProductsLazyQuery
  // The BE will laterwards provide a query which will instead require the idOfProductToMatch state rather than the brandToMatch
  const [brandToMatch, setBrandToMatch] = useState('')
  const [matchInput, setMatchInput] = useState('')
  const [filteredMatchResults, setFilteredMatchResults] = useState<
    Array<MatchingListItemFragment>
  >([])

  const [showResults, setShowResults] = useState(false)

  const clearState = () => {
    setSearchInput('')
    setIdOfProductToMatch('')
    setMatchInput('')
    setBrandToMatch('')
    setFilteredMatchResults([])
  }

  /*
   * Hooks
   */
  const [
    searchAmongProducts,
    {
      data: searchResults,
      loading: loadingSearchResults,
      error: errorSearchingResults,
    },
  ] = useSearchAmongProductsLazyQuery({
    onError: () => {
      leftPanelMessage = t('general:error-message')
    },
  })
  const [
    searchAmongMatchingProducts,
    {
      data: matchingResults,
      loading: loadingMatchingResults,
      error: errorMatchingResults,
    },
  ] = useSearchAmongProductsLazyQuery({
    onError: () => {
      rightPanelMessage = t('general:error-message')
    },
  })
  const [selectedMatchesIds, setSelectedMatchesIds] = useState<string[]>([])
  const [matchProducts] = useProductMatchMutation({
    onCompleted: () => {
      showSuccessToast(t('matching:matching-success-message'))
      clearState()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  /*
   * Helpers
   */

  let leftPanelMessage = t('matching:search-tagline-message')
  let rightPanelMessage = t('matching:select-tagline-message')

  if (!errorSearchingResults && searchResults?.productsAll.length === 0) {
    leftPanelMessage = t('matching:no-search-results-message')
  }
  if (
    !errorMatchingResults &&
    !loadingMatchingResults &&
    matchingResults?.productsAll.length === 0
  ) {
    rightPanelMessage = t('matching:no-search-results-message')
  }

  if (
    !errorMatchingResults &&
    !loadingMatchingResults &&
    (matchingResults?.productsAll.length === 0 ||
      (matchInput !== '' && filteredMatchResults.length === 0))
  ) {
    rightPanelMessage = t('matching:select-tagline-message')
  }

  /*
   * Handlers
   */

  /* Left panel */
  const handleOnSearchClick = () => {
    if (searchInput !== '') {
      searchAmongProducts({
        variables: { search: searchInput },
      })
      setShowResults(true)
      setIdOfProductToMatch('')
      setBrandToMatch('')
      setFilteredMatchResults([])
    }
  }

  /* Right panel */
  const handleMatchingSearchClick = () => {
    if (idOfProductToMatch !== '' && matchInput !== '') {
      const arrayOfStrings = matchInput.split(' ')

      const filteredResults: Array<MatchingListItemFragment> = []

      for (let product of matchingResults!.productsAll) {
        if (
          arrayOfStrings.every((string) =>
            product.name.toLowerCase().includes(string.toLowerCase())
          )
        ) {
          filteredResults.push(product)
        }
      }

      setFilteredMatchResults(filteredResults)
    }

    if (idOfProductToMatch !== '' && matchInput === '') {
      setFilteredMatchResults(matchingResults!.productsAll)
    }
  }
  const handleArrayOfItemsSelected = (id: string) => {
    if (selectedMatchesIds.includes(id)) {
      const updatedArray = selectedMatchesIds.filter(
        (productId) => productId !== id
      )
      setSelectedMatchesIds(updatedArray)
    } else {
      const updatedArray = [...selectedMatchesIds, id]

      setSelectedMatchesIds(updatedArray)
    }
  }
  /* On submit */
  const onSubmit = () => {
    matchProducts({
      variables: {
        productId: idOfProductToMatch,
        matchesIds: selectedMatchesIds,
      },
    })
    setShowResults(false)
    clearState()
  }

  /*
   * Use effects
   */

  useEffect(() => {
    // Everytime a product is selected on the left panel, run this query to display matching results
    if (brandToMatch !== '') {
      setMatchInput('')
      setFilteredMatchResults([])

      searchAmongMatchingProducts({
        variables: { search: brandToMatch },
      })
    }
  }, [searchInput, searchAmongMatchingProducts, brandToMatch])

  return (
    <section className={css.host}>
      <div className={css.panelsWrapper}>
        {/*
         **************************
         ** LEFT PANEL **
         **************************
         */}
        <div className={css.panel}>
          {/* SEARCH */}
          <section className={css.searchSection}>
            <div className={css.panelTagline}>
              {t('matching:search-input-tagline')}
            </div>
            <SearchBox
              size={SearchBoxSize.Large}
              inputName="searchInput"
              placeholder={t('matching:search-input-placeholder')}
              value={searchInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value)
              }}
              onClick={handleOnSearchClick}
            />
          </section>
          {
            /* If the matching data is still being loaded... */
            loadingSearchResults && !searchResults && (
              <TablePlaceholder
                numberOfPlaceholderColumns={5}
                numberOfPlaceholderRows={3}
                numberOfPlaceholderActions={0}
              />
            )
          }
          {
            /* If nothing was yet searched or something went wrong (error or no results)... */
            (!showResults && searchResults) ||
            (!loadingSearchResults &&
              (!searchResults || searchResults?.productsAll.length === 0)) ? (
              <div className={css.messageWrapper}>
                <p className={css.messageContent}>{leftPanelMessage}</p>
              </div>
            ) : (
              /* If there are results! */
              showResults && (
                <form className={css.listItemsWrapper}>
                  {searchResults?.productsAll.map((product, key) => {
                    return (
                      <MatchingListItem
                        key={key}
                        product={product}
                        isChecked={idOfProductToMatch === product.id}
                        handleChange={() => {
                          if (product.id !== idOfProductToMatch) {
                            setIdOfProductToMatch(product.id)
                          } else {
                            clearState()
                          }
                          // To be removed: provisory for testing
                          setBrandToMatch(product.brand!.name ?? product.color)
                        }}
                      />
                    )
                  })}
                </form>
              )
            )
          }
        </div>
        {/*
         **************************
         ** RIGHT PANEL **
         **************************
         */}
        <div className={css.panel}>
          {/* SEARCH */}
          <section className={css.searchSection}>
            <div className={css.panelTagline}>
              {t('matching:search-input-tagline')}
            </div>
            <SearchBox
              size={SearchBoxSize.Large}
              inputName="matchInput"
              placeholder={t('matching:search-input-placeholder')}
              value={matchInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMatchInput(e.target.value)
              }}
              onClick={handleMatchingSearchClick}
              disabled={idOfProductToMatch === '' || !matchProducts}
            />
          </section>
          {
            /* If the matching data is still being loaded... */
            loadingMatchingResults && !matchingResults && (
              <TablePlaceholder
                numberOfPlaceholderColumns={5}
                numberOfPlaceholderRows={3}
                numberOfPlaceholderActions={0}
              />
            )
          }
          {
            /* If nothing was yet searched or something went wrong (error or no results).... */
            idOfProductToMatch === '' ||
            (!loadingSearchResults &&
              !searchResults &&
              !loadingMatchingResults &&
              (!matchingResults ||
                matchingResults?.productsAll.length === 0)) ||
            (matchInput !== '' && filteredMatchResults.length === 0) ? (
              <div className={css.messageWrapper}>
                <p className={css.messageContent}>{rightPanelMessage}</p>
              </div>
            ) : (
              /* If there are results! */
              <form className={css.listItemsWrapper}>
                {idOfProductToMatch !== '' &&
                  matchingResults &&
                  matchingResults.productsAll.length > 0 &&
                  filteredMatchResults.length > 0 &&
                  filteredMatchResults.map(
                    (product, key) =>
                      product.id !== idOfProductToMatch && (
                        <MatchingListItem
                          key={key}
                          product={product}
                          isMatchingResult
                          isChecked={selectedMatchesIds.includes(product.id)}
                          handleChange={() => {
                            handleArrayOfItemsSelected(product.id)
                          }}
                        />
                      )
                  )}

                {idOfProductToMatch !== '' &&
                  filteredMatchResults.length === 0 &&
                  matchingResults &&
                  matchingResults?.productsAll.map(
                    (product, key) =>
                      product.id !== idOfProductToMatch && (
                        <MatchingListItem
                          key={key}
                          product={product}
                          isMatchingResult
                          isChecked={selectedMatchesIds.includes(product.id)}
                          handleChange={() => {
                            handleArrayOfItemsSelected(product.id)
                          }}
                        />
                      )
                  )}
              </form>
            )
          }
        </div>
      </div>
      <div className={css.buttonWrapper}>
        <Button
          label={t('matching:confirm-match-label')}
          onClick={() => onSubmit()}
          disabled={selectedMatchesIds.length === 0}
        />
      </div>
    </section>
  )
}

export default Manual
