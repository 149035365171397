import * as Types from './operations'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    name
    surname
    phoneNumber
    isEnabled
    userType
  }
`
export const DashboardInfoFragmentDoc = gql`
  fragment DashboardInfo on Product {
    id
    name
    image
    clicks
    brand {
      name
    }
    category {
      name
      category {
        name
      }
    }
  }
`
export const FeedFragmentDoc = gql`
  fragment Feed on Feed {
    id
    network
    merchantId
    merchant
    frequency
    productCount
    productLoaded
    isEnabled
    scrapeLogs {
      updatedAt
      status
    }
  }
`
export const MatchFragmentDoc = gql`
  fragment Match on Match {
    id
    sourceOneId
    sourceOne {
      brand
      product {
        name
        id
        image
      }
    }
    sourceTwoId
    sourceTwo {
      brand
      product {
        name
        id
        image
      }
    }
  }
`
export const BrandFragmentDoc = gql`
  fragment Brand on Brand {
    description
    id
    name
    countProducts
    trending
    trendingImageUrl
    isFeatured
    highlighted
    highlightedImageUrl
  }
`
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    categoryId
    name
    description
    countProducts
    featuredBrands {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`
export const SourceFragmentDoc = gql`
  fragment Source on Source {
    currency
    price
    onSale
    saleDiscount
    shippingCost
    shippingCostCurrency
    inStock
  }
`
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    name
    image
    brand {
      ...Brand
    }
    category {
      ...Category
      subCategories {
        ...Category
      }
      category {
        name
      }
    }
    insertedAt
    updatedAt
    isVisible
    sources {
      ...Source
    }
  }
  ${BrandFragmentDoc}
  ${CategoryFragmentDoc}
  ${SourceFragmentDoc}
`
export const MatchingListItemFragmentDoc = gql`
  fragment MatchingListItem on Product {
    id
    name
    image
    color
    brand {
      name
    }
    sources {
      price
      currency
      feed {
        merchant
        network
      }
    }
  }
`
export const SourceUrlsFragmentDoc = gql`
  fragment SourceUrls on Source {
    url
    directUrl
    refUrl
  }
`
export const SourceIdentifiersFragmentDoc = gql`
  fragment SourceIdentifiers on Source {
    externalId
    barcode
    mpn
    upc
    sku
  }
`
export const MeDocument = gql`
  query Me {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(
    MeDocument,
    options
  )
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MeQuery,
    Types.MeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(
    MeDocument,
    options
  )
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<
  Types.MeQuery,
  Types.MeQueryVariables
>
export const MeEditDocument = gql`
  mutation MeEdit(
    $email: String
    $name: String
    $surname: String
    $phoneNumber: String
    $newPassword: String
    $oldPassword: String
  ) {
    meEdit(
      input: {
        email: $email
        name: $name
        surname: $surname
        phoneNumber: $phoneNumber
        newPassword: $newPassword
        oldPassword: $oldPassword
      }
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type MeEditMutationFn = Apollo.MutationFunction<
  Types.MeEditMutation,
  Types.MeEditMutationVariables
>

/**
 * __useMeEditMutation__
 *
 * To run a mutation, you first call `useMeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meEditMutation, { data, loading, error }] = useMeEditMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      phoneNumber: // value for 'phoneNumber'
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useMeEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MeEditMutation,
    Types.MeEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.MeEditMutation,
    Types.MeEditMutationVariables
  >(MeEditDocument, options)
}
export type MeEditMutationHookResult = ReturnType<typeof useMeEditMutation>
export type MeEditMutationResult = Apollo.MutationResult<Types.MeEditMutation>
export type MeEditMutationOptions = Apollo.BaseMutationOptions<
  Types.MeEditMutation,
  Types.MeEditMutationVariables
>
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    userSignIn(email: $email, password: $password) {
      id
      name
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<
  Types.LoginMutation,
  Types.LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoginMutation,
    Types.LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(
    LoginDocument,
    options
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginMutation,
  Types.LoginMutationVariables
>
export const LogoutDocument = gql`
  mutation Logout {
    userSignOut {
      id
    }
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<
  Types.LogoutMutation,
  Types.LogoutMutationVariables
>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LogoutMutation,
    Types.LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.LogoutMutation,
    Types.LogoutMutationVariables
  >(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<Types.LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  Types.LogoutMutation,
  Types.LogoutMutationVariables
>
export const BrandsDocument = gql`
  query Brands($page: Int!, $pageSize: Int!) {
    brands(
      pagination: { page: $page, pageSize: $pageSize }
      sort: { direction: ASC, field: NAME }
    ) {
      entries {
        ...Brand
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
  ${BrandFragmentDoc}
`

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.BrandsQuery,
    Types.BrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BrandsQuery, Types.BrandsQueryVariables>(
    BrandsDocument,
    options
  )
}
export function useBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BrandsQuery,
    Types.BrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BrandsQuery, Types.BrandsQueryVariables>(
    BrandsDocument,
    options
  )
}
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>
export type BrandsQueryResult = Apollo.QueryResult<
  Types.BrandsQuery,
  Types.BrandsQueryVariables
>
export const BrandsAllDocument = gql`
  query BrandsAll($search: String) {
    brandsAll(search: $search, sort: { direction: ASC, field: NAME }) {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`

/**
 * __useBrandsAllQuery__
 *
 * To run a query within a React component, call `useBrandsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsAllQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBrandsAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.BrandsAllQuery,
    Types.BrandsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BrandsAllQuery, Types.BrandsAllQueryVariables>(
    BrandsAllDocument,
    options
  )
}
export function useBrandsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BrandsAllQuery,
    Types.BrandsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.BrandsAllQuery,
    Types.BrandsAllQueryVariables
  >(BrandsAllDocument, options)
}
export type BrandsAllQueryHookResult = ReturnType<typeof useBrandsAllQuery>
export type BrandsAllLazyQueryHookResult = ReturnType<
  typeof useBrandsAllLazyQuery
>
export type BrandsAllQueryResult = Apollo.QueryResult<
  Types.BrandsAllQuery,
  Types.BrandsAllQueryVariables
>
export const BrandDeleteDocument = gql`
  mutation BrandDelete($id: ID!) {
    brandDelete(id: $id) {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`
export type BrandDeleteMutationFn = Apollo.MutationFunction<
  Types.BrandDeleteMutation,
  Types.BrandDeleteMutationVariables
>

/**
 * __useBrandDeleteMutation__
 *
 * To run a mutation, you first call `useBrandDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandDeleteMutation, { data, loading, error }] = useBrandDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BrandDeleteMutation,
    Types.BrandDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.BrandDeleteMutation,
    Types.BrandDeleteMutationVariables
  >(BrandDeleteDocument, options)
}
export type BrandDeleteMutationHookResult = ReturnType<
  typeof useBrandDeleteMutation
>
export type BrandDeleteMutationResult =
  Apollo.MutationResult<Types.BrandDeleteMutation>
export type BrandDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.BrandDeleteMutation,
  Types.BrandDeleteMutationVariables
>
export const BrandCreateDocument = gql`
  mutation BrandCreate(
    $description: String!
    $name: String!
    $trending: Boolean!
    $trendingImageUrl: String
    $isFeatured: Boolean!
    $highlighted: Boolean!
    $highlightedImageUrl: String
  ) {
    brandCreate(
      input: {
        description: $description
        name: $name
        trending: $trending
        trendingImageUrl: $trendingImageUrl
        isFeatured: $isFeatured
        highlighted: $highlighted
        highlightedImageUrl: $highlightedImageUrl
      }
    ) {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`
export type BrandCreateMutationFn = Apollo.MutationFunction<
  Types.BrandCreateMutation,
  Types.BrandCreateMutationVariables
>

/**
 * __useBrandCreateMutation__
 *
 * To run a mutation, you first call `useBrandCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandCreateMutation, { data, loading, error }] = useBrandCreateMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      trending: // value for 'trending'
 *      trendingImageUrl: // value for 'trendingImageUrl'
 *      isFeatured: // value for 'isFeatured'
 *      highlighted: // value for 'highlighted'
 *      highlightedImageUrl: // value for 'highlightedImageUrl'
 *   },
 * });
 */
export function useBrandCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BrandCreateMutation,
    Types.BrandCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.BrandCreateMutation,
    Types.BrandCreateMutationVariables
  >(BrandCreateDocument, options)
}
export type BrandCreateMutationHookResult = ReturnType<
  typeof useBrandCreateMutation
>
export type BrandCreateMutationResult =
  Apollo.MutationResult<Types.BrandCreateMutation>
export type BrandCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.BrandCreateMutation,
  Types.BrandCreateMutationVariables
>
export const BrandEditDocument = gql`
  mutation BrandEdit(
    $id: ID!
    $description: String!
    $name: String!
    $trending: Boolean!
    $trendingImageUrl: String
    $isFeatured: Boolean!
    $highlighted: Boolean!
    $highlightedImageUrl: String
  ) {
    brandEdit(
      id: $id
      input: {
        description: $description
        name: $name
        trending: $trending
        trendingImageUrl: $trendingImageUrl
        isFeatured: $isFeatured
        highlighted: $highlighted
        highlightedImageUrl: $highlightedImageUrl
      }
    ) {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`
export type BrandEditMutationFn = Apollo.MutationFunction<
  Types.BrandEditMutation,
  Types.BrandEditMutationVariables
>

/**
 * __useBrandEditMutation__
 *
 * To run a mutation, you first call `useBrandEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandEditMutation, { data, loading, error }] = useBrandEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      trending: // value for 'trending'
 *      trendingImageUrl: // value for 'trendingImageUrl'
 *      isFeatured: // value for 'isFeatured'
 *      highlighted: // value for 'highlighted'
 *      highlightedImageUrl: // value for 'highlightedImageUrl'
 *   },
 * });
 */
export function useBrandEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BrandEditMutation,
    Types.BrandEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.BrandEditMutation,
    Types.BrandEditMutationVariables
  >(BrandEditDocument, options)
}
export type BrandEditMutationHookResult = ReturnType<
  typeof useBrandEditMutation
>
export type BrandEditMutationResult =
  Apollo.MutationResult<Types.BrandEditMutation>
export type BrandEditMutationOptions = Apollo.BaseMutationOptions<
  Types.BrandEditMutation,
  Types.BrandEditMutationVariables
>
export const CategoriesDocument = gql`
  query Categories($page: Int!, $pageSize: Int!) {
    categories(pagination: { page: $page, pageSize: $pageSize }) {
      entries {
        ...Category
        subCategories {
          ...Category
        }
      }
      totalEntries
      totalPages
    }
  }
  ${CategoryFragmentDoc}
`

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CategoriesQuery,
    Types.CategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(
    CategoriesDocument,
    options
  )
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CategoriesQuery,
    Types.CategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CategoriesQuery,
    Types.CategoriesQueryVariables
  >(CategoriesDocument, options)
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>
export type CategoriesLazyQueryHookResult = ReturnType<
  typeof useCategoriesLazyQuery
>
export type CategoriesQueryResult = Apollo.QueryResult<
  Types.CategoriesQuery,
  Types.CategoriesQueryVariables
>
export const CategoriesAllDocument = gql`
  query CategoriesAll($search: String) {
    categoriesAll(search: $search, sort: { direction: ASC, field: NAME }) {
      ...Category
      subCategories {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`

/**
 * __useCategoriesAllQuery__
 *
 * To run a query within a React component, call `useCategoriesAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesAllQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCategoriesAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CategoriesAllQuery,
    Types.CategoriesAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CategoriesAllQuery,
    Types.CategoriesAllQueryVariables
  >(CategoriesAllDocument, options)
}
export function useCategoriesAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CategoriesAllQuery,
    Types.CategoriesAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CategoriesAllQuery,
    Types.CategoriesAllQueryVariables
  >(CategoriesAllDocument, options)
}
export type CategoriesAllQueryHookResult = ReturnType<
  typeof useCategoriesAllQuery
>
export type CategoriesAllLazyQueryHookResult = ReturnType<
  typeof useCategoriesAllLazyQuery
>
export type CategoriesAllQueryResult = Apollo.QueryResult<
  Types.CategoriesAllQuery,
  Types.CategoriesAllQueryVariables
>
export const CategoryDocument = gql`
  query Category($id: ID!) {
    category(id: $id) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CategoryQuery,
    Types.CategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CategoryQuery, Types.CategoryQueryVariables>(
    CategoryDocument,
    options
  )
}
export function useCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CategoryQuery,
    Types.CategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CategoryQuery, Types.CategoryQueryVariables>(
    CategoryDocument,
    options
  )
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>
export type CategoryLazyQueryHookResult = ReturnType<
  typeof useCategoryLazyQuery
>
export type CategoryQueryResult = Apollo.QueryResult<
  Types.CategoryQuery,
  Types.CategoryQueryVariables
>
export const DeleteCategoryDocument = gql`
  mutation DeleteCategory($id: ID!) {
    categoryDelete(id: $id) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`
export type DeleteCategoryMutationFn = Apollo.MutationFunction<
  Types.DeleteCategoryMutation,
  Types.DeleteCategoryMutationVariables
>

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCategoryMutation,
    Types.DeleteCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteCategoryMutation,
    Types.DeleteCategoryMutationVariables
  >(DeleteCategoryDocument, options)
}
export type DeleteCategoryMutationHookResult = ReturnType<
  typeof useDeleteCategoryMutation
>
export type DeleteCategoryMutationResult =
  Apollo.MutationResult<Types.DeleteCategoryMutation>
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCategoryMutation,
  Types.DeleteCategoryMutationVariables
>
export const CategoryCreateDocument = gql`
  mutation CategoryCreate(
    $categoryId: ID
    $name: String!
    $description: String!
  ) {
    categoryCreate(
      input: { categoryId: $categoryId, name: $name, description: $description }
    ) {
      ...Category
      subCategories {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`
export type CategoryCreateMutationFn = Apollo.MutationFunction<
  Types.CategoryCreateMutation,
  Types.CategoryCreateMutationVariables
>

/**
 * __useCategoryCreateMutation__
 *
 * To run a mutation, you first call `useCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryCreateMutation, { data, loading, error }] = useCategoryCreateMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CategoryCreateMutation,
    Types.CategoryCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CategoryCreateMutation,
    Types.CategoryCreateMutationVariables
  >(CategoryCreateDocument, options)
}
export type CategoryCreateMutationHookResult = ReturnType<
  typeof useCategoryCreateMutation
>
export type CategoryCreateMutationResult =
  Apollo.MutationResult<Types.CategoryCreateMutation>
export type CategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.CategoryCreateMutation,
  Types.CategoryCreateMutationVariables
>
export const CategoryEditDocument = gql`
  mutation CategoryEdit(
    $id: ID!
    $categoryId: ID
    $name: String!
    $description: String!
  ) {
    categoryEdit(
      id: $id
      input: { categoryId: $categoryId, name: $name, description: $description }
    ) {
      ...Category
      subCategories {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`
export type CategoryEditMutationFn = Apollo.MutationFunction<
  Types.CategoryEditMutation,
  Types.CategoryEditMutationVariables
>

/**
 * __useCategoryEditMutation__
 *
 * To run a mutation, you first call `useCategoryEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryEditMutation, { data, loading, error }] = useCategoryEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCategoryEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CategoryEditMutation,
    Types.CategoryEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CategoryEditMutation,
    Types.CategoryEditMutationVariables
  >(CategoryEditDocument, options)
}
export type CategoryEditMutationHookResult = ReturnType<
  typeof useCategoryEditMutation
>
export type CategoryEditMutationResult =
  Apollo.MutationResult<Types.CategoryEditMutation>
export type CategoryEditMutationOptions = Apollo.BaseMutationOptions<
  Types.CategoryEditMutation,
  Types.CategoryEditMutationVariables
>
export const CategoryEditFeaturedBrandsDocument = gql`
  mutation CategoryEditFeaturedBrands($id: ID!, $brandIds: [ID!]!) {
    categoryEditFeaturedBrands(id: $id, brandIds: $brandIds) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`
export type CategoryEditFeaturedBrandsMutationFn = Apollo.MutationFunction<
  Types.CategoryEditFeaturedBrandsMutation,
  Types.CategoryEditFeaturedBrandsMutationVariables
>

/**
 * __useCategoryEditFeaturedBrandsMutation__
 *
 * To run a mutation, you first call `useCategoryEditFeaturedBrandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditFeaturedBrandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryEditFeaturedBrandsMutation, { data, loading, error }] = useCategoryEditFeaturedBrandsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      brandIds: // value for 'brandIds'
 *   },
 * });
 */
export function useCategoryEditFeaturedBrandsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CategoryEditFeaturedBrandsMutation,
    Types.CategoryEditFeaturedBrandsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CategoryEditFeaturedBrandsMutation,
    Types.CategoryEditFeaturedBrandsMutationVariables
  >(CategoryEditFeaturedBrandsDocument, options)
}
export type CategoryEditFeaturedBrandsMutationHookResult = ReturnType<
  typeof useCategoryEditFeaturedBrandsMutation
>
export type CategoryEditFeaturedBrandsMutationResult =
  Apollo.MutationResult<Types.CategoryEditFeaturedBrandsMutation>
export type CategoryEditFeaturedBrandsMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CategoryEditFeaturedBrandsMutation,
    Types.CategoryEditFeaturedBrandsMutationVariables
  >
export const MostPopularProductsDocument = gql`
  query MostPopularProducts($limit: Int!) {
    productsTop(limit: $limit) {
      ...DashboardInfo
    }
  }
  ${DashboardInfoFragmentDoc}
`

/**
 * __useMostPopularProductsQuery__
 *
 * To run a query within a React component, call `useMostPopularProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostPopularProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostPopularProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMostPopularProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MostPopularProductsQuery,
    Types.MostPopularProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MostPopularProductsQuery,
    Types.MostPopularProductsQueryVariables
  >(MostPopularProductsDocument, options)
}
export function useMostPopularProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MostPopularProductsQuery,
    Types.MostPopularProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MostPopularProductsQuery,
    Types.MostPopularProductsQueryVariables
  >(MostPopularProductsDocument, options)
}
export type MostPopularProductsQueryHookResult = ReturnType<
  typeof useMostPopularProductsQuery
>
export type MostPopularProductsLazyQueryHookResult = ReturnType<
  typeof useMostPopularProductsLazyQuery
>
export type MostPopularProductsQueryResult = Apollo.QueryResult<
  Types.MostPopularProductsQuery,
  Types.MostPopularProductsQueryVariables
>
export const MostPopularProductsByCategoryDocument = gql`
  query MostPopularProductsByCategory($limit: Int!) {
    productsTopByCategory(limit: $limit) {
      ...DashboardInfo
    }
  }
  ${DashboardInfoFragmentDoc}
`

/**
 * __useMostPopularProductsByCategoryQuery__
 *
 * To run a query within a React component, call `useMostPopularProductsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostPopularProductsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostPopularProductsByCategoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMostPopularProductsByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MostPopularProductsByCategoryQuery,
    Types.MostPopularProductsByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MostPopularProductsByCategoryQuery,
    Types.MostPopularProductsByCategoryQueryVariables
  >(MostPopularProductsByCategoryDocument, options)
}
export function useMostPopularProductsByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MostPopularProductsByCategoryQuery,
    Types.MostPopularProductsByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MostPopularProductsByCategoryQuery,
    Types.MostPopularProductsByCategoryQueryVariables
  >(MostPopularProductsByCategoryDocument, options)
}
export type MostPopularProductsByCategoryQueryHookResult = ReturnType<
  typeof useMostPopularProductsByCategoryQuery
>
export type MostPopularProductsByCategoryLazyQueryHookResult = ReturnType<
  typeof useMostPopularProductsByCategoryLazyQuery
>
export type MostPopularProductsByCategoryQueryResult = Apollo.QueryResult<
  Types.MostPopularProductsByCategoryQuery,
  Types.MostPopularProductsByCategoryQueryVariables
>
export const MostPopularCategoriesDocument = gql`
  query MostPopularCategories($limit: Int!) {
    categoriesTop(limit: $limit) {
      clicks
      category {
        id
        name
      }
    }
  }
`

/**
 * __useMostPopularCategoriesQuery__
 *
 * To run a query within a React component, call `useMostPopularCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostPopularCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostPopularCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMostPopularCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MostPopularCategoriesQuery,
    Types.MostPopularCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MostPopularCategoriesQuery,
    Types.MostPopularCategoriesQueryVariables
  >(MostPopularCategoriesDocument, options)
}
export function useMostPopularCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MostPopularCategoriesQuery,
    Types.MostPopularCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MostPopularCategoriesQuery,
    Types.MostPopularCategoriesQueryVariables
  >(MostPopularCategoriesDocument, options)
}
export type MostPopularCategoriesQueryHookResult = ReturnType<
  typeof useMostPopularCategoriesQuery
>
export type MostPopularCategoriesLazyQueryHookResult = ReturnType<
  typeof useMostPopularCategoriesLazyQuery
>
export type MostPopularCategoriesQueryResult = Apollo.QueryResult<
  Types.MostPopularCategoriesQuery,
  Types.MostPopularCategoriesQueryVariables
>
export const FeedsStatusCountDocument = gql`
  query FeedsStatusCount {
    feedsStatusCount {
      status
      count
    }
  }
`

/**
 * __useFeedsStatusCountQuery__
 *
 * To run a query within a React component, call `useFeedsStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsStatusCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedsStatusCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FeedsStatusCountQuery,
    Types.FeedsStatusCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.FeedsStatusCountQuery,
    Types.FeedsStatusCountQueryVariables
  >(FeedsStatusCountDocument, options)
}
export function useFeedsStatusCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FeedsStatusCountQuery,
    Types.FeedsStatusCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.FeedsStatusCountQuery,
    Types.FeedsStatusCountQueryVariables
  >(FeedsStatusCountDocument, options)
}
export type FeedsStatusCountQueryHookResult = ReturnType<
  typeof useFeedsStatusCountQuery
>
export type FeedsStatusCountLazyQueryHookResult = ReturnType<
  typeof useFeedsStatusCountLazyQuery
>
export type FeedsStatusCountQueryResult = Apollo.QueryResult<
  Types.FeedsStatusCountQuery,
  Types.FeedsStatusCountQueryVariables
>
export const TotalProductsDocument = gql`
  query TotalProducts {
    products(pagination: { page: 1, pageSize: 10 }) {
      totalEntries
    }
  }
`

/**
 * __useTotalProductsQuery__
 *
 * To run a query within a React component, call `useTotalProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TotalProductsQuery,
    Types.TotalProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.TotalProductsQuery,
    Types.TotalProductsQueryVariables
  >(TotalProductsDocument, options)
}
export function useTotalProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TotalProductsQuery,
    Types.TotalProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.TotalProductsQuery,
    Types.TotalProductsQueryVariables
  >(TotalProductsDocument, options)
}
export type TotalProductsQueryHookResult = ReturnType<
  typeof useTotalProductsQuery
>
export type TotalProductsLazyQueryHookResult = ReturnType<
  typeof useTotalProductsLazyQuery
>
export type TotalProductsQueryResult = Apollo.QueryResult<
  Types.TotalProductsQuery,
  Types.TotalProductsQueryVariables
>
export const FeedsDocument = gql`
  query Feeds($page: Int!, $pageSize: Int!, $search: String) {
    feeds(
      pagination: { page: $page, pageSize: $pageSize }
      sort: { direction: ASC, field: MERCHANT }
      search: $search
    ) {
      entries {
        ...Feed
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
  ${FeedFragmentDoc}
`

/**
 * __useFeedsQuery__
 *
 * To run a query within a React component, call `useFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFeedsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FeedsQuery,
    Types.FeedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FeedsQuery, Types.FeedsQueryVariables>(
    FeedsDocument,
    options
  )
}
export function useFeedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FeedsQuery,
    Types.FeedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.FeedsQuery, Types.FeedsQueryVariables>(
    FeedsDocument,
    options
  )
}
export type FeedsQueryHookResult = ReturnType<typeof useFeedsQuery>
export type FeedsLazyQueryHookResult = ReturnType<typeof useFeedsLazyQuery>
export type FeedsQueryResult = Apollo.QueryResult<
  Types.FeedsQuery,
  Types.FeedsQueryVariables
>
export const FeedsAllDocument = gql`
  query FeedsAll {
    feedsAll(sort: { direction: DESC, field: NETWORK }) {
      id
      merchant
      network
    }
  }
`

/**
 * __useFeedsAllQuery__
 *
 * To run a query within a React component, call `useFeedsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedsAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FeedsAllQuery,
    Types.FeedsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FeedsAllQuery, Types.FeedsAllQueryVariables>(
    FeedsAllDocument,
    options
  )
}
export function useFeedsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FeedsAllQuery,
    Types.FeedsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.FeedsAllQuery, Types.FeedsAllQueryVariables>(
    FeedsAllDocument,
    options
  )
}
export type FeedsAllQueryHookResult = ReturnType<typeof useFeedsAllQuery>
export type FeedsAllLazyQueryHookResult = ReturnType<
  typeof useFeedsAllLazyQuery
>
export type FeedsAllQueryResult = Apollo.QueryResult<
  Types.FeedsAllQuery,
  Types.FeedsAllQueryVariables
>
export const FeedDeleteDocument = gql`
  mutation FeedDelete($id: ID!) {
    feedDelete(id: $id) {
      ...Feed
    }
  }
  ${FeedFragmentDoc}
`
export type FeedDeleteMutationFn = Apollo.MutationFunction<
  Types.FeedDeleteMutation,
  Types.FeedDeleteMutationVariables
>

/**
 * __useFeedDeleteMutation__
 *
 * To run a mutation, you first call `useFeedDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedDeleteMutation, { data, loading, error }] = useFeedDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeedDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FeedDeleteMutation,
    Types.FeedDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.FeedDeleteMutation,
    Types.FeedDeleteMutationVariables
  >(FeedDeleteDocument, options)
}
export type FeedDeleteMutationHookResult = ReturnType<
  typeof useFeedDeleteMutation
>
export type FeedDeleteMutationResult =
  Apollo.MutationResult<Types.FeedDeleteMutation>
export type FeedDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.FeedDeleteMutation,
  Types.FeedDeleteMutationVariables
>
export const FeedCreateDocument = gql`
  mutation FeedCreate(
    $network: FeedNetworkValues!
    $merchantId: String!
    $merchant: String!
    $frequency: FeedFrequencyValues!
    $isEnabled: Boolean!
  ) {
    feedCreate(
      input: {
        network: $network
        merchantId: $merchantId
        merchant: $merchant
        frequency: $frequency
        isEnabled: $isEnabled
      }
    ) {
      ...Feed
    }
  }
  ${FeedFragmentDoc}
`
export type FeedCreateMutationFn = Apollo.MutationFunction<
  Types.FeedCreateMutation,
  Types.FeedCreateMutationVariables
>

/**
 * __useFeedCreateMutation__
 *
 * To run a mutation, you first call `useFeedCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedCreateMutation, { data, loading, error }] = useFeedCreateMutation({
 *   variables: {
 *      network: // value for 'network'
 *      merchantId: // value for 'merchantId'
 *      merchant: // value for 'merchant'
 *      frequency: // value for 'frequency'
 *      isEnabled: // value for 'isEnabled'
 *   },
 * });
 */
export function useFeedCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FeedCreateMutation,
    Types.FeedCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.FeedCreateMutation,
    Types.FeedCreateMutationVariables
  >(FeedCreateDocument, options)
}
export type FeedCreateMutationHookResult = ReturnType<
  typeof useFeedCreateMutation
>
export type FeedCreateMutationResult =
  Apollo.MutationResult<Types.FeedCreateMutation>
export type FeedCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.FeedCreateMutation,
  Types.FeedCreateMutationVariables
>
export const FeedEditDocument = gql`
  mutation FeedEdit(
    $id: ID!
    $network: FeedNetworkValues!
    $merchantId: String!
    $merchant: String!
    $frequency: FeedFrequencyValues!
    $isEnabled: Boolean!
  ) {
    feedEdit(
      id: $id
      input: {
        network: $network
        merchantId: $merchantId
        merchant: $merchant
        frequency: $frequency
        isEnabled: $isEnabled
      }
    ) {
      ...Feed
    }
  }
  ${FeedFragmentDoc}
`
export type FeedEditMutationFn = Apollo.MutationFunction<
  Types.FeedEditMutation,
  Types.FeedEditMutationVariables
>

/**
 * __useFeedEditMutation__
 *
 * To run a mutation, you first call `useFeedEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedEditMutation, { data, loading, error }] = useFeedEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      network: // value for 'network'
 *      merchantId: // value for 'merchantId'
 *      merchant: // value for 'merchant'
 *      frequency: // value for 'frequency'
 *      isEnabled: // value for 'isEnabled'
 *   },
 * });
 */
export function useFeedEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FeedEditMutation,
    Types.FeedEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.FeedEditMutation,
    Types.FeedEditMutationVariables
  >(FeedEditDocument, options)
}
export type FeedEditMutationHookResult = ReturnType<typeof useFeedEditMutation>
export type FeedEditMutationResult =
  Apollo.MutationResult<Types.FeedEditMutation>
export type FeedEditMutationOptions = Apollo.BaseMutationOptions<
  Types.FeedEditMutation,
  Types.FeedEditMutationVariables
>
export const FeedScrapeDocument = gql`
  mutation FeedScrape($id: ID!) {
    feedScrape(id: $id) {
      ...Feed
    }
  }
  ${FeedFragmentDoc}
`
export type FeedScrapeMutationFn = Apollo.MutationFunction<
  Types.FeedScrapeMutation,
  Types.FeedScrapeMutationVariables
>

/**
 * __useFeedScrapeMutation__
 *
 * To run a mutation, you first call `useFeedScrapeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedScrapeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedScrapeMutation, { data, loading, error }] = useFeedScrapeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeedScrapeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FeedScrapeMutation,
    Types.FeedScrapeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.FeedScrapeMutation,
    Types.FeedScrapeMutationVariables
  >(FeedScrapeDocument, options)
}
export type FeedScrapeMutationHookResult = ReturnType<
  typeof useFeedScrapeMutation
>
export type FeedScrapeMutationResult =
  Apollo.MutationResult<Types.FeedScrapeMutation>
export type FeedScrapeMutationOptions = Apollo.BaseMutationOptions<
  Types.FeedScrapeMutation,
  Types.FeedScrapeMutationVariables
>
export const MatchResetDocument = gql`
  mutation MatchReset($brand: String) {
    matchReset(brand: $brand)
  }
`
export type MatchResetMutationFn = Apollo.MutationFunction<
  Types.MatchResetMutation,
  Types.MatchResetMutationVariables
>

/**
 * __useMatchResetMutation__
 *
 * To run a mutation, you first call `useMatchResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchResetMutation, { data, loading, error }] = useMatchResetMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useMatchResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MatchResetMutation,
    Types.MatchResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.MatchResetMutation,
    Types.MatchResetMutationVariables
  >(MatchResetDocument, options)
}
export type MatchResetMutationHookResult = ReturnType<
  typeof useMatchResetMutation
>
export type MatchResetMutationResult =
  Apollo.MutationResult<Types.MatchResetMutation>
export type MatchResetMutationOptions = Apollo.BaseMutationOptions<
  Types.MatchResetMutation,
  Types.MatchResetMutationVariables
>
export const MatchsAllDocument = gql`
  query MatchsAll {
    matchsAll {
      ...Match
    }
  }
  ${MatchFragmentDoc}
`

/**
 * __useMatchsAllQuery__
 *
 * To run a query within a React component, call `useMatchsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatchsAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MatchsAllQuery,
    Types.MatchsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MatchsAllQuery, Types.MatchsAllQueryVariables>(
    MatchsAllDocument,
    options
  )
}
export function useMatchsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MatchsAllQuery,
    Types.MatchsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MatchsAllQuery,
    Types.MatchsAllQueryVariables
  >(MatchsAllDocument, options)
}
export type MatchsAllQueryHookResult = ReturnType<typeof useMatchsAllQuery>
export type MatchsAllLazyQueryHookResult = ReturnType<
  typeof useMatchsAllLazyQuery
>
export type MatchsAllQueryResult = Apollo.QueryResult<
  Types.MatchsAllQuery,
  Types.MatchsAllQueryVariables
>
export const ProductsDocument = gql`
  query Products($page: Int!, $pageSize: Int!, $search: String) {
    products(
      pagination: { page: $page, pageSize: $pageSize }
      search: $search
      sort: { direction: ASC, field: NAME }
    ) {
      entries {
        ...Product
        sources {
          feedId
        }
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ProductsQuery,
    Types.ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProductsQuery,
    Types.ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>
export type ProductsQueryResult = Apollo.QueryResult<
  Types.ProductsQuery,
  Types.ProductsQueryVariables
>
export const SearchAmongProductsDocument = gql`
  query SearchAmongProducts($search: String) {
    productsAll(search: $search, sort: { direction: ASC, field: NAME }) {
      ...MatchingListItem
    }
  }
  ${MatchingListItemFragmentDoc}
`

/**
 * __useSearchAmongProductsQuery__
 *
 * To run a query within a React component, call `useSearchAmongProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAmongProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAmongProductsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchAmongProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchAmongProductsQuery,
    Types.SearchAmongProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.SearchAmongProductsQuery,
    Types.SearchAmongProductsQueryVariables
  >(SearchAmongProductsDocument, options)
}
export function useSearchAmongProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchAmongProductsQuery,
    Types.SearchAmongProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.SearchAmongProductsQuery,
    Types.SearchAmongProductsQueryVariables
  >(SearchAmongProductsDocument, options)
}
export type SearchAmongProductsQueryHookResult = ReturnType<
  typeof useSearchAmongProductsQuery
>
export type SearchAmongProductsLazyQueryHookResult = ReturnType<
  typeof useSearchAmongProductsLazyQuery
>
export type SearchAmongProductsQueryResult = Apollo.QueryResult<
  Types.SearchAmongProductsQuery,
  Types.SearchAmongProductsQueryVariables
>
export const ProductDocument = gql`
  query Product($id: ID!) {
    product(id: $id) {
      ...Product
      images
      lastArrival
      sizes {
        standard
        type
        value
      }
      description
      gender
      color
      condition
      sources {
        feedId
        ...Source
        ...SourceUrls
        ...SourceIdentifiers
      }
      locationId
    }
  }
  ${ProductFragmentDoc}
  ${SourceFragmentDoc}
  ${SourceUrlsFragmentDoc}
  ${SourceIdentifiersFragmentDoc}
`

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ProductQuery,
    Types.ProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductQuery, Types.ProductQueryVariables>(
    ProductDocument,
    options
  )
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProductQuery,
    Types.ProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductQuery, Types.ProductQueryVariables>(
    ProductDocument,
    options
  )
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>
export type ProductQueryResult = Apollo.QueryResult<
  Types.ProductQuery,
  Types.ProductQueryVariables
>
export const ProductDeleteDocument = gql`
  mutation ProductDelete($id: ID!) {
    productDelete(id: $id) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`
export type ProductDeleteMutationFn = Apollo.MutationFunction<
  Types.ProductDeleteMutation,
  Types.ProductDeleteMutationVariables
>

/**
 * __useProductDeleteMutation__
 *
 * To run a mutation, you first call `useProductDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productDeleteMutation, { data, loading, error }] = useProductDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ProductDeleteMutation,
    Types.ProductDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ProductDeleteMutation,
    Types.ProductDeleteMutationVariables
  >(ProductDeleteDocument, options)
}
export type ProductDeleteMutationHookResult = ReturnType<
  typeof useProductDeleteMutation
>
export type ProductDeleteMutationResult =
  Apollo.MutationResult<Types.ProductDeleteMutation>
export type ProductDeleteMutationOptions = Apollo.BaseMutationOptions<
  Types.ProductDeleteMutation,
  Types.ProductDeleteMutationVariables
>
export const ProductDeleteAllDocument = gql`
  mutation productDeleteAll($ids: [ID!]!) {
    productDeleteAll(ids: $ids)
  }
`
export type ProductDeleteAllMutationFn = Apollo.MutationFunction<
  Types.ProductDeleteAllMutation,
  Types.ProductDeleteAllMutationVariables
>

/**
 * __useProductDeleteAllMutation__
 *
 * To run a mutation, you first call `useProductDeleteAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductDeleteAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productDeleteAllMutation, { data, loading, error }] = useProductDeleteAllMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProductDeleteAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ProductDeleteAllMutation,
    Types.ProductDeleteAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ProductDeleteAllMutation,
    Types.ProductDeleteAllMutationVariables
  >(ProductDeleteAllDocument, options)
}
export type ProductDeleteAllMutationHookResult = ReturnType<
  typeof useProductDeleteAllMutation
>
export type ProductDeleteAllMutationResult =
  Apollo.MutationResult<Types.ProductDeleteAllMutation>
export type ProductDeleteAllMutationOptions = Apollo.BaseMutationOptions<
  Types.ProductDeleteAllMutation,
  Types.ProductDeleteAllMutationVariables
>
export const ProductCreateDocument = gql`
  mutation ProductCreate(
    $brandId: ID!
    $categoryId: ID!
    $clicks: Int!
    $color: String
    $condition: String
    $description: String!
    $gender: String
    $image: String
    $images: [String!]
    $isVisible: Boolean!
    $lastArrival: Boolean!
    $locationId: ID
    $name: String!
    $standard: String!
    $type: String!
    $value: String!
    $sources: [SourceInput!]
  ) {
    productCreate(
      input: {
        brandId: $brandId
        categoryId: $categoryId
        clicks: $clicks
        color: $color
        condition: $condition
        description: $description
        gender: $gender
        image: $image
        images: $images
        isVisible: $isVisible
        lastArrival: $lastArrival
        locationId: $locationId
        name: $name
        sizes: { type: $type, value: $value, standard: $standard }
        sources: $sources
      }
    ) {
      brandId
      categoryId
      clicks
      color
      condition
      description
      gender
      image
      images
      isVisible
      lastArrival
      locationId
      name
      sizes {
        standard
        type
        value
      }
    }
  }
`
export type ProductCreateMutationFn = Apollo.MutationFunction<
  Types.ProductCreateMutation,
  Types.ProductCreateMutationVariables
>

/**
 * __useProductCreateMutation__
 *
 * To run a mutation, you first call `useProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateMutation, { data, loading, error }] = useProductCreateMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      categoryId: // value for 'categoryId'
 *      clicks: // value for 'clicks'
 *      color: // value for 'color'
 *      condition: // value for 'condition'
 *      description: // value for 'description'
 *      gender: // value for 'gender'
 *      image: // value for 'image'
 *      images: // value for 'images'
 *      isVisible: // value for 'isVisible'
 *      lastArrival: // value for 'lastArrival'
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      standard: // value for 'standard'
 *      type: // value for 'type'
 *      value: // value for 'value'
 *      sources: // value for 'sources'
 *   },
 * });
 */
export function useProductCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ProductCreateMutation,
    Types.ProductCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ProductCreateMutation,
    Types.ProductCreateMutationVariables
  >(ProductCreateDocument, options)
}
export type ProductCreateMutationHookResult = ReturnType<
  typeof useProductCreateMutation
>
export type ProductCreateMutationResult =
  Apollo.MutationResult<Types.ProductCreateMutation>
export type ProductCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.ProductCreateMutation,
  Types.ProductCreateMutationVariables
>
export const ProductEditDocument = gql`
  mutation ProductEdit(
    $id: ID!
    $brandId: ID!
    $categoryId: ID!
    $clicks: Int!
    $color: String
    $condition: String
    $description: String!
    $gender: String
    $image: String
    $images: [String!]
    $isVisible: Boolean!
    $lastArrival: Boolean!
    $locationId: ID
    $name: String!
    $standard: String!
    $type: String!
    $value: String!
    $sources: [SourceInput!]
  ) {
    productEdit(
      id: $id
      input: {
        brandId: $brandId
        categoryId: $categoryId
        clicks: $clicks
        color: $color
        condition: $condition
        description: $description
        gender: $gender
        image: $image
        images: $images
        isVisible: $isVisible
        lastArrival: $lastArrival
        locationId: $locationId
        name: $name
        sizes: { type: $type, value: $value, standard: $standard }
        sources: $sources
      }
    ) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`
export type ProductEditMutationFn = Apollo.MutationFunction<
  Types.ProductEditMutation,
  Types.ProductEditMutationVariables
>

/**
 * __useProductEditMutation__
 *
 * To run a mutation, you first call `useProductEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productEditMutation, { data, loading, error }] = useProductEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      brandId: // value for 'brandId'
 *      categoryId: // value for 'categoryId'
 *      clicks: // value for 'clicks'
 *      color: // value for 'color'
 *      condition: // value for 'condition'
 *      description: // value for 'description'
 *      gender: // value for 'gender'
 *      image: // value for 'image'
 *      images: // value for 'images'
 *      isVisible: // value for 'isVisible'
 *      lastArrival: // value for 'lastArrival'
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      standard: // value for 'standard'
 *      type: // value for 'type'
 *      value: // value for 'value'
 *      sources: // value for 'sources'
 *   },
 * });
 */
export function useProductEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ProductEditMutation,
    Types.ProductEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ProductEditMutation,
    Types.ProductEditMutationVariables
  >(ProductEditDocument, options)
}
export type ProductEditMutationHookResult = ReturnType<
  typeof useProductEditMutation
>
export type ProductEditMutationResult =
  Apollo.MutationResult<Types.ProductEditMutation>
export type ProductEditMutationOptions = Apollo.BaseMutationOptions<
  Types.ProductEditMutation,
  Types.ProductEditMutationVariables
>
export const ProductMatchDocument = gql`
  mutation ProductMatch($productId: ID!, $matchesIds: [ID!]!) {
    productMatch(id: $productId, ids: $matchesIds) {
      id
      name
      brand {
        name
      }
      sources {
        feed {
          merchant
          network
        }
      }
    }
  }
`
export type ProductMatchMutationFn = Apollo.MutationFunction<
  Types.ProductMatchMutation,
  Types.ProductMatchMutationVariables
>

/**
 * __useProductMatchMutation__
 *
 * To run a mutation, you first call `useProductMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productMatchMutation, { data, loading, error }] = useProductMatchMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      matchesIds: // value for 'matchesIds'
 *   },
 * });
 */
export function useProductMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ProductMatchMutation,
    Types.ProductMatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ProductMatchMutation,
    Types.ProductMatchMutationVariables
  >(ProductMatchDocument, options)
}
export type ProductMatchMutationHookResult = ReturnType<
  typeof useProductMatchMutation
>
export type ProductMatchMutationResult =
  Apollo.MutationResult<Types.ProductMatchMutation>
export type ProductMatchMutationOptions = Apollo.BaseMutationOptions<
  Types.ProductMatchMutation,
  Types.ProductMatchMutationVariables
>
export const SourcesDocument = gql`
  query Sources($page: Int!, $pageSize: Int!) {
    sources(pagination: { page: $page, pageSize: $pageSize }) {
      entries {
        id
        ...Source
        ...SourceUrls
        ...SourceIdentifiers
      }
    }
  }
  ${SourceFragmentDoc}
  ${SourceUrlsFragmentDoc}
  ${SourceIdentifiersFragmentDoc}
`

/**
 * __useSourcesQuery__
 *
 * To run a query within a React component, call `useSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SourcesQuery,
    Types.SourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SourcesQuery, Types.SourcesQueryVariables>(
    SourcesDocument,
    options
  )
}
export function useSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SourcesQuery,
    Types.SourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SourcesQuery, Types.SourcesQueryVariables>(
    SourcesDocument,
    options
  )
}
export type SourcesQueryHookResult = ReturnType<typeof useSourcesQuery>
export type SourcesLazyQueryHookResult = ReturnType<typeof useSourcesLazyQuery>
export type SourcesQueryResult = Apollo.QueryResult<
  Types.SourcesQuery,
  Types.SourcesQueryVariables
>
export const SourceDocument = gql`
  query Source($id: ID!) {
    source(id: $id) {
      id
      ...Source
      ...SourceUrls
      ...SourceIdentifiers
    }
  }
  ${SourceFragmentDoc}
  ${SourceUrlsFragmentDoc}
  ${SourceIdentifiersFragmentDoc}
`

/**
 * __useSourceQuery__
 *
 * To run a query within a React component, call `useSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SourceQuery,
    Types.SourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SourceQuery, Types.SourceQueryVariables>(
    SourceDocument,
    options
  )
}
export function useSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SourceQuery,
    Types.SourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SourceQuery, Types.SourceQueryVariables>(
    SourceDocument,
    options
  )
}
export type SourceQueryHookResult = ReturnType<typeof useSourceQuery>
export type SourceLazyQueryHookResult = ReturnType<typeof useSourceLazyQuery>
export type SourceQueryResult = Apollo.QueryResult<
  Types.SourceQuery,
  Types.SourceQueryVariables
>
export const SourceCreateDocument = gql`
  mutation SourceCreate(
    $externalId: Int!
    $feedId: ID!
    $currency: String
    $price: Int
    $onSale: Boolean
    $saleDiscount: Int
    $shippingCost: Int
    $shippingCostCurrency: String
    $url: String
    $directUrl: String
    $refUrl: String
    $inStock: Int
    $barcode: String
    $mpn: String
    $upc: String
    $sku: String
  ) {
    sourceCreate(
      input: {
        externalId: $externalId
        feedId: $feedId
        currency: $currency
        price: $price
        onSale: $onSale
        saleDiscount: $saleDiscount
        shippingCost: $shippingCost
        shippingCostCurrency: $shippingCostCurrency
        url: $url
        directUrl: $directUrl
        refUrl: $refUrl
        inStock: $inStock
        barcode: $barcode
        mpn: $mpn
        upc: $upc
        sku: $sku
      }
    ) {
      id
      ...Source
      ...SourceUrls
      ...SourceIdentifiers
    }
  }
  ${SourceFragmentDoc}
  ${SourceUrlsFragmentDoc}
  ${SourceIdentifiersFragmentDoc}
`
export type SourceCreateMutationFn = Apollo.MutationFunction<
  Types.SourceCreateMutation,
  Types.SourceCreateMutationVariables
>

/**
 * __useSourceCreateMutation__
 *
 * To run a mutation, you first call `useSourceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceCreateMutation, { data, loading, error }] = useSourceCreateMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      feedId: // value for 'feedId'
 *      currency: // value for 'currency'
 *      price: // value for 'price'
 *      onSale: // value for 'onSale'
 *      saleDiscount: // value for 'saleDiscount'
 *      shippingCost: // value for 'shippingCost'
 *      shippingCostCurrency: // value for 'shippingCostCurrency'
 *      url: // value for 'url'
 *      directUrl: // value for 'directUrl'
 *      refUrl: // value for 'refUrl'
 *      inStock: // value for 'inStock'
 *      barcode: // value for 'barcode'
 *      mpn: // value for 'mpn'
 *      upc: // value for 'upc'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useSourceCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SourceCreateMutation,
    Types.SourceCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.SourceCreateMutation,
    Types.SourceCreateMutationVariables
  >(SourceCreateDocument, options)
}
export type SourceCreateMutationHookResult = ReturnType<
  typeof useSourceCreateMutation
>
export type SourceCreateMutationResult =
  Apollo.MutationResult<Types.SourceCreateMutation>
export type SourceCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.SourceCreateMutation,
  Types.SourceCreateMutationVariables
>
export const SourceEditDocument = gql`
  mutation SourceEdit(
    $id: ID!
    $externalId: Int!
    $feedId: ID!
    $currency: String
    $price: Int
    $onSale: Boolean
    $saleDiscount: Int
    $shippingCost: Int
    $shippingCostCurrency: String
    $url: String
    $directUrl: String
    $refUrl: String
    $inStock: Int
    $barcode: String
    $mpn: String
    $upc: String
    $sku: String
  ) {
    sourceEdit(
      id: $id
      input: {
        externalId: $externalId
        feedId: $feedId
        currency: $currency
        price: $price
        onSale: $onSale
        saleDiscount: $saleDiscount
        shippingCost: $shippingCost
        shippingCostCurrency: $shippingCostCurrency
        url: $url
        directUrl: $directUrl
        refUrl: $refUrl
        inStock: $inStock
        barcode: $barcode
        mpn: $mpn
        upc: $upc
        sku: $sku
      }
    ) {
      id
      ...Source
      ...SourceUrls
      ...SourceIdentifiers
    }
  }
  ${SourceFragmentDoc}
  ${SourceUrlsFragmentDoc}
  ${SourceIdentifiersFragmentDoc}
`
export type SourceEditMutationFn = Apollo.MutationFunction<
  Types.SourceEditMutation,
  Types.SourceEditMutationVariables
>

/**
 * __useSourceEditMutation__
 *
 * To run a mutation, you first call `useSourceEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceEditMutation, { data, loading, error }] = useSourceEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      externalId: // value for 'externalId'
 *      feedId: // value for 'feedId'
 *      currency: // value for 'currency'
 *      price: // value for 'price'
 *      onSale: // value for 'onSale'
 *      saleDiscount: // value for 'saleDiscount'
 *      shippingCost: // value for 'shippingCost'
 *      shippingCostCurrency: // value for 'shippingCostCurrency'
 *      url: // value for 'url'
 *      directUrl: // value for 'directUrl'
 *      refUrl: // value for 'refUrl'
 *      inStock: // value for 'inStock'
 *      barcode: // value for 'barcode'
 *      mpn: // value for 'mpn'
 *      upc: // value for 'upc'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useSourceEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SourceEditMutation,
    Types.SourceEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.SourceEditMutation,
    Types.SourceEditMutationVariables
  >(SourceEditDocument, options)
}
export type SourceEditMutationHookResult = ReturnType<
  typeof useSourceEditMutation
>
export type SourceEditMutationResult =
  Apollo.MutationResult<Types.SourceEditMutation>
export type SourceEditMutationOptions = Apollo.BaseMutationOptions<
  Types.SourceEditMutation,
  Types.SourceEditMutationVariables
>
export const CurrenciesDocument = gql`
  query Currencies {
    sourceCurrencies
  }
`

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CurrenciesQuery,
    Types.CurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(
    CurrenciesDocument,
    options
  )
}
export function useCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CurrenciesQuery,
    Types.CurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CurrenciesQuery,
    Types.CurrenciesQueryVariables
  >(CurrenciesDocument, options)
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>
export type CurrenciesLazyQueryHookResult = ReturnType<
  typeof useCurrenciesLazyQuery
>
export type CurrenciesQueryResult = Apollo.QueryResult<
  Types.CurrenciesQuery,
  Types.CurrenciesQueryVariables
>
