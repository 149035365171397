import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useLoginMutation } from '../../queries/autogenerate/hooks'
import {
  LoginMutation,
  LoginMutationVariables,
} from '../../queries/autogenerate/operations'

import {
  emailValidator,
  stringRequiredValidator,
} from '../../helpers/FormValidators'
import Auth from '../../services/Auth'
import { showErrorToast, showSuccessToast } from '../../services/Toaster'

import LogoHorizontal from '../../components/Logos/LogoHorizontal'
import Input from '../../components/Input'
import Button, { ButtonSize } from '../../components/Button'

import css from './login.module.css'

const Login: React.FC<{}> = () => {
  const initialValues: LoginMutationVariables = {
    email: '',
    password: '',
  }

  const { t } = useTranslation(['login', 'form', 'general'])

  const history = useHistory()

  const [loginMutation, { loading }] = useLoginMutation({
    onCompleted: (data: LoginMutation) => {
      showSuccessToast(t('welcome-message', { name: data.userSignIn?.name }))

      Auth.login()

      history.push('/')
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const handleSubmit = (values: LoginMutationVariables) => {
    loginMutation({ variables: values })
  }

  const SigninSchema = Yup.object().shape({
    email: emailValidator(t),
    password: stringRequiredValidator(t),
  })

  return (
    <div className={css.host}>
      <div className={css.welcome}>
        <LogoHorizontal mode={'dark'} />
        <p className={css.title}>{t('title')}</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={SigninSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <div className={css.input}>
              <Input
                type="text"
                name="email"
                value={values.email}
                errorMessage={
                  touched.email && errors.email ? errors.email : undefined
                }
                label={t('form:email-input-label')}
                onChange={handleChange}
                isBig
              />
            </div>

            <div className={css.input}>
              <Input
                type="password"
                name="password"
                value={values.password}
                errorMessage={
                  touched.password && errors.password
                    ? errors.password
                    : undefined
                }
                label={t('form:password-input-label')}
                onChange={handleChange}
                isBig
              />
            </div>

            <div className={css.button}>
              <Button
                type="submit"
                isLoading={loading}
                label={t('login-button')}
                loadingLabel={t('general:loading')}
                size={ButtonSize.Large}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Login
