import React, { FC } from 'react'
import css from './avatar.module.css'

interface Props {
  name: string
  isBig?: Boolean
  avatarUrl?: string
}

const Avatar: FC<Props> = ({ name, avatarUrl, isBig }) => {
  let nameFirstChar = name.charAt(0)

  return avatarUrl ? (
    <img src={avatarUrl} alt="avatar" />
  ) : (
    <>
      <div className={`${css.host} ${isBig ? css.hostBig : css.hostSmall}`}>
        <span
          className={`${css.letters} ${
            isBig ? css.lettersBig : css.lettersSmall
          }`}
        >
          {nameFirstChar}
        </span>
      </div>
    </>
  )
}

Avatar.defaultProps = {}

export default Avatar
