import { FC } from 'react'

interface Props {}

const CloseIcon: FC<Props> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 18L6 6" stroke="hsl(0, 0%, 20%)" />
      <path d="M18 6L6 18" stroke="hsl(0, 0%, 20%)" />
    </svg>
  )
}

CloseIcon.defaultProps = {}

export default CloseIcon
