import React, { FC } from 'react'
import BulkActions from '../BulkActions'

import Pagination from '../Pagination'

import css from './table.module.css'
interface Props {
  labelBulk?: string
  disabledBulk?: boolean
  onClickBulk?: Function
  optionsBulk?: { title: string; value: any }[]
  placeholderBulk?: string
  nameBulk?: string
  valueBulk?: string
  handleBulk?: (e: React.ChangeEvent<any>) => void
  isPaginated?: boolean
  pageNumber: number
  totalEntries?: number
  entriesPerPage: number
  onPreviousPage?: Function
  onNextPage?: Function
  onFirstPage?: Function
  onLastPage?: Function
  entityName?: string
  isPlainTable?: boolean
  ofLabel?: string
  validateInputValue?: Function
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  inputPageNumber?: string | number
}

const Table: FC<Props> = ({
  labelBulk,
  disabledBulk,
  onClickBulk,
  optionsBulk,
  handleBulk,
  placeholderBulk,
  nameBulk,
  valueBulk,
  isPaginated,
  pageNumber,
  totalEntries,
  entriesPerPage,
  onPreviousPage,
  onFirstPage,
  onNextPage,
  onLastPage,
  entityName,
  ofLabel,
  isPlainTable,
  children,
  validateInputValue,
  handleKeyDown,
  inputPageNumber,
}) => {
  const totalPages = Math.max(Math.ceil(totalEntries! / entriesPerPage), 1)

  const isOnLastPage = pageNumber === totalPages
  const isOnFirstPage = pageNumber === 1

  let sizeClass = css.medium

  return (
    <div className={css.host}>
      <div className={css.actions}>
        {labelBulk && onClickBulk && (
          <BulkActions
            labelBulk={labelBulk}
            disabledBulk={disabledBulk !== undefined && disabledBulk}
            onClickBulk={onClickBulk}
            optionsBulk={optionsBulk}
            placeholderBulk={placeholderBulk}
            nameBulk={nameBulk}
            valueBulk={valueBulk}
            handleBulk={handleBulk}
          />
        )}

        {isPaginated &&
          ofLabel &&
          validateInputValue &&
          handleKeyDown &&
          totalEntries &&
          entityName && (
            <Pagination
              totalEntries={totalEntries}
              entityName={entityName}
              ofLabel={ofLabel}
              isOnFirstPage={isOnFirstPage}
              isOnLastPage={isOnLastPage}
              totalPages={totalPages}
              onPreviousPage={onPreviousPage}
              onFirstPage={onFirstPage}
              onNextPage={onNextPage}
              onLastPage={onLastPage}
              validateInputValue={validateInputValue}
              handleKeyDown={handleKeyDown}
              inputPageNumber={inputPageNumber ? inputPageNumber : ''}
            />
          )}
      </div>
      <div className={css.tableContainer}>
        <table
          className={`${css.table} ${sizeClass} ${
            isPlainTable ? css.isPlainTable : ''
          }`}
        >
          {children}
        </table>
      </div>
      <div className={css.actions}>
        {labelBulk && onClickBulk && (
          <BulkActions
            labelBulk={labelBulk}
            disabledBulk={disabledBulk !== undefined && disabledBulk}
            onClickBulk={onClickBulk}
            optionsBulk={optionsBulk}
            handleBulk={handleBulk}
            placeholderBulk={placeholderBulk}
            nameBulk={nameBulk}
            valueBulk={valueBulk}
          />
        )}

        {isPaginated &&
          ofLabel &&
          validateInputValue &&
          handleKeyDown &&
          totalEntries &&
          entityName && (
            <Pagination
              totalEntries={totalEntries}
              entityName={entityName}
              ofLabel={ofLabel}
              isOnFirstPage={isOnFirstPage}
              isOnLastPage={isOnLastPage}
              totalPages={totalPages}
              onPreviousPage={onPreviousPage}
              onFirstPage={onFirstPage}
              onNextPage={onNextPage}
              onLastPage={onLastPage}
              validateInputValue={validateInputValue}
              handleKeyDown={handleKeyDown}
              inputPageNumber={inputPageNumber ? inputPageNumber : ''}
            />
          )}
      </div>
    </div>
  )
}

Table.defaultProps = {}

export default Table
