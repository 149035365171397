import React, { FC, useRef } from 'react'

import useDetectOutsideClick from '../../hooks/useDetectClickOutside'

import css from './dropdown.module.css'

import ChevronDownSmall from '../Icons/ChevronDownSmall'
import ChevronUpSmall from '../Icons/ChevronUpSmall'

interface Props {
  label: string
  options: { title: string | undefined; value: any }[]
  name: string
  value: any
  isBig?: boolean
  placeholderText: string
  errorMessage?: string
  onChange?: (e: React.ChangeEvent<any>) => void
}

const Dropdown: FC<Props> = ({
  isBig,
  label,
  options,
  placeholderText,
  name,
  value,
  errorMessage,
  onChange,
}) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useDetectOutsideClick(dropdownRef, false)

  let placeholder = placeholderText

  // when we already have a value we need to update the "placeholder" shown
  if (value !== undefined) {
    // we disable the `===` because of the problem of the input converting the value numbers to string
    // eslint-disable-next-line eqeqeq
    const selectedOption = options.find((option) => option.value == value)
    placeholder = selectedOption?.title
      ? selectedOption?.title
      : placeholderText
  }

  const switchOpenState = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={css.host}>
      <span className={css.label}>{label}</span>

      <div className={css.container} onClick={switchOpenState}>
        <div
          className={`${css.input} ${isOpen && css.focus} ${
            isBig && css.isBig
          } ${value !== (undefined || '') && css.filled} ${
            errorMessage && css.error
          }`}
        >
          {placeholder}
        </div>

        <div className={css.icon}>
          {isOpen ? <ChevronUpSmall /> : <ChevronDownSmall />}
        </div>
        <div
          ref={dropdownRef}
          className={`${css.dropdown} ${isOpen && css.isOpen}`}
        >
          {options.map(({ title, value: optionValue }, index) => (
            <div
              key={`${index}-${optionValue}`}
              className={css.option}
              onClick={switchOpenState}
            >
              {/* the actual input used in the form */}
              <input
                type="radio"
                name={name}
                value={optionValue}
                onChange={onChange}
              />

              <p>{title}</p>
            </div>
          ))}
        </div>
      </div>

      {errorMessage && <span className={css.errorMessage}>{errorMessage}</span>}
    </div>
  )
}
Dropdown.defaultProps = {}

export default Dropdown
