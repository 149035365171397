import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  useBrandsQuery,
  useBrandDeleteMutation,
  useBrandCreateMutation,
  useBrandEditMutation,
} from '../../../../../queries/autogenerate/hooks'
import {
  BrandDeleteMutationVariables,
  BrandCreateMutation,
  BrandCreateMutationVariables,
} from '../../../../../queries/autogenerate/operations'

import { requestPageSize } from '../../../../../helpers/Constants'
import {
  stringRequiredValidator,
  booleanValidator,
  imageUrlRequiredWhenActiveBooleanValidator,
} from '../../../../../helpers/FormValidators'

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../services/Toaster'

import Table from '../../../../../components/Table'
import TablePlaceholder from '../../../../../components/TablePlaceholder'
import TableActionLinks from '../../../../../components/TableActionLinks'
import Button, { ButtonSize } from '../../../../../components/Button'
import FormSectionTitle from '../../../../../components/FormSectionTitle'
import Input from '../../../../../components/Input'
import InputMultiline from '../../../../../components/InputMultiline'
import Checkbox from '../../../../../components/Checkbox'
import CenteredModal from '../../../../../components/CenteredModal'

import css from './brands.module.css'

const Brands = () => {
  const { t } = useTranslation(['brands', 'general', 'form', 'table'])

  const [currentPage, setCurrentPage] = useState(1)
  const [inputPageNumber, setInputPageNumber] = useState(currentPage)

  const [isFormVisible, setIsFormVisible] = useState(false)

  const [initialValues, setInitialValues] = useState<
    BrandCreateMutationVariables | undefined
  >(undefined)

  const [idOfBrandToEdit, setIdOfBrandToEdit] = useState<string | undefined>(
    undefined
  )

  const AddBrandSchema = Yup.object().shape({
    description: stringRequiredValidator(t),
    name: stringRequiredValidator(t),
    trending: booleanValidator(t),
    trendingImageUrl: imageUrlRequiredWhenActiveBooleanValidator('trending', t),
    isFeatured: booleanValidator(t),
    highlighted: booleanValidator(t),
    highlightedImageUrl: imageUrlRequiredWhenActiveBooleanValidator(
      'highlighted',
      t
    ),
  })

  const { loading, data, error, refetch } = useBrandsQuery({
    variables: { page: currentPage, pageSize: requestPageSize },
  })

  const [brandCreateMutation] = useBrandCreateMutation({
    onCompleted: (data: BrandCreateMutation) => {
      showSuccessToast(
        t('brands:brand-creation-successful', {
          name: `${data.brandCreate}`,
        })
      )
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [brandDeleteMutation] = useBrandDeleteMutation({
    onCompleted: () => {
      showSuccessToast(t('brands:brand-deletion-successful'))
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [brandEditMutation] = useBrandEditMutation({
    onCompleted: () => {
      showSuccessToast(t('brands:brand-edit-successful'))
      setIdOfBrandToEdit(undefined)
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const handleFirstPageClick = () => {
    setCurrentPage(1)
    setInputPageNumber(1)
  }

  const handlePreviousPageClick = () => {
    setCurrentPage((oldPage) => oldPage - 1)
    setInputPageNumber((oldPage) => oldPage - 1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((oldPage) => oldPage + 1)
    setInputPageNumber((oldPage) => oldPage + 1)
  }

  const handleLastPageClick = () => {
    setCurrentPage(data!.brands.totalPages!)
    setInputPageNumber(data!.brands.totalPages!)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleDeleteBrand = (values: BrandDeleteMutationVariables) => {
    brandDeleteMutation({ variables: values })
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (inputPageNumber <= 0) {
        handlePageChange(1)
        setInputPageNumber(1)
      } else {
        handlePageChange(inputPageNumber)
      }
    }
  }

  /**
   *
   * @param id The id of the brand interacted with
   * @param actionType parameter used to distinguish action ('delete', 'edit', etc.) so we can trigger the proper functionality
   */
  const defineActionToTake = (id: string, actionType: string) => {
    if (actionType === 'edit') {
      const brandToEdit = data?.brands.entries.find((entry) => entry.id === id)
      const {
        name,
        description,
        trending,
        trendingImageUrl,
        isFeatured,
        highlighted,
        highlightedImageUrl,
      } = brandToEdit!
      setInitialValues({
        name: name,
        description: description,
        trending: trending,
        trendingImageUrl: trendingImageUrl || '',
        isFeatured: isFeatured,
        highlightedImageUrl: highlightedImageUrl || '',
        highlighted: highlighted,
      })
      setIdOfBrandToEdit(id)
      setIsFormVisible(true)
    }
    if (actionType === 'delete') {
      handleDeleteBrand({ id })
    }
  }

  const tableActions = [
    {
      label: t('general:edit'),
      functionToTrigger: defineActionToTake,
      actionType: 'edit',
    },
    {
      label: t('general:delete'),
      functionToTrigger: defineActionToTake,
      actionType: 'delete',
    },
  ]

  /**
   * This function opens the form solely for creating a new brand
   * Because of that we need to reset the values that might exists within the state
   * Since we're using the same form for `Adding` and `Editing`,
   * And because we need to save the info of the item to edit within the state,
   * We need to reset them when the user opens the form via `Add new`
   */
  const openForm = () => {
    setInitialValues({
      name: '',
      description: '',
      trending: false,
      trendingImageUrl: '',
      isFeatured: false,
      highlighted: false,
      highlightedImageUrl: '',
    })
    setIdOfBrandToEdit(undefined)
    setIsFormVisible(true)
  }

  const handleSubmit = (values: BrandCreateMutationVariables) => {
    if (idOfBrandToEdit) {
      const valuesWithId = { id: idOfBrandToEdit, ...values }
      brandEditMutation({ variables: valuesWithId })
    } else {
      brandCreateMutation({ variables: values })
    }
    setIsFormVisible(false)
    setInitialValues(undefined)
  }

  /**
   * When we delete an entry we do a new request
   * and the same happens when we delete the last entry of a page
   * By deleting the last entry of a page we need to now go
   * to the last page with results and while the API response is correct,
   * our `currentPage` and `inputPageNumeber` variables still need to be updated
   */
  useEffect(() => {
    if (data && currentPage > data.brands.totalPages!) {
      setCurrentPage(data.brands.totalPages!)
      setInputPageNumber(data.brands.totalPages!)
    }
  }, [currentPage, data])

  return (
    <div className={css.host}>
      <div className={css.sectionHeader}>
        <div className={css.leftContent}>
          <h1 className={css.sectionTitle}>{t('brands:brands-title')}</h1>
          <Button
            type={'button'}
            label={t('general:add-new')}
            size={ButtonSize.Small}
            onClick={openForm}
          />
        </div>
      </div>
      {(loading || data?.brands.entries.length === 0) && (
        <TablePlaceholder
          numberOfPlaceholderColumns={5}
          numberOfPlaceholderRows={5}
          numberOfPlaceholderActions={1}
          isPaginated
          isEmpty={data?.brands.entries.length === 0}
          emptyStatusText={t('table:table-empty-message')}
        />
      )}
      {error && (
        <p className={css.errorMessage}>{t('general:error-message')}</p>
      )}
      {!loading && data && data?.brands.entries.length !== 0 && (
        <Table
          pageNumber={currentPage}
          entriesPerPage={requestPageSize}
          totalEntries={data.brands.totalEntries ?? 0}
          entityName={t('brands-entity')}
          ofLabel={t('table:of-label')}
          isPaginated
          onPreviousPage={handlePreviousPageClick}
          onNextPage={handleNextPageClick}
          onFirstPage={handleFirstPageClick}
          onLastPage={handleLastPageClick}
          validateInputValue={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setInputPageNumber(Math.floor(+evt.target.value))
          }
          handleKeyDown={handleKeyDown}
          inputPageNumber={inputPageNumber}
        >
          <thead>
            <tr>
              <th>{t('table:name-label')}</th>
              <th>{t('table:number-of-products-label')}</th>
              <th>{t('table:featured-label')}</th>
              <th>{t('table:trending-label')}</th>
              <th>{t('table:highlighted-label')}</th>
              <th>{t('table:description-label')}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.brands.entries.map(
              ({
                id,
                name,
                description,
                countProducts,
                trending,
                highlighted,
                isFeatured,
              }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{countProducts}</td>
                  <td>{isFeatured ? t('general:yes') : t('general:no')}</td>
                  <td>{trending ? t('general:yes') : t('general:no')}</td>
                  <td>{highlighted ? t('general:yes') : t('general:no')}</td>
                  <td>{description}</td>
                  <td className={css.cellWithNoWrap}>
                    <TableActionLinks linksArray={tableActions} itemId={id} />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
      {initialValues && (
        <CenteredModal
          isVisible={isFormVisible}
          onClick={() => setIsFormVisible(false)}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={AddBrandSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <div className={css.formRow}>
                  {idOfBrandToEdit ? (
                    <FormSectionTitle title={t('brands:edit-brand-title')} />
                  ) : (
                    <FormSectionTitle title={t('brands:new-brand-title')} />
                  )}
                </div>

                <div className={css.formFieldsContainer}>
                  <Input
                    type="text"
                    name="name"
                    value={values.name}
                    errorMessage={
                      touched.name && errors.name ? errors.name : undefined
                    }
                    label={t('form:name-input-label')}
                    onChange={handleChange}
                  />

                  <InputMultiline
                    placeholderText=""
                    name="description"
                    value={values.description}
                    errorMessage={
                      touched.description && errors.description
                        ? errors.description
                        : undefined
                    }
                    label={t('form:description-input-label')}
                    onChange={handleChange}
                  />

                  <Checkbox
                    name="trending"
                    value={values.trending}
                    label={t('form:trending-input-label')}
                    isChecked={values.trending}
                    isDisabled={false}
                    onChange={handleChange}
                  />

                  {values.trending && (
                    <Input
                      type="text"
                      name="trendingImageUrl"
                      value={values.trendingImageUrl}
                      errorMessage={
                        touched.trendingImageUrl && errors.trendingImageUrl
                          ? errors.trendingImageUrl
                          : undefined
                      }
                      label={t('form:trending-image-input-label')}
                      onChange={handleChange}
                    />
                  )}

                  {values.trending &&
                    values.trendingImageUrl !== '' &&
                    !errors.trendingImageUrl && (
                      <div
                        className={`${css.imageContainer} ${css.trendingImage}`}
                        style={{
                          backgroundImage: `url(${values.trendingImageUrl})`,
                        }}
                      ></div>
                    )}

                  {values.trendingImageUrl !== '' &&
                    errors.trendingImageUrl && (
                      <p className={css.brandImageWarning}>
                        {t('form:imageUrl-error-label')}
                      </p>
                    )}

                  <Checkbox
                    name="isFeatured"
                    value={values.isFeatured}
                    label={t('form:featured-input-label')}
                    isChecked={values.isFeatured}
                    isDisabled={false}
                    onChange={handleChange}
                  />

                  <Checkbox
                    name="highlighted"
                    value={values.highlighted}
                    label={t('form:highlighted-input-label')}
                    isChecked={values.highlighted}
                    isDisabled={false}
                    onChange={handleChange}
                  />

                  {values.highlighted && (
                    <Input
                      type="text"
                      name="highlightedImageUrl"
                      value={values.highlightedImageUrl}
                      errorMessage={
                        touched.highlightedImageUrl &&
                        errors.highlightedImageUrl
                          ? errors.highlightedImageUrl
                          : undefined
                      }
                      label={t('form:highlighted-image-input-label')}
                      onChange={handleChange}
                    />
                  )}

                  {values.highlighted &&
                    values.highlightedImageUrl !== '' &&
                    !errors.highlightedImageUrl && (
                      <div
                        className={`${css.imageContainer} ${css.featuredImage}`}
                        style={{
                          backgroundImage: `url(${values.highlightedImageUrl})`,
                        }}
                      ></div>
                    )}

                  {values.highlightedImageUrl !== '' &&
                    errors.highlightedImageUrl && (
                      <p className={css.brandImageWarning}>
                        {t('form:imageUrl-error-label')}
                      </p>
                    )}
                </div>

                <div className={css.buttons}>
                  <Button
                    type="button"
                    label={t('general:cancel')}
                    loadingLabel={t('general:cancel')}
                    onClick={() => setIsFormVisible(false)}
                    secondary
                  />
                  <Button
                    type="submit"
                    label={
                      idOfBrandToEdit
                        ? t('form:save-changes-label')
                        : t('brands:add-brand-label')
                    }
                    loadingLabel={t('general:loading')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </CenteredModal>
      )}
    </div>
  )
}

export default Brands
