import React, { FC } from 'react'

interface Props {
  isHighlighted?: boolean
}

const DashboardBig: FC<Props> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 9H11V8H18V9Z" fill="hsla(0, 0%, 65%, 1)" />
    <path d="M11 12H18V11H11V12Z" fill="hsla(0, 0%, 65%, 1)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20ZM9 4H20V20H9V4ZM8 20H4V4H8V20Z"
      fill="hsla(0, 0%, 65%, 1)"
    />
    <path d="M11 15H15V14H11V15Z" fill="hsla(0, 0%, 65%, 1)" />
  </svg>
)

DashboardBig.defaultProps = {}

export default DashboardBig
