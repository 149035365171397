import { FC, useState } from 'react'

import TableChevronLeftSingle from '../Icons/TableChevronLeftSingle'
import TableChevronRightSingle from '../Icons/TableChevronRightSingle'

import css from './imagesCarousel.module.css'

interface Props {
  images: String[]
}

const ImagesCarousel: FC<Props> = ({ images }) => {
  const [carouselPosition, setCarouselPosition] = useState(0)
  const [currentClick, setCurrentClick] = useState(0)

  /**
   * This `2` represents the number of images that we show on the first slide
   * Currently hardcoded but we could change our carousel to take
   * whatever number we want to make it more dynamic
   */
  const maxNumberOfClicks = images.length - 2

  /**
   * Each card has 100px width and between them there's 8px of gap
   * So to move the container back and forward we need to take this
   * gap into consideration
   */
  const carouselCardWidthWithGap = 108

  const moveOneSlideBack = () => {
    setCarouselPosition(carouselPosition + carouselCardWidthWithGap)
    setCurrentClick(currentClick - 1)
  }

  const moveOneSlideForward = () => {
    setCarouselPosition(carouselPosition - carouselCardWidthWithGap)
    setCurrentClick(currentClick + 1)
  }

  return (
    <div className={css.host}>
      {currentClick !== 0 && (
        <button
          className={`${css.button} ${css.backButton}`}
          onClick={moveOneSlideBack}
        >
          <TableChevronLeftSingle />
        </button>
      )}
      <div
        className={css.carouselImagesContainer}
        style={{
          left: `${carouselPosition}px`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`${css.imageContainer} ${css.carouselImage}`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        ))}
      </div>
      {currentClick < maxNumberOfClicks && (
        <button
          className={`${css.button} ${css.forwardButton}`}
          onClick={moveOneSlideForward}
        >
          <TableChevronRightSingle />
        </button>
      )}
    </div>
  )
}

export default ImagesCarousel
