import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  useCategoriesQuery,
  useCategoriesAllLazyQuery,
  useCategoryCreateMutation,
  useCategoryEditMutation,
  useDeleteCategoryMutation,
  useBrandsAllLazyQuery,
  useCategoryEditFeaturedBrandsMutation,
  useCategoryLazyQuery,
} from '../../../../../queries/autogenerate/hooks'
import {
  DeleteCategoryMutationVariables,
  CategoryCreateMutationVariables,
  CategoryCreateMutation,
} from '../../../../../queries/autogenerate/operations'

import {
  referenceIdValidator,
  stringRequiredValidator,
} from '../../../../../helpers/FormValidators'

import {
  showSuccessToast,
  showErrorToast,
} from '../../../../../services/Toaster'

import Button, { ButtonSize } from '../../../../../components/Button'
import Table from '../../../../../components/Table'
import TablePlaceholder from '../../../../../components/TablePlaceholder'
import TableActionLinks from '../../../../../components/TableActionLinks'
import CenteredModal, {
  ModalSize,
} from '../../../../../components/CenteredModal'
import FormSectionTitle from '../../../../../components/FormSectionTitle'
import Input from '../../../../../components/Input'
import InputMultiline from '../../../../../components/InputMultiline'
import Dropdown from '../../../../../components/Dropdown'
import SearchBox, { SearchBoxSize } from '../../../../../components/SearchBox'
import CloseIcon from '../../../../../components/Icons/Close'
import Checkbox from '../../../../../components/Checkbox'

import css from './categories.module.css'

const Categories = () => {
  const { t } = useTranslation(['general', 'categories', 'table', 'form'])

  const [currentPage, setCurrentPage] = useState(1)
  const [inputPageNumber, setInputPageNumber] = useState(currentPage)

  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isOpenFeaturedBrandsModal, setIsOpenFeaturedBrandsModal] =
    useState(false)

  const [categoryId, setCategoryId] = useState('')

  const [searchInput, setSearchInput] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchNow, setSearchNow] = useState(false)

  const [newFeaturedBrands, setNewFeaturedBrands] = useState<
    { name: string; id: string }[]
  >([])

  const [initialValues, setInitialValues] = useState<
    CategoryCreateMutationVariables | undefined
  >(undefined)

  const [idOfSubCategoryToEdit, setIdOfSubCategoryToEdit] = useState<
    string | undefined
  >(undefined)

  const AddCategorySchema = Yup.object().shape({
    categoryId: referenceIdValidator(t),
    name: stringRequiredValidator(t),
    description: stringRequiredValidator(t),
  })

  const numberOfCategoriesPerPage = 3

  const { data, loading, error, refetch } = useCategoriesQuery({
    variables: {
      page: currentPage,
      pageSize: numberOfCategoriesPerPage,
    },
  })

  const [categoryCreateMutation] = useCategoryCreateMutation({
    onCompleted: (data: CategoryCreateMutation) => {
      showSuccessToast(
        t('categories:subcategory-creation-successful', {
          name: `${data.categoryCreate}`,
        })
      )
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [categoryEditMutation] = useCategoryEditMutation({
    onCompleted: () => {
      showSuccessToast(t('categories:subcategory-edit-successful'))
      setIdOfSubCategoryToEdit(undefined)
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [categoryDeleteMutation] = useDeleteCategoryMutation({
    onCompleted: () => {
      showSuccessToast(t('categories:toast-delete-success-message'))
      refetch()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  /*
   * Get all categories to be listed in the dropdown menu present in the add/edit modal.
   * Convert them to an objects array containing, per each category, a title and value _ this is a requirement of the dropdown component
   */
  const [
    getCategories,
    { data: categoriesData, loading: loadingAllCategories },
  ] = useCategoriesAllLazyQuery()

  const availableCategories = categoriesData?.categoriesAll.map((category) => ({
    title: category.name,
    value: category.id,
  }))

  const [getCategory, { data: categoryInfo }] = useCategoryLazyQuery()

  const [getBrands, { data: brandsData }] = useBrandsAllLazyQuery()

  const [categoryEditFeaturedBrandsMutation] =
    useCategoryEditFeaturedBrandsMutation({
      onCompleted: () => {
        showSuccessToast(t('categories:successfully-edited-featured-brands'))
        refetch()
        clearState()
      },
      onError: (error: ApolloError) => {
        showErrorToast(error.message)
      },
    })

  const clearState = () => {
    setIsOpenFeaturedBrandsModal(false)
    setCategoryId('')
    setNewFeaturedBrands([])
    setSearchInput('')
    setSearchTerm('')
    setSearchNow(false)
  }

  const handlePreviousPageClick = () => {
    setCurrentPage((oldPage) => oldPage - 1)
    setInputPageNumber((oldPage) => oldPage - 1)
  }

  const handleFirstPageClick = () => {
    setCurrentPage(1)
    setInputPageNumber(1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((oldPage) => oldPage + 1)
    setInputPageNumber((oldPage) => oldPage + 1)
  }

  const handleLastPageClick = () => {
    setCurrentPage(data?.categories.totalPages!)
    setInputPageNumber(data?.categories.totalPages!)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (inputPageNumber <= 0) {
        handlePageChange(1)
        setInputPageNumber(1)
      } else {
        handlePageChange(inputPageNumber)
      }
    }
  }

  const handleDeleteRow = (values: DeleteCategoryMutationVariables) => {
    categoryDeleteMutation({ variables: values })
  }

  /**
   *
   * @param id The id of the subcategory interacted with
   * @param actionType parameter used to distinguish action ('delete', 'edit', etc.) so we can trigger the proper functionality
   */
  const defineActionToTake = (
    id: string,
    categoryId: string,
    actionType: string
  ) => {
    if (actionType === 'edit') {
      getCategories()

      const parentCategory = data?.categories.entries.find(
        (entry) => entry.id === categoryId
      )

      const subcategoryToEdit = parentCategory?.subCategories.find(
        (entry) => entry.id === id
      )

      const { name, description } = subcategoryToEdit!
      setInitialValues({
        categoryId: categoryId,
        name: name,
        description: description,
      })
      setIdOfSubCategoryToEdit(id)
      setIsFormVisible(true)
    }

    if (actionType === 'delete') {
      handleDeleteRow({ id })
    }
  }

  const tableActions = [
    {
      label: t('general:edit'),
      functionToTrigger: defineActionToTake,
      actionType: 'edit',
    },
    {
      label: t('general:delete'),
      functionToTrigger: defineActionToTake,
      actionType: 'delete',
    },
  ]

  /**
   * This function opens the form solely for creating a new category
   * Because of that we need to reset the values that might exists within the state
   * Since we're using the same form for `Adding` and `Editing`,
   * And because we need to save the info of the item to edit within the state,
   * We need to reset them when the user opens the form via `Add new`
   */
  const openForm = () => {
    setInitialValues({
      categoryId: '',
      name: '',
      description: '',
    })

    getCategories()
    setIdOfSubCategoryToEdit(undefined)
    setIsFormVisible(true)
  }

  const handleSubmit = (values: CategoryCreateMutationVariables) => {
    if (idOfSubCategoryToEdit) {
      const valuesWithId = { id: idOfSubCategoryToEdit, ...values }
      categoryEditMutation({ variables: valuesWithId })
    } else {
      categoryCreateMutation({ variables: values })
    }

    setIsFormVisible(false)
    setInitialValues(undefined)
  }

  const openModal = (id: string) => {
    setCategoryId(id)
    setSearchInput('')
    setSearchTerm('')
    setSearchNow(false)
    setIsOpenFeaturedBrandsModal(true)
  }

  const handleOnSearchClick = () => {
    setSearchTerm(searchInput)
    setSearchNow(true)
  }

  const handleCheckboxBrand = (result: { name: string; id: string }) => {
    if (
      newFeaturedBrands.filter((brand) => brand.id === result.id).length === 0
    ) {
      let array = [...newFeaturedBrands, result]
      setNewFeaturedBrands(array)
    } else {
      let array = newFeaturedBrands.filter((brand) => brand.id !== result.id)
      setNewFeaturedBrands(array)
    }
  }

  const handleRemoveBrand = (brand: { name: string; id: string }) => {
    const reducedNewFeaturedBrands = newFeaturedBrands.filter(
      (featured) => brand.id !== featured.id
    )

    setNewFeaturedBrands(reducedNewFeaturedBrands)
  }

  const handleCancelFeaturedBrands = () => {
    clearState()
  }

  const handleSubmitFeaturedBrands = () => {
    const featuredBrands = newFeaturedBrands.map((brand) => brand.id)
    categoryEditFeaturedBrandsMutation({
      variables: {
        id: categoryId,
        brandIds: featuredBrands,
      },
    })
    setCategoryId('')
  }

  const closeModal = () => {
    clearState()
  }

  //Search category:
  //const [searchInput, setSearchInput] = useState('')

  //const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //setSearchInput(event.currentTarget.value)
  //}

  //const handleSearch = () => {
  //To be completed in a separate PR
  //Object.values(MockUpTableContent).filter(
  //(category) => category.name.toLowerCase() === searchInput.toLowerCase()
  //)

  /**
   * When we delete an entry we do a new request
   * and the same happens when we delete the last entry of a page
   * By deleting the last entry of a page we need to now go
   * to the last page with results and while the API response is correct,
   * our `currentPage` and `inputPageNumeber` variables still need to be updated
   */
  useEffect(() => {
    if (data && currentPage > data.categories.totalPages!) {
      setCurrentPage(data.categories.totalPages!)
      setInputPageNumber(data.categories.totalPages!)
    }
  }, [currentPage, data])

  useEffect(() => {
    if (searchInput === '') {
      getBrands()
    }
  }, [searchInput, getBrands])

  useEffect(() => {
    if (searchNow) {
      getBrands({
        variables: {
          search: searchTerm,
        },
      })
      setSearchNow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchNow, getBrands])

  useEffect(() => {
    getCategory({ variables: { id: categoryId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, getCategory])

  useEffect(() => {
    if (
      categoryInfo?.category &&
      categoryInfo?.category?.featuredBrands.length > 0
    ) {
      let existingFeaturedBrands = categoryInfo?.category?.featuredBrands.map(
        (brand) => ({ name: brand.name, id: brand.id })
      )
      setNewFeaturedBrands(existingFeaturedBrands)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryInfo?.category, getCategory])

  return (
    <section className={css.host}>
      <div className={css.sectionHeader}>
        <div className={css.leftContent}>
          <h1 className={css.sectionTitle}>
            {t('categories:categories-title')}
          </h1>
          <Button
            type={'button'}
            label={t('general:add-new')}
            size={ButtonSize.Small}
            onClick={openForm}
          />
        </div>
        {/* <SearchBox
          size={SearchBoxSize.Small}
          inputName={t('input-name-categories-search')}
          placeholder={t('input-placeholder-categories-search')}
          value={searchInput}
          onChange={handleInputChange}
          onClick={handleSearch}
        /> */}
      </div>

      {(loading || data?.categories.entries.length === 0) && (
        <TablePlaceholder
          numberOfPlaceholderColumns={5}
          numberOfPlaceholderRows={5}
          numberOfPlaceholderActions={1}
          isPaginated
          isEmpty={data?.categories.entries.length === 0}
          emptyStatusText={t('table:table-empty-message')}
        />
      )}
      {error && (
        <p className={css.errorMessage}>{t('general:error-message')}</p>
      )}
      {!loading && data && data.categories.entries.length !== 0 && (
        <Table
          isPaginated
          pageNumber={currentPage}
          totalEntries={data.categories.totalEntries ?? 0}
          entriesPerPage={numberOfCategoriesPerPage}
          entityName={t('categories:categories-label')}
          ofLabel={t('table:of-label')}
          isPlainTable
          onPreviousPage={handlePreviousPageClick}
          onNextPage={handleNextPageClick}
          onFirstPage={handleFirstPageClick}
          onLastPage={handleLastPageClick}
          validateInputValue={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setInputPageNumber(Math.floor(+evt.target.value))
          }
          handleKeyDown={handleKeyDown}
          inputPageNumber={inputPageNumber}
        >
          <thead>
            <tr>
              {/* <th className={css.checkboxColumnWidth}></th> */}
              <th>{t('table:category-label')}</th>
              <th>{t('table:sub-category-label')}</th>
              <th>{t('table:number-of-products-label')}</th>
              <th>{t('table:description-label')}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.categories.entries?.map((category) => (
              <React.Fragment key={category.id}>
                <tr id={category.id} className={css.categoriesRow}>
                  {/* <td>
                      <Checkbox
                        name={`${category}`}
                        value={`${category}`}
                        isChecked={false}
                      />
                    </td> */}
                  <td>{category.name}</td>
                  <td>{category.subCategories.length}</td>
                  <td></td>
                  <td>{category.description}</td>
                  <td>
                    <div
                      className={css.featuredBrandsButton}
                      onClick={() => openModal(category.id)}
                    >
                      {t('categories:edit-featured-brands')}
                    </div>
                  </td>
                </tr>

                {category.subCategories &&
                  category.subCategories.map((subcategory) => (
                    <tr
                      id={subcategory.id}
                      key={subcategory.id}
                      className={css.subCategoriesRow}
                    >
                      {/*
                          <td>
                            <Checkbox
                              name={`${subcategory}`}
                              value={`${subcategory}`}
                              isChecked={false}
                            />
                          </td>
                      */}
                      <td></td>
                      <td>{subcategory.name}</td>
                      <td>{subcategory.countProducts}</td>
                      <td>{subcategory.description}</td>
                      <td className={css.cellWithNoWrap}>
                        <TableActionLinks
                          linksArray={tableActions}
                          itemId={subcategory.id}
                          parentId={subcategory.categoryId!}
                        />
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}

      {categoryId && categoryInfo && (
        <CenteredModal
          size={ModalSize.Large}
          isVisible={isOpenFeaturedBrandsModal}
          onClick={closeModal}
        >
          <div className={css.bottomContainer}>
            <div className={css.featuredWrapper}>
              <div className={css.container}>
                <div className={css.leftPanelTagline}>
                  {t('categories:search-featured-brands-title')}
                </div>
                <SearchBox
                  size={SearchBoxSize.Large}
                  inputName="searchInput"
                  placeholder={t('categories:search-brand-placeholder')}
                  value={searchInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(e.target.value)
                  }}
                  onClick={handleOnSearchClick}
                  disabled={false}
                />
                <div className={css.searchResultsContainer}>
                  {!loading &&
                    brandsData?.brandsAll.map((result, index) => (
                      <div className={css.searchResult} key={index}>
                        {result.name}
                        <div className={css.addButton}>
                          <Checkbox
                            name={'brand'}
                            value={result.id}
                            isChecked={
                              newFeaturedBrands &&
                              newFeaturedBrands.filter(
                                (brand) => brand.id === result.id
                              ).length > 0
                            }
                            onChange={() => handleCheckboxBrand(result)}
                          />
                        </div>
                      </div>
                    ))}
                  {!loading && brandsData?.brandsAll.length === 0 && (
                    <p className={css.noBrandResults}>
                      {t('categories:no-search-results')}
                    </p>
                  )}
                </div>
              </div>
              <div className={css.rightContainer}>
                <div className={css.container}>
                  <div className={css.leftPanelTagline}>
                    {t('categories:current-featured-brands-title')}
                  </div>
                  <div className={css.searchResultsContainer}>
                    {newFeaturedBrands &&
                      newFeaturedBrands.map((brand, index) => (
                        <div className={css.searchResult} key={index}>
                          {brand.name}
                          <div
                            className={css.addButton}
                            onClick={() => handleRemoveBrand(brand)}
                          >
                            <CloseIcon />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.buttons}>
            <Button
              label={t('general:save')}
              onClick={handleSubmitFeaturedBrands}
            />
            <Button
              label={t('general:cancel')}
              secondary
              onClick={handleCancelFeaturedBrands}
            />
          </div>
        </CenteredModal>
      )}

      {initialValues && !loadingAllCategories && (
        <CenteredModal
          isVisible={isFormVisible}
          onClick={() => setIsFormVisible(false)}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={AddCategorySchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <div className={css.formRow}>
                  {/* Add new subcategory */}
                  {!idOfSubCategoryToEdit && (
                    <FormSectionTitle
                      title={t('categories:new-subcategory-title')}
                    />
                  )}
                  {/* Edit subcategory */}
                  {idOfSubCategoryToEdit && (
                    <FormSectionTitle
                      title={t('categories:edit-subcategory-title')}
                    />
                  )}
                </div>

                <div className={css.formFieldsContainer}>
                  {availableCategories && (
                    <Dropdown
                      label={t('categories:category-label')}
                      options={availableCategories}
                      name="categoryId"
                      value={values.categoryId}
                      placeholderText={
                        values.categoryId
                          ? values.categoryId
                          : t('categories:dropdown-categories-placeholder')
                      }
                      errorMessage={
                        touched.categoryId && errors.categoryId
                          ? errors.categoryId
                          : undefined
                      }
                      onChange={handleChange}
                    />
                  )}

                  <Input
                    type="text"
                    name="name"
                    value={values.name}
                    errorMessage={
                      touched.name && errors.name ? errors.name : undefined
                    }
                    label={t('form:name-input-label')}
                    onChange={handleChange}
                  />

                  <InputMultiline
                    name="description"
                    value={values.description}
                    errorMessage={
                      touched.description && errors.description
                        ? errors.description
                        : undefined
                    }
                    label={t('form:description-input-label')}
                    onChange={handleChange}
                  />
                </div>

                <div className={css.buttons}>
                  <Button
                    type="button"
                    label={t('general:cancel')}
                    loadingLabel={t('general:cancel')}
                    onClick={() => setIsFormVisible(false)}
                    secondary
                  />
                  <Button
                    type="submit"
                    label={
                      idOfSubCategoryToEdit
                        ? t('form:save-changes-label')
                        : t('categories:add-subcategory-label')
                    }
                    loadingLabel={t('general:loading')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </CenteredModal>
      )}
    </section>
  )
}

Categories.defaultProps = {}

export default Categories
