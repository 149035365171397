import { useHistory, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  useFeedDeleteMutation,
  useFeedCreateMutation,
  useFeedEditMutation,
  useFeedScrapeMutation,
  useFeedsLazyQuery,
} from '../../../../queries/autogenerate/hooks'
import {
  FeedDeleteMutationVariables,
  FeedCreateMutation,
  FeedCreateMutationVariables,
} from '../../../../queries/autogenerate/operations'
import {
  FeedFrequencyValues,
  FeedNetworkValues,
} from '../../../../queries/autogenerate/schemas'

import { requestPageSize } from '../../../../helpers/Constants'
import { parseBackendDateTime } from '../../../../helpers/Time'
import {
  stringRequiredValidator,
  booleanValidator,
} from '../../../../helpers/FormValidators'

import { showSuccessToast, showErrorToast } from '../../../../services/Toaster'

import Button, { ButtonSize } from '../../../../components/Button'
import CenteredModal from '../../../../components/CenteredModal'
import Dropdown from '../../../../components/Dropdown'
import FeedStatus from '../../../../components/FeedStatus'
import FormSectionTitle from '../../../../components/FormSectionTitle'
import Input from '../../../../components/Input'
import Switch from '../../../../components/Switch'
import TablePlaceholder from '../../../../components/TablePlaceholder'
import Table from '../../../../components/Table'
import TableActionLinks from '../../../../components/TableActionLinks'
import SearchBox, { SearchBoxSize } from '../../../../components/SearchBox'

import css from './feeds.module.css'

const Feeds = () => {
  const { t } = useTranslation(['general', 'feeds', 'table', 'form'])
  const history = useHistory()

  const availableNetworks = [
    { value: 'PARTNERIZE', title: 'PARTNERIZE' },
    { value: 'RAKUTEN', title: 'RAKUTEN' },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const [inputPageNumber, setInputPageNumber] = useState(currentPage)

  const [isFormVisible, setIsFormVisible] = useState(false)
  const [idOfFeedToEdit, setIdOfFeedToEdit] = useState<string | undefined>(
    undefined
  )

  const [searchTerm, setSearchTerm] = useState('')

  const [initialValues, setInitialValues] = useState<
    FeedCreateMutationVariables | undefined
  >(undefined)

  const AddFeedSchema = Yup.object().shape({
    network: stringRequiredValidator(t),
    merchantId: stringRequiredValidator(t),
    merchant: stringRequiredValidator(t),
    isEnabled: booleanValidator(t),
  })

  const [getSearchFeeds, { data, loading, error }] = useFeedsLazyQuery({
    variables: {
      page: currentPage,
      pageSize: requestPageSize,
    },
  })

  const [feedCreateMutation, { loading: loadingFeedCreate }] =
    useFeedCreateMutation({
      onCompleted: (data: FeedCreateMutation) => {
        showSuccessToast(
          t('feeds:feed-create-success-message', {
            name: `${data.feedCreate}`,
          })
        )
        getSearchFeeds()
      },
      onError: (errors: ApolloError) => {
        showErrorToast(errors.message)
      },
    })

  const [feedDeleteMutation, { loading: loadingFeedDelete }] =
    useFeedDeleteMutation({
      onCompleted: () => {
        showSuccessToast(t('feeds:feed-delete-success-message'))
        getSearchFeeds()
      },
      onError: (error: ApolloError) => {
        showErrorToast(error.message)
      },
    })

  const [feedEditMutation, { loading: loadingFeedEdit }] = useFeedEditMutation({
    onCompleted: () => {
      showSuccessToast(t('feeds:feed-edit-success-message'))
      setIdOfFeedToEdit(undefined)
      getSearchFeeds()
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [feedUpdateMutation, { loading: feedUpdating }] = useFeedScrapeMutation(
    {
      onCompleted: () => {
        showSuccessToast(t('feeds:feed-update-success-message'))
      },
      onError: (feedUpdateError: ApolloError) => {
        showErrorToast(feedUpdateError.message)
      },
    }
  )

  const handlePreviousPageClick = () => {
    setCurrentPage((oldPage) => oldPage - 1)
    setInputPageNumber((oldPage) => oldPage - 1)
  }

  const handleFirstPageClick = () => {
    setCurrentPage(1)
    setInputPageNumber(1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((oldPage) => oldPage + 1)
    setInputPageNumber((oldPage) => oldPage + 1)
  }

  const handleLastPageClick = () => {
    setCurrentPage(data?.feeds.totalPages!)
    setInputPageNumber(data?.feeds.totalPages!)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleDeleteFeed = (values: FeedDeleteMutationVariables) => {
    feedDeleteMutation({ variables: values })
  }

  const handleUpdateFeed = (idOfFeedToUpdate: string) => {
    feedUpdateMutation({ variables: { id: idOfFeedToUpdate } })
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (inputPageNumber <= 0) {
        handlePageChange(1)
        setInputPageNumber(1)
      } else {
        handlePageChange(inputPageNumber)
      }
    }
  }

  const handleOnSearch = () => {
    setSearchTerm(searchTerm)

    let params = new URLSearchParams(document.location.search)

    if (searchTerm === '') {
      params.delete('search')
    } else {
      params.set('search', searchTerm)
    }

    history.push({ search: params.toString() })
  }

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const translateFrequency = (frequency: FeedFrequencyValues) => {
    switch (frequency) {
      case 'WEEKLY':
        return t('form:frequency-weekly')
      case 'MONTHLY':
        return t('form:frequency-monthly')
      default:
        return ''
    }
  }

  const frequencyDropdownOptions = () => {
    /*
     * An array with enum items ordered chronologically was hard-coded because the BE cannot provide it in the correct order.
     * This is needed to display options in a chronological order in the  form dropdown
     */
    const sortedEnum = [FeedFrequencyValues.Weekly, FeedFrequencyValues.Monthly]
    const arrayOfFrequencyOptions: Array<{
      title: string
      value: FeedFrequencyValues
    }> = []

    sortedEnum.forEach((option, i) => {
      arrayOfFrequencyOptions[i] = {
        title: translateFrequency(option),
        value: option,
      }
    })

    return arrayOfFrequencyOptions
  }

  /**
   *
   * @param id The id of the feed interacted with
   * @param actionType parameter used to distinguish action ('delete', 'edit', etc.) so we can trigger the proper functionality
   */
  const defineActionToTake = (id: string, actionType: string) => {
    if (actionType === 'edit') {
      const feedToEdit = data?.feeds.entries.find((entry) => entry.id === id)
      const { network, merchantId, merchant, frequency, isEnabled } =
        feedToEdit!

      setInitialValues({
        network: network,
        merchantId: merchantId,
        merchant: merchant,
        frequency: frequency,
        isEnabled: isEnabled,
      })
      setIdOfFeedToEdit(id)
      setIsFormVisible(true)
    }
    if (actionType === 'delete') {
      handleDeleteFeed({ id })
    }
  }

  const tableActions = [
    {
      label: t('general:edit'),
      functionToTrigger: defineActionToTake,
      actionType: 'edit',
    },
    {
      label: t('general:delete'),
      functionToTrigger: defineActionToTake,
      actionType: 'delete',
    },
  ]

  /**
   * This function opens the form solely for creating a new feed
   * Because of that we need to reset the values that might exists within the state
   * Since we're using the same form for `Adding` and `Editing`,
   * And because we need to save the info of the item to edit within the state,
   * We need to reset them when the user opens the form via `Add new`
   */
  const openForm = () => {
    setInitialValues({
      network: FeedNetworkValues.Partnerize,
      merchantId: '',
      merchant: '',
      frequency: FeedFrequencyValues.Weekly,
      isEnabled: false,
    })
    setIdOfFeedToEdit(undefined)
    setIsFormVisible(true)
  }

  const handleSubmit = (values: FeedCreateMutationVariables) => {
    if (idOfFeedToEdit) {
      const valuesWithId = {
        id: idOfFeedToEdit,
        ...values,
      }
      feedEditMutation({
        variables: valuesWithId,
      })
    } else {
      feedCreateMutation({ variables: values })
    }
    setIsFormVisible(false)
    setInitialValues(undefined)
  }

  /**
   * When we delete an entry we do a new request
   * and the same happens when we delete the last entry of a page
   * By deleting the last entry of a page we need to now go
   * to the last page with results and while the API response is correct,
   * our `currentPage` and `inputPageNumeber` variables still need to be updated
   */
  useEffect(() => {
    if (data && currentPage > data.feeds.totalPages!) {
      setCurrentPage(data.feeds.totalPages!)
      setInputPageNumber(data.feeds.totalPages!)
    }
  }, [currentPage, data])

  const search = useLocation().search

  useEffect(() => {
    let urlSearch = new URLSearchParams(search).get('search')
    if (urlSearch && urlSearch !== '') {
      setSearchTerm(urlSearch)
    }

    getSearchFeeds({
      variables: {
        page: currentPage,
        pageSize: requestPageSize,
        search: urlSearch,
      },
    })
  }, [currentPage, getSearchFeeds, search])

  return (
    <section className={css.host}>
      <div className={css.sectionHeader}>
        <div className={css.leftContent}>
          <h1 className={css.sectionTitle}>{t('feeds:feeds-title')}</h1>
          <Button
            type={'button'}
            label={t('general:add-new')}
            size={ButtonSize.Small}
            onClick={openForm}
          />
        </div>
        <div className={css.rightContent}>
          <SearchBox
            size={SearchBoxSize.Large}
            placeholder={t('feeds:searchbox-placeholder')}
            onClick={handleOnSearch}
            onChange={handleOnChangeSearch}
            value={searchTerm}
            inputName={'search'}
          />
        </div>
      </div>
      {(loading ||
        loadingFeedDelete ||
        loadingFeedCreate ||
        loadingFeedEdit ||
        data?.feeds.entries.length === 0) && (
        <TablePlaceholder
          numberOfPlaceholderColumns={10}
          numberOfPlaceholderRows={5}
          numberOfPlaceholderActions={2}
          isPaginated
          isEmpty={data?.feeds.entries.length === 0}
          emptyStatusText={
            searchTerm !== ''
              ? t('table:no-search-results-empty-message')
              : t('table:table-empty-message')
          }
        />
      )}
      {error && (
        <p className={css.errorMessage}>{t('general:error-message')}</p>
      )}
      {!loading &&
        !loadingFeedDelete &&
        !loadingFeedCreate &&
        !loadingFeedEdit &&
        data &&
        data?.feeds.entries.length !== 0 && (
          <Table
            isPaginated
            pageNumber={currentPage}
            totalEntries={data?.feeds.totalEntries ?? 0}
            entriesPerPage={requestPageSize}
            entityName={t('table:items-label')}
            ofLabel={t('table:of-label')}
            onPreviousPage={handlePreviousPageClick}
            onNextPage={handleNextPageClick}
            onFirstPage={handleFirstPageClick}
            onLastPage={handleLastPageClick}
            validateInputValue={(evt: React.ChangeEvent<HTMLInputElement>) =>
              setInputPageNumber(Math.floor(+evt.target.value))
            }
            handleKeyDown={handleKeyDown}
            inputPageNumber={inputPageNumber}
          >
            <thead>
              <tr>
                <th>{t('table:network-label')}</th>
                <th>{t('table:merchant-label')}</th>
                <th>{t('table:status-label')}</th>
                <th>{t('table:frequency-label')}</th>
                <th>{t('table:last-update-label')}</th>
                <th>{t('table:product-information-label')}</th>
                <th>{t('table:enabled-label')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.feeds.entries.map((feed) => {
                const percentage =
                  feed.productCount === 0
                    ? 0
                    : (feed.productLoaded / feed.productCount) * 100
                const productInformation = `${feed.productCount} / ${feed.productLoaded} (${percentage}%)`

                return (
                  <tr key={feed.id}>
                    <td>
                      <p className={css.ellipsedText}>{feed.network}</p>
                    </td>
                    <td>
                      <p>{feed.merchantId}</p>
                      <p className={css.ellipsedText}>{feed.merchant}</p>
                    </td>
                    <td>
                      <FeedStatus
                        isDisabled={!feed.isEnabled}
                        statusCode={
                          feed?.scrapeLogs!.length > 0
                            ? feed.scrapeLogs![0].status
                            : 'noInfo'
                        }
                      />
                    </td>
                    <td>{translateFrequency(feed.frequency)}</td>
                    <td className={css.cellWithNoWrap}>
                      {feed?.scrapeLogs!.length > 0
                        ? parseBackendDateTime(feed.scrapeLogs![0].updatedAt)
                        : '-'}
                    </td>
                    <td className={css.cellWithNoWrap}>{productInformation}</td>
                    <td>
                      {feed.isEnabled ? t('general:yes') : t('general:no')}
                    </td>
                    <td>
                      <Button
                        label={t('table:update-label')}
                        size={ButtonSize.Small}
                        secondary
                        disabled={!feed.isEnabled || feedUpdating}
                        onClick={() => handleUpdateFeed(feed.id)}
                      />
                    </td>
                    <td className={css.cellWithNoWrap}>
                      <TableActionLinks
                        linksArray={tableActions}
                        itemId={feed.id}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      {initialValues && (
        <CenteredModal
          isVisible={isFormVisible}
          onClick={() => setIsFormVisible(false)}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={AddFeedSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form>
                <div className={css.formRow}>
                  {idOfFeedToEdit ? (
                    <FormSectionTitle title={t('feeds:edit-feed-title')} />
                  ) : (
                    <FormSectionTitle title={t('feeds:add-new-feed-title')} />
                  )}
                </div>
                <div className={css.formFieldsContainer}>
                  {/* Network ID */}
                  <div className={css.subsectionWrapper}>
                    <h2 className={css.sectionSubtitle}>
                      {t('form:network-label')}
                    </h2>

                    {/* Network name */}
                    <Dropdown
                      label={t('form:name-input-label')}
                      options={availableNetworks}
                      name="network"
                      value={values.network}
                      placeholderText={values.network}
                      errorMessage={
                        touched.network && errors.network
                          ? errors.network
                          : undefined
                      }
                      onChange={handleChange}
                    />
                  </div>

                  {/* Merchant ID */}
                  <div className={css.subsectionWrapper}>
                    <h2 className={css.sectionSubtitle}>
                      {t('form:merchant-label')}
                    </h2>
                    <Input
                      type="text"
                      name="merchantId"
                      value={values.merchantId}
                      errorMessage={
                        touched.merchantId && errors.merchantId
                          ? errors.merchantId
                          : undefined
                      }
                      label={t('form:id-input-label')}
                      onChange={handleChange}
                      disabled={idOfFeedToEdit !== undefined}
                    />
                    {/* Merchant name */}
                    <Input
                      type="text"
                      name="merchant"
                      value={values.merchant}
                      errorMessage={
                        touched.merchant && errors.merchant
                          ? errors.merchant
                          : undefined
                      }
                      label={t('form:name-input-label')}
                      onChange={handleChange}
                      disabled={idOfFeedToEdit !== undefined}
                    />
                  </div>
                  <Dropdown
                    label={t('form:frequency-label')}
                    options={frequencyDropdownOptions()}
                    name="frequency"
                    value={values.frequency}
                    onChange={handleChange}
                    placeholderText=""
                  />
                  <Switch
                    name={'isEnabled'}
                    labelActive={t('general:enabled')}
                    labelNotActive={t('general:disabled')}
                    isActive={values.isEnabled}
                    onChange={handleChange}
                  />
                </div>

                <div className={css.buttons}>
                  <Button
                    type="button"
                    label={t('general:cancel')}
                    loadingLabel={t('general:cancel')}
                    onClick={() => setIsFormVisible(false)}
                    secondary
                  />
                  <Button
                    type="submit"
                    label={
                      idOfFeedToEdit
                        ? t('form:save-changes-label')
                        : t('feeds:add-feed-label')
                    }
                    loadingLabel={t('general:loading')}
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </CenteredModal>
      )}
    </section>
  )
}

export default Feeds
