import React, { FC, useRef } from 'react'

import useDetectOutsideClick from '../../hooks/useDetectClickOutside'

import ChevronDownSmall from '../Icons/ChevronDownSmall'
import ChevronUpSmall from '../Icons/ChevronUpSmall'

import css from './dropdown-bulk.module.css'

interface Props {
  options?: { title: string | undefined; value: any }[]
  name?: string
  value?: any
  placeholderText?: string
  errorMessage?: string
  onChange?: (e: React.ChangeEvent<any>) => void
}

const DropdownBulk: FC<Props> = ({
  options,
  placeholderText,
  name,
  value,
  errorMessage,
  onChange,
}) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useDetectOutsideClick(dropdownRef, false)

  let placeholder = placeholderText

  // when we already have a value we need to update the "placeholder" shown
  if (value !== undefined) {
    // we disable the `===` because of the problem of the input converting the value numbers to string
    const selectedOption =
      // eslint-disable-next-line eqeqeq
      options && options.find((option) => option.value == value)
    placeholder = selectedOption?.title
      ? selectedOption?.title
      : placeholderText
  }

  const switchOpenState = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={css.host}>
      <div className={css.container} onClick={switchOpenState}>
        <div
          className={`${css.input} ${isOpen && css.focus} ${
            value !== (undefined || '') ? css.filled : ''
          } ${errorMessage && css.error}`}
        >
          {placeholder}
        </div>

        <div className={css.icon}>
          {isOpen ? <ChevronUpSmall /> : <ChevronDownSmall />}
        </div>
        <div
          ref={dropdownRef}
          className={`${css.dropdown} ${isOpen && css.isOpen}`}
        >
          {options &&
            options.map(({ title, value: optionValue }, index) => (
              <div
                key={`${index}-${optionValue}`}
                className={css.option}
                onClick={switchOpenState}
              >
                {/* the actual input used in the form */}
                <input
                  type="radio"
                  name={name}
                  value={optionValue}
                  onChange={onChange}
                />

                <p>{title}</p>
              </div>
            ))}
        </div>
      </div>

      {errorMessage && <span className={css.errorMessage}>{errorMessage}</span>}
    </div>
  )
}
DropdownBulk.defaultProps = {}

export default DropdownBulk
