import React, { FC } from 'react'

interface Props {}

const ChevronDownSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.76297 6L8 9.5654 11.237 6l.763.84037L8.38149 10.826c-.21069.232-.55229.232-.76298 0L4 6.84037 4.76297 6z"
        fill="#3D4452"
      />
    </svg>
  )
}

ChevronDownSmall.defaultProps = {}

export default ChevronDownSmall
