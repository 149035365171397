import React, { FC } from 'react'
import css from './tableActionLinks.module.css'

interface Link {
  label: string
  functionToTrigger: Function
  actionType: string
}

interface Props {
  linksArray: Array<Link>
  itemId: string
  parentId?: string | undefined
}

const TableActionLinks: FC<Props> = ({ linksArray, itemId, parentId }) => {
  return (
    <div className={css.linksWrapper}>
      {linksArray.map(({ label, functionToTrigger, actionType }, i) => (
        <React.Fragment key={i}>
          <button
            className={css.link}
            onClick={
              parentId
                ? () => functionToTrigger(itemId, parentId, actionType)
                : () => functionToTrigger(itemId, actionType)
            }
          >
            {label}
          </button>
          {linksArray.length - 1 !== i && <p className={css.separator}>/</p>}
        </React.Fragment>
      ))}
    </div>
  )
}

export default TableActionLinks
