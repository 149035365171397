import React, { FC } from 'react'

interface Props {
  isHighlighted?: boolean
}

const GearSmall: FC<Props> = ({ isHighlighted }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 8.00006c0 1.65686-1.34318 3.00004-3.00004 3.00004-1.65685 0-3-1.34318-3-3.00004 0-1.65685 1.34315-3 3-3 1.65686 0 3.00004 1.34315 3.00004 3zm-1 0c0 1.10457-.89547 2.00004-2.00004 2.00004-1.10457 0-2-.89547-2-2.00004 0-1.10457.89543-2 2-2s2.00004.89543 2.00004 2z"
        fill={isHighlighted ? '#FD780D' : '#949CA8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52549 1.00146c-.78346 0-1.41859.63513-1.41859 1.41859 0 .3729-.45087.55969-.71459.29597-.55399-.55399-1.45221-.554-2.00621 0l-.67004.67003c-.55401.55402-.55399 1.45226.00001 2.00627.26374.26374.07693.71465-.29599.71465-.78348 0-1.41862.63513-1.41862 1.41862v.9476c0 .78347.63513 1.41859 1.41859 1.41859.3729 0 .55968.45092.29597.71462-.55399.554-.554 1.4522 0 2.0062l.67006.67c.554.554 1.45221.554 2.00621 0 .2637-.2637.71461-.0769.71461.296 0 .7835.63513 1.4187 1.41862 1.4187h.94756c.7835 0 1.41863-.6352 1.41863-1.4187 0-.373.45089-.5597.71459-.296.554.554 1.4523.554 2.0063 0l.67-.6701c.554-.554.554-1.4522 0-2.0062-.2636-.2636-.0769-.71452.296-.71452.7835 0 1.4187-.63512 1.4187-1.4186V7.5256c0-.7835-.6352-1.41863-1.4187-1.41863-.373 0-.5597-.45093-.296-.71464.554-.554.554-1.45222 0-2.00623l-.6701-.67004c-.554-.554-1.4522-.554-2.0062 0-.2637.26372-.71459.07694-.71459-.29599 0-.78347-.63513-1.41861-1.4186-1.41861h-.94762zM7.1069 2.42005c0-.23118.18741-.41859.41859-.41859h.94762c.23119 0 .4186.18742.4186.41861 0 1.26385 1.52809 1.89676 2.42169 1.0031.1635-.16348.4286-.16348.592 0l.6701.67004c.1635.16348.1635.42854 0 .59202-.8937.89369-.2607 2.42174 1.0031 2.42174.2312 0 .4187.18743.4187.41863v.94758c0 .23118-.1875.4186-.4187.4186-1.2637 0-1.8968 1.52802-1.0031 2.42162.1635.1635.1635.4285 0 .592l-.67.6701c-.1635.1635-.4286.1635-.5921 0-.8937-.8937-2.42169-.2607-2.42169 1.0031 0 .2312-.18743.4187-.41863.4187h-.94756c-.23119 0-.41862-.1875-.41862-.4187 0-1.2638-1.52803-1.8968-2.42171-1.0031-.16348.1635-.42853.1635-.59201 0l-.67006-.67c-.16346-.1635-.16347-.4286.00001-.592.89365-.8937.26077-2.42172-1.00308-2.42172-.23118 0-.41859-.18741-.41859-.41859v-.9476c0-.2312.18743-.41862.41862-.41862 1.26389 0 1.89676-1.5281 1.0031-2.42176-.16349-.16349-.16349-.42857-.00001-.59205l.67004-.67003c.16347-.16347.42852-.16348.592 0 .89364.89365 2.42169.26078 2.42169-1.00308z"
        fill={isHighlighted ? '#FD780D' : '#949CA8'}
      />
    </svg>
  )
}

GearSmall.defaultProps = {
  isHighlighted: false,
}

export default GearSmall
