import React, { FC } from 'react'
import css from './button.module.css'

export enum ButtonSize {
  Default = 'medium',
  Large = 'large',
  Small = 'small',
}

interface Props {
  className?: string
  label: string
  size?: ButtonSize
  onClick?: Function
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  isLoading?: boolean
  loadingLabel?: string
  secondary?: boolean
}

const Button: FC<Props> = ({
  className,
  label,
  size,
  onClick,
  type,
  disabled,
  isLoading,
  loadingLabel,
  secondary,
}) => {
  let sizeClass = css.medium

  switch (size) {
    case ButtonSize.Default:
      sizeClass = css.medium
      break

    case ButtonSize.Large:
      sizeClass = css.large
      break

    case ButtonSize.Small:
      sizeClass = css.small
      break
  }

  return (
    <button
      type={type || 'button'}
      className={`${css.host} ${className} ${
        secondary ? css.secondary : css.default
      } ${sizeClass}`}
      onClick={(evt) => onClick && onClick(evt)}
      disabled={disabled || isLoading}
    >
      {isLoading ? loadingLabel : label}
    </button>
  )
}

Button.defaultProps = {
  size: ButtonSize.Default,
  disabled: false,
}

export default Button
