import React, { FC } from 'react'

interface Props {
  isHighlighted?: boolean
}

const AddIcon: FC<Props> = ({ isHighlighted }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5C4.96243 2.5 2.5 4.96243 2.5 8c0 3.0376 2.46243 5.5 5.5 5.5 3.0376 0 5.5-2.4624 5.5-5.5 0-3.03757-2.4624-5.5-5.5-5.5zM1.5 8c0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5 0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5zm7-3.5v3h3v1h-3v3h-1v-3h-3v-1h3v-3h1z"
        fill={isHighlighted ? 'hsla(0, 0%, 4%, 1)' : 'hsla(0, 0%, 65%, 1)'}
      />
    </svg>
  )
}

AddIcon.defaultProps = {
  isHighlighted: false,
}

export default AddIcon
