import React, { FC } from 'react'

interface Props {
  isHighlighted?: boolean
}

const Gear: FC<Props> = ({ isHighlighted }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4 0-2.20914 1.79086-4 4-4 2.2091 0 4 1.79086 4 4zm-1 0c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3z"
        fill={isHighlighted ? '#FD780D' : '#949CA8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3076 2.00031c-1.0178 0-1.84299.82516-1.84299 1.84302 0 .75106-.90805 1.12718-1.43911.59612-.71974-.71974-1.88667-.71974-2.60642 0l-.97955.97955c-.71977.71977-.71972 1.88676.00003 2.60651.53111.53111.15493 1.43917-.59605 1.43917-1.01787 0-1.84302.82512-1.84302 1.84302v1.3876c0 1.0166.82411 1.8408 1.84071 1.8408.74885 0 1.1241.9058.59409 1.4355-.71934.719-.71952 1.8852-.00009 2.6042l.98035.9798c.72021.7199 1.88749.7199 2.6077 0 .53127-.5309 1.44136-.1546 1.44136.5987 0 1.0188.82589 1.8457 1.84519 1.8457h1.3832c1.0178 0 1.843-.8251 1.843-1.8429 0-.751.908-1.1272 1.439-.5961.7198.7197 1.8867.7197 2.6064 0l.9797-.9797c.7197-.7197.7197-1.8866 0-2.6063-.531-.5309-.155-1.4389.5961-1.4389 1.0178 0 1.843-.8252 1.843-1.843v-1.385c0-1.0181-.8254-1.84342-1.8435-1.84342-.7515 0-1.1278-.90875-.5962-1.44007.7204-.72.7205-1.8878.0001-2.60782l-.9794-.97886c-.7199-.71948-1.8867-.71892-2.6063.00028-.5314.53114-1.4389.15452-1.4389-.59529 0-1.01765-.825-1.84261-1.8426-1.84261h-1.3858zm-.843 1.84302c0-.46559.3774-.84302.843-.84302h1.3858c.4653 0 .8426.37725.8426.84261 0 1.64212 1.9855 2.46234 3.1458 1.30259.3294-.32921.8633-.32922 1.1924-.00028l.9794.97885c.3296.32944.3296.86378 0 1.19323-1.1617 1.16113-.3395 3.14739 1.3031 3.14739.4659 0 .8435.3776.8435.8434v1.385c0 .4655-.3774.843-.843.843-1.6418 0-2.4643 1.985-1.3032 3.146.3291.3292.3291.8629 0 1.1921l-.9797.9797c-.3292.3292-.863.3292-1.1922 0-1.161-1.161-3.1461-.3388-3.1461 1.3032 0 .4655-.3774.8429-.843.8429h-1.3832c-.4665 0-.8452-.3787-.8452-.8457 0-1.6419-1.98544-2.4681-3.14826-1.3059-.32974.3295-.86414.3295-1.19388 0l-.98035-.9799c-.32856-.3284-.32855-.8611.00009-1.1896 1.15989-1.1592.33938-3.1428-1.301-3.1428-.46431 0-.84071-.3764-.84071-.8408v-1.3876c0-.4656.37743-.843.84302-.843 1.64201 0 2.46415-1.9853 1.30315-3.1463-.32924-.32925-.32924-.86307-.00002-1.19229l.97955-.97955c.32922-.32922.86299-.32922 1.1922 0 1.16104 1.16104 3.14621.33871 3.14621-1.30323z"
        fill={isHighlighted ? '#FD780D' : '#949CA8'}
      />
    </svg>
  )
}

Gear.defaultProps = {
  isHighlighted: false,
}

export default Gear
