import { FC } from 'react'

import { ListItemString } from '../../scenes/Home/scenes/Dashboard'

import css from './list-item.module.css'

interface Item {
  category?: string
  subCategory?: string
  image?: string
  brand?: string
  product?: string
  clicks?: number | null
  link?: string
}

interface Props {
  id: number
  item?: Item
  small?: boolean
  loading?: boolean
  listItemStrings?: ListItemString
}

const ListItem: FC<Props> = ({ id, item, small, loading, listItemStrings }) => {
  return (
    <div className={css.host}>
      {!loading && !small && item && (
        <div className={css.itemRow}>
          <div className={css.rowLeftSide}>
            <div className={css.key}>{id + 1}</div>
            <div className={css.imageContainer}>
              <img
                src={item.image}
                alt={`${item.product} - ${item.brand} - ${item.category} - ${item.subCategory}`}
                className={css.image}
              />
            </div>
            <div className={css.itemContainer}>
              <p className={css.categoryContainer}>
                <span className={css.itemCategory}>{item.category}</span> /{' '}
                {item.subCategory}
              </p>
              <p className={css.productContainer}>
                <span className={css.itemBrand}>{item.brand}</span>{' '}
                {item.product}
              </p>
            </div>
          </div>
          <div className={css.rowRightSide}>
            <div className={css.clicks}>
              {item.clicks} {listItemStrings?.clicksLabel}
            </div>
            <div className={css.itemLinkContainer}>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={css.link}
              >
                {listItemStrings?.viewOnWebsiteLabel}
              </a>
            </div>
          </div>
        </div>
      )}

      {!loading && small && item && (
        <div className={css.smallItemRow}>
          <div className={css.smallRowLeftSide}>
            <div className={css.key}>{id + 1}</div>
            <div className={css.itemContainer}>
              <p className={css.productContainer}>
                <span className={css.smallItemBrand}>{item.subCategory}</span>
              </p>
            </div>
          </div>
          <div className={css.smallRowRightSide}>
            <p className={css.smallClicks}>
              {item.clicks} {listItemStrings?.clicksLabel}
            </p>
          </div>
        </div>
      )}

      {loading && (
        <div className={`${css.itemRow} ${css.loading}`}>
          <div className={css.rowLeftSide}>
            <div className={css.key} />
            <div className={css.imageContainer} />
            <div className={css.itemContainer} />
          </div>
          <div className={css.rowRightSide}>
            <div className={css.clicks} />
            <div className={css.itemLinkContainer} />
          </div>
        </div>
      )}
      <hr className={css.rowSplit} />
    </div>
  )
}

export default ListItem
