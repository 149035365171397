import React, { FC } from 'react'

interface Props {
  isDisabled?: boolean
}

const CircleSmall: FC<Props> = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13c2.7614 0 5-2.2386 5-5 0-2.76142-2.2386-5-5-5-2.76142 0-5 2.23858-5 5 0 2.7614 2.23858 5 5 5zm0 1c3.3137 0 6-2.6863 6-6 0-3.31371-2.6863-6-6-6-3.31371 0-6 2.68629-6 6 0 3.3137 2.68629 6 6 6z"
        fill="hsla(0, 0%, 46%, 1)"
      />
    </svg>
  )
}

CircleSmall.defaultProps = {
  isDisabled: false,
}

export default CircleSmall
