/**
 * we opted to not parametrize the component as it won't actually be reused
 * so we import the react router, translations, icons, ... directly
 */

import React, { FC, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import LogoHorizontal from '../Logos/LogoHorizontal'

import css from './sidebar.module.css'

interface CategoryProps {
  label: string
}

const SidebarCategory: FC<CategoryProps> = ({ label }) => {
  return <div className={css.category}>{label}</div>
}

interface OptionProps {
  label: string
  icon: ReactElement
  link: string
}

const SidebarOption: FC<OptionProps> = ({ label, icon, link }) => {
  return (
    <NavLink
      to={link}
      exact
      activeClassName={css.optionActive}
      className={css.option}
    >
      <div className={css.icon}>{icon}</div>
      <span className={css.label}>{label}</span>
    </NavLink>
  )
}

interface Props {
  sections: {
    title?: string
    options: {
      title: string
      icon: ReactElement
      link: string
    }[]
  }[]
}

const Sidebar: FC<Props> = ({ sections }) => (
  <div className={css.host}>
    <div className={css.logo}>
      <LogoHorizontal mode={'light'} />
    </div>

    {sections.map(({ title, options }, index) => (
      <div key={index} className={css.section}>
        {title && <SidebarCategory label={title} />}

        {options.map(({ icon, title, link }, index) => (
          <SidebarOption key={index} icon={icon} label={title} link={link} />
        ))}
      </div>
    ))}
  </div>
)

Sidebar.defaultProps = {}

export default Sidebar
