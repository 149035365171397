import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

import {
  useFeedsAllQuery,
  useProductDeleteAllMutation,
  useProductDeleteMutation,
  useProductLazyQuery,
  useProductsLazyQuery,
} from '../../../../queries/autogenerate/hooks'

import {
  SourceCreateMutationVariables,
  SourceEditMutationVariables,
  ProductDeleteAllMutation,
  ProductDeleteMutationVariables,
} from '../../../../queries/autogenerate/operations'

import { requestPageSize } from '../../../../helpers/Constants'
import { parseBackendDate } from '../../../../helpers/Time'

import { createStringWithPriceRange } from '../../../../helpers/Functions'

import { showErrorToast, showSuccessToast } from '../../../../services/Toaster'

import Button, { ButtonSize } from '../../../../components/Button'
import CenteredModal, { ModalSize } from '../../../../components/CenteredModal'
import Checkbox from '../../../../components/Checkbox'
import CheckmarkIcon from '../../../../components/Icons/Checkmark'
import CloseIcon from '../../../../components/Icons/Close'
import ImagesCarousel from '../../../../components/ImagesCarousel'
import SourcesTable from '../../components/SourcesTable'
import Table from '../../../../components/Table'
import TablePlaceholder from '../../../../components/TablePlaceholder'
import TableActionLinks from '../../../../components/TableActionLinks'
import SearchBox, { SearchBoxSize } from '../../../../components/SearchBox'

import css from './products.module.css'

const Products = () => {
  const { t } = useTranslation([
    'products',
    'general',
    'form',
    'table',
    'feeds',
  ])
  const history = useHistory()

  /*
   * STATE
   */
  const [currentPage, setCurrentPage] = useState(1)
  const [inputPageNumber, setInputPageNumber] = useState(currentPage)

  const [isViewingProduct, setIsViewingProduct] = useState(false)
  const [viewingProductId, setViewingProductId] = useState('')

  const [searchTerm, setSearchTerm] = useState('')

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([])

  const [bulkAction, setBulkAction] = useState('')

  const [getSearchProducts, { data, loading, error }] = useProductsLazyQuery({
    variables: {
      page: currentPage,
      pageSize: requestPageSize,
    },
  })

  const [getProduct, { data: productData, loading: loadingProduct }] =
    useProductLazyQuery({ variables: { id: viewingProductId } })

  const { data: feedsData } = useFeedsAllQuery()

  const availableFeeds = feedsData?.feedsAll.map(
    (feed: { id: string; merchant: string; network: string }) => ({
      id: feed.id,
      merchant: feed.merchant,
      network: feed.network,
    })
  )

  const [productDeleteMutation] = useProductDeleteMutation({
    onCompleted: () => {
      showSuccessToast(t('products:product-delete-successful'))
    },
    onError: (error: ApolloError) => {
      showErrorToast(error.message)
    },
  })

  const [productDeleteAllMutation, { loading: isLoadingBulkAction }] =
    useProductDeleteAllMutation({
      onCompleted: (data: ProductDeleteAllMutation) => {
        showSuccessToast(t('products:products-delete-successful'))

        setSelectedProductIds([])
        setBulkAction('')
        setSearchTerm('')
        history.push({})
        getSearchProducts()
      },
      onError: (error: ApolloError) => {
        showErrorToast(error.message)
      },
    })

  /*
   * HELPERS
   */
  const handleFirstPageClick = () => {
    setCurrentPage(1)
    setInputPageNumber(1)
  }

  const handlePreviousPageClick = () => {
    setCurrentPage((oldPage) => oldPage - 1)
    setInputPageNumber((oldPage) => oldPage - 1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((oldPage) => oldPage + 1)
    setInputPageNumber((oldPage) => oldPage + 1)
  }

  const handleLastPageClick = () => {
    setCurrentPage(data!.products.totalPages!)
    setInputPageNumber(data!.products.totalPages!)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (inputPageNumber <= 0) {
        handlePageChange(1)
        setInputPageNumber(1)
      } else {
        handlePageChange(inputPageNumber)
      }
    }
  }

  const handleOnSearch = () => {
    let params = new URLSearchParams(document.location.search)

    if (searchTerm === '') {
      params.delete('search')
    } else {
      params.set('search', searchTerm)
    }

    history.push({ search: params.toString() })
  }

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const toggleCheckbox = (id: string) => {
    if (selectedProductIds.includes(id)) {
      const updatedArray = selectedProductIds.filter(
        (productId) => productId !== id
      )
      setSelectedProductIds(updatedArray)
    } else {
      setSelectedProductIds([...selectedProductIds, id])
    }
  }

  const toggleAllCheckboxes = () => {
    if (
      selectedProductIds.length === requestPageSize ||
      selectedProductIds.length === data?.products?.entries.length
    ) {
      setSelectedProductIds([])
    } else {
      if (data?.products.entries) {
        const idArray = data?.products.entries.map((product) => product.id)
        setSelectedProductIds(idArray)
      }
    }
  }

  const handleDeleteProduct = (values: ProductDeleteMutationVariables) => {
    productDeleteMutation({ variables: values })
  }

  const handleActionSubmit = () => {
    productDeleteAllMutation({
      variables: {
        ids: selectedProductIds,
      },
    })
    setSelectedProductIds([])
    setBulkAction('')
  }

  const createSourceName = (sources: Array<any>) => {
    let merchant = undefined
    let network = '-'

    if (sources.length === 1) {
      const feed = availableFeeds!.find((feed) => feed.id === sources[0].feedId)
      merchant = feed!.merchant
      network = feed!.network
    }

    if (sources.length > 1) {
      network = `${sources!.length} ${t('table:sources-label')}`
    }

    return (
      <>
        {merchant && <p className={css.boldText}>{merchant}</p>}
        <p>{network}</p>
      </>
    )
  }

  /**
   *
   * @param id The id of the product interacted with
   * @param actionType parameter used to distinguish action ('delete', 'edit', etc.) so we can trigger the proper functionality
   */
  const defineActionToTake = (id: string, actionType: string) => {
    if (actionType === 'delete') {
      handleDeleteProduct({ id })
    }

    if (actionType === 'view') {
      setViewingProductId(id)
      setIsViewingProduct(true)
      getProduct({ variables: { id } })
    }

    if (actionType === 'edit') {
      history.push(`/products/product?id=${id}`)
    }
  }

  const tableActions = [
    {
      label: t('general:view'),
      functionToTrigger: defineActionToTake,
      actionType: 'view',
    },
    {
      label: t('general:edit'),
      functionToTrigger: defineActionToTake,
      actionType: 'edit',
    },
    {
      label: t('general:delete'),
      functionToTrigger: defineActionToTake,
      actionType: 'delete',
    },
  ]

  /*
   * USE EFFECTS
   */

  /**
   * When we delete an entry we do a new request
   * and the same happens when we delete the last entry of a page
   * By deleting the last entry of a page we need to now go
   * to the last page with results and while the API response is correct,
   * our `currentPage` and `inputPageNumeber` variables still need to be updated
   */
  useEffect(() => {
    if (data && currentPage > data.products.totalPages!) {
      setCurrentPage(data.products.totalPages!)
      setInputPageNumber(data.products.totalPages!)
    }
  }, [currentPage, data])

  const search = useLocation().search

  useEffect(() => {
    let urlSearch = new URLSearchParams(search).get('search')
    if (urlSearch && urlSearch !== '') {
      setSearchTerm(urlSearch)
    }
    getSearchProducts({
      variables: {
        page: currentPage,
        pageSize: requestPageSize,
        search: urlSearch,
      },
    })
  }, [currentPage, getSearchProducts, search])

  const handleBulkAction = (e: React.ChangeEvent<any>) => {
    setBulkAction(e.target.value)
  }

  const optionsBulk = [
    { title: t('general:delete'), value: t('general:delete') },
  ]

  return (
    <div className={css.host}>
      <div className={css.sectionHeader}>
        <div className={css.leftContent}>
          <h1 className={css.sectionTitle}>
            {t('products:products-title')}{' '}
            {data && `(${data.products.totalEntries})`}
          </h1>
          <Button
            type={'button'}
            label={t('general:add-new')}
            size={ButtonSize.Small}
            onClick={() => history.push('/products/product')}
            disabled={isLoadingBulkAction}
          />
        </div>
        <div className={css.rightContent}>
          <SearchBox
            size={SearchBoxSize.Large}
            placeholder={t('products:search-product-placeholder')}
            onClick={handleOnSearch}
            onChange={handleOnChangeSearch}
            value={searchTerm}
            inputName={'search'}
          />
        </div>
      </div>

      {(loading || data?.products.entries.length === 0) && (
        <TablePlaceholder
          numberOfPlaceholderColumns={5}
          numberOfPlaceholderRows={5}
          numberOfPlaceholderActions={1}
          isPaginated
          isEmpty={data?.products.entries.length === 0}
          emptyStatusText={t('table:table-empty-message')}
        />
      )}
      {error && (
        <p className={css.errorMessage}>{t('general:error-message')}</p>
      )}
      {!loading && !error && data && data?.products.entries.length !== 0 && (
        <Table
          labelBulk={t('general:yes')}
          disabledBulk={selectedProductIds.length === 0 || isLoadingBulkAction}
          onClickBulk={handleActionSubmit}
          placeholderBulk={t('table:actions-label')}
          nameBulk={'bulkAction'}
          valueBulk={bulkAction}
          optionsBulk={optionsBulk}
          handleBulk={handleBulkAction}
          pageNumber={currentPage}
          entriesPerPage={requestPageSize}
          totalEntries={data.products.totalEntries ?? 0}
          entityName={t('products-entity')}
          ofLabel={t('table:of-label')}
          isPaginated
          onPreviousPage={handlePreviousPageClick}
          onNextPage={handleNextPageClick}
          onFirstPage={handleFirstPageClick}
          onLastPage={handleLastPageClick}
          validateInputValue={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setInputPageNumber(Math.floor(+evt.target.value))
          }
          handleKeyDown={handleKeyDown}
          inputPageNumber={inputPageNumber}
        >
          <thead>
            <tr>
              <th>
                <Checkbox
                  name={'actions'}
                  value={true}
                  isChecked={
                    selectedProductIds.length === requestPageSize ||
                    selectedProductIds.length === data?.products?.entries.length
                  }
                  onChange={toggleAllCheckboxes}
                />
              </th>
              <th>{t('table:image-label')}</th>
              <th>{t('table:brand-label')}</th>
              <th>{t('table:name-label')}</th>
              <th>{t('table:price-label')}</th>
              <th>{t('table:sources-label')}</th>
              <th>{t('table:category-label')}</th>
              <th>{t('table:date-label')}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.products.entries.map(
              ({
                image,
                id,
                brand,
                name,
                category,
                insertedAt,
                isVisible,
                sources,
              }) => {
                return (
                  <tr key={id}>
                    <th>
                      <Checkbox
                        name={'actions'}
                        value={id}
                        isChecked={selectedProductIds.includes(id)}
                        onChange={(e) => toggleCheckbox(id)}
                      />
                    </th>
                    <td>
                      <div
                        className={css.image}
                        style={{ backgroundImage: `url(${image})` }}
                      />
                    </td>
                    <td>{brand?.name}</td>
                    <td>{name}</td>
                    <td className={css.noWrapText}>
                      {sources && sources.length > 0
                        ? createStringWithPriceRange(
                            sources as Array<{
                              price: number
                              currency: string
                            }>,
                            t('table:to-label')
                          )
                        : '-'}
                    </td>
                    <td>{sources && createSourceName(sources!)}</td>
                    <td>
                      {category && category.category && (
                        <>
                          <p className={css.boldText}>
                            {category.category.name}
                          </p>
                          <p>{category.name}</p>
                        </>
                      )}
                    </td>
                    <td>
                      <p>{parseBackendDate(insertedAt)}</p>
                      <p className={css.mutedText}>
                        {isVisible
                          ? t('general:published')
                          : t('general:disabled')}
                      </p>
                    </td>
                    <td className={css.noWrapText}>
                      <TableActionLinks linksArray={tableActions} itemId={id} />
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </Table>
      )}
      <CenteredModal
        isVisible={isViewingProduct && !loadingProduct}
        onClick={() => setIsViewingProduct(false)}
        size={ModalSize.Large}
      >
        <div className={css.modalHost}>
          <div className={css.viewingModalHeader}>
            <h2>{t('products:product-detail-title')}</h2>
            <button
              className={css.closeButton}
              onClick={() => setIsViewingProduct(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className={css.container}>
            <div className={css.imagesContainer}>
              <div
                className={`${css.imageContainer} ${css.mainImage}`}
                style={{
                  backgroundImage: `url(${productData?.product?.image})`,
                }}
              />
              {productData?.product?.images && (
                <ImagesCarousel images={productData.product.images} />
              )}
            </div>
            <div className={css.infoContainer}>
              <div className={css.basicTextInfo}>
                <p className={css.brandName}>
                  {productData?.product?.brand?.name}
                </p>
                <p className={css.productName}>{productData?.product?.name}</p>
              </div>
              {(productData?.product?.lastArrival ||
                productData?.product?.isVisible) && (
                <div className={css.innerContainer}>
                  {productData?.product?.lastArrival && (
                    <div className={css.attributeContainer}>
                      <CheckmarkIcon />
                      {t('products:latest-arrival-label')}
                    </div>
                  )}
                  {productData?.product?.isVisible && (
                    <div className={css.attributeContainer}>
                      <CheckmarkIcon />
                      {t('products:visible-on-the-website-label')}
                    </div>
                  )}
                </div>
              )}
              <div className={css.characteristicsContainer}>
                <div className={css.innerContainer}>
                  <p>
                    <span className={css.property}>
                      {t('products:condition-label')}:
                    </span>{' '}
                    {productData?.product?.condition}
                  </p>
                  <p>
                    <span className={css.property}>
                      {t('products:gender-label')}:
                    </span>{' '}
                    {productData?.product?.gender}
                  </p>
                  <p>
                    <span className={css.property}>
                      {t('products:color-label')}:
                    </span>{' '}
                    {productData?.product?.color}
                  </p>
                  <p>
                    <span className={css.property}>
                      {t('products:size-label')}:
                    </span>{' '}
                    {productData?.product?.sizes && (
                      <>
                        <span>
                          {productData?.product?.sizes[0].standard
                            ? `${productData.product.sizes[0].standard} / `
                            : ' - / '}
                        </span>
                        <span>
                          {productData?.product?.sizes[0].type
                            ? `${productData.product.sizes[0].type} / `
                            : ' - / '}
                        </span>
                        <span>
                          {productData.product.sizes[0].value ?? ' -'}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {productData?.product?.sources?.length !== 0 && (
            <div className={css.sourcesContainer}>
              <p className={css.sourcesTitle}>{t('feeds:sources-label')}</p>
              <div className={css.sourcesContainer}>
                {productData?.product?.sources && (
                  <SourcesTable
                    arrayOfSources={
                      productData?.product?.sources as Array<
                        | SourceCreateMutationVariables
                        | SourceEditMutationVariables
                      >
                    }
                    displayActions={false}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </CenteredModal>
    </div>
  )
}

export default Products
