import { TFunction } from 'react-i18next'

import Account from '../scenes/Home/scenes/account'
import Products from '../scenes/Home/scenes/Products'
import Categories from '../scenes/Home/scenes/Products/Categories'
import Dashboard from '../scenes/Home/scenes/Dashboard'
import Brands from '../scenes/Home/scenes/Products/Brands'
import Product from '../scenes/Home/scenes/Products/Product'
import Manual from '../scenes/Home/scenes/Matching/Manual'
import Suggestions from '../scenes/Home/scenes/Matching/Suggestions'
import Feeds from '../scenes/Home/scenes/Feeds'

/* Icons */
import Gear from '../components/Icons/Gear'
import GearSmall from '../components/Icons/GearSmall'
import DashboardSmall from '../components/Icons/DashboardSmall'
import DashboardBig from '../components/Icons/DashboardBig'
import List from '../components/Icons/List'
import ListSmall from '../components/Icons/ListSmall'

const extraOptions = (t: TFunction<string[]>) => [
  {
    breadCrumbtitle: t('general:account-settings-title'),
    showingTitle: t('general:account-settings-title'),
    link: '/account-settings',
    icon: <GearSmall />,
    iconBig: <Gear />,
    component: <Account />,
  },
  {
    breadCrumbtitle: t('general:product-title'),
    showingTitle: t('general:product-title'),
    link: '/products/product',
    icon: <ListSmall />,
    iconBig: <List />,
    component: <Product />,
  },
]

// contains all the options shown in the sidebar
export const sidebarOptions = (t: TFunction<string[]>) => [
  // Dashboard
  {
    options: [
      {
        title: t('general:dashboard-title'),
        showingTitle: t('general:dashboard-title'),
        link: '/dashboard',
        icon: <DashboardSmall />,
        iconBig: <DashboardBig />,
        component: <Dashboard />,
      },
    ],
  },
  // Products: List /  Matching /  Categories /  Brands

  {
    title: t('general:products-title'),
    options: [
      {
        title: t('general:list-title'),
        showingTitle: t('general:products-title'),
        link: '/products/',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Products />,
      },
      {
        title: t('general:categories-title'),
        showingTitle: t('general:categories-title'),
        link: '/products/categories',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Categories />,
      },
      {
        title: t('general:brands-title'),
        showingTitle: t('general:brands-title'),
        link: '/products/brands',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Brands />,
      },
    ],
  },
  {
    title: t('general:matching-title'),
    options: [
      {
        title: t('general:matching-title'),
        showingTitle: t('general:manual-title'),
        link: '/matching/manual',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Manual />,
      },
      {
        title: t('general:suggestions-title'),
        showingTitle: t('general:suggestions-title'),
        link: '/matching/suggestions',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Suggestions />,
      },
    ],
  },
  {
    title: t('general:feeds-title'),
    options: [
      {
        title: t('general:list-title'),
        showingTitle: t('general:feeds-title'),
        link: '/feeds/list',
        icon: <ListSmall />,
        iconBig: <List />,
        component: <Feeds />,
      },
    ],
  },
]

// create an array with all the possible routes, including the ones that aren't in the sidebar
export const allRoutes = (t: TFunction<string[]>) =>
  sidebarOptions(t)
    .reduce(
      (
        accumulator: {
          showingTitle: string
          breadCrumbtitle: string
          icon: JSX.Element
          iconBig: JSX.Element
          link: string
          component?: JSX.Element
        }[],
        { options }
      ) => {
        // add proper breadcrumb title to each option
        const updatedOptions = options.map((option) => ({
          ...option,
          breadCrumbtitle: option.title,
          showingTitle: option.showingTitle,
        }))

        return accumulator.concat(updatedOptions)
      },
      []
    )
    .concat(extraOptions(t))
