import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import css from './feedStatus.module.css'

interface Props {
  isDisabled?: boolean
  isLoading?: boolean
  statusCode: string
  totalCases?: number
  isBig?: boolean
}

const FeedStatus: FC<Props> = ({
  isDisabled,
  isLoading,
  statusCode,
  isBig,
  totalCases,
}) => {
  const { t } = useTranslation(['feeds'])

  //  Each feed has separate status and isEnabled properties, hence why the  existance of the isDisabled prop
  const status = isDisabled ? 'disabled' : statusCode

  // Define whether the status container should occupy all the available width, and have the text left-aligned
  // or fit to the content and be centred
  const containerSize = isBig
    ? css.largeStatusContainer
    : css.smallStatusContainer

  let data = {
    style: `${containerSize} ${css.allGoodContainer}`,
    label: statusCode,
  }

  switch (status) {
    case 'disabled':
      data = {
        style: `${containerSize} ${css.disabledContainer}`,
        label: t('feeds:disabled-label'),
      }
      break

    case 'noInfo':
      data = {
        style: `${containerSize} ${css.disabledContainer}`,
        label: t('feeds:no-info-label'),
      }
      break

    case 'start':
      data = {
        style: `${containerSize} ${css.inProgressContainer}`,
        label: t('feeds:updating-label'),
      }
      break

    case 'error':
      data = {
        style: `${containerSize} ${css.notWorkingContainer}`,
        label: t('feeds:not-working-label'),
      }
      break
    case 'finish':
      data = {
        style: `${containerSize} ${css.allGoodContainer}`,
        label: t('feeds:all-good-label'),
      }
      break
  }

  return isLoading ? (
    <div className={css.loading}></div>
  ) : (
    <div className={data.style}>{`${data.label} ${
      isBig ? `(${totalCases})` : ''
    }`}</div>
  )
}

export default FeedStatus
