import React, { FC, useRef } from 'react'
import css from './radioButton.module.css'

import CircleSmall from '../Icons/CircleSmall'
import CircleFilledSmall from '../Icons/CircleFilledSmall'

interface Props {
  name: string
  label?: String
  value: any
  isDisabled?: boolean
  isChecked?: boolean
  onChange?: (e: React.ChangeEvent<any>) => void
}

const RadioButton: FC<Props> = ({
  label,
  isChecked,
  onChange,
  isDisabled,
  name,
  value,
}) => {
  const radioButtonRef = useRef<HTMLInputElement>(null)

  return (
    <div
      onClick={() => {
        radioButtonRef.current?.click()
      }}
      className={`${css.host}  ${isDisabled && css.disabled}`}
    >
      <input
        ref={radioButtonRef}
        type="radio"
        disabled={isDisabled}
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
      />

      {isChecked ? (
        <CircleFilledSmall />
      ) : (
        <CircleSmall isDisabled={isDisabled} />
      )}

      {label && <span className={css.label}>{label}</span>}
    </div>
  )
}

RadioButton.defaultProps = {
  isDisabled: false,
}

export default RadioButton
