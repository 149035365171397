import React, { FC } from 'react'
import css from './searchbox.module.css'

import Input from '../Input'
import Button, { ButtonSize } from '../Button'
import { useTranslation } from 'react-i18next'

export enum SearchBoxSize {
  Large = 'large',
  Small = 'small',
}

interface Props {
  size: SearchBoxSize
  inputName: string
  placeholder: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onClick: Function
  disabled?: boolean
}

const SearchBox: FC<Props> = ({
  size,
  inputName,
  placeholder,
  value,
  onChange,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation('categories')

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  return (
    <div
      className={size === SearchBoxSize.Large ? css.largeHost : css.smallHost}
    >
      <div className={css.searchInput}>
        <Input
          name={inputName}
          value={value}
          type={'text'}
          placeholderText={placeholder}
          isBig={false}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        <Button
          type={'button'}
          label={t('search-label')}
          size={ButtonSize.Small}
          onClick={onClick}
          secondary
          disabled={disabled}
        />
      </div>
    </div>
  )
}

SearchBox.defaultProps = {
  size: SearchBoxSize.Large,
}

export default SearchBox
