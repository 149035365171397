import React, { FC, MouseEventHandler, useRef } from 'react'

import useDetectOutsideClick from '../../hooks/useDetectClickOutside'

import css from './pillAvatar.module.css'

import Avatar from '../Avatar'

import ChevronDownSmall from '../Icons/ChevronDownSmall'
import ChevronUpSmall from '../Icons/ChevronUpSmall'

interface Props {
  avatarName: string
  avatarSurname: string
  imageUrl?: string
  links: { onClick?: MouseEventHandler; label: string }[]
}

const PillAvatar: FC<Props> = ({
  avatarName,
  avatarSurname,
  imageUrl,
  links,
}) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useDetectOutsideClick(dropdownRef, false)

  const switchOpenState = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={css.host}>
      <button
        className={`${css.button} ${isOpen && css.focus}`}
        onClick={switchOpenState}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" />
        ) : (
          <Avatar name={avatarName} />
        )}
        <span className={css.label}>
          {avatarName} {avatarSurname}
        </span>
        {isOpen ? <ChevronUpSmall /> : <ChevronDownSmall />}
      </button>

      <div
        ref={dropdownRef}
        className={`${css.dropdown} ${isOpen && css.isOpen}`}
      >
        {links.map(({ onClick, label }, index) => (
          <p
            key={index}
            className={css.option}
            onClick={(evt) => {
              switchOpenState()
              onClick && onClick(evt)
            }}
          >
            {label}
          </p>
        ))}
      </div>
    </div>
  )
}

PillAvatar.defaultProps = {}

export default PillAvatar
