import React, { FC } from 'react'
import DropdownBulk from '../DropdownBulk'

import css from './bulkActions.module.css'

interface Props {
  labelBulk: string
  disabledBulk: boolean
  onClickBulk: Function
  optionsBulk?: { title: string; value: any }[]
  placeholderBulk?: string
  nameBulk?: string
  valueBulk?: string
  handleBulk?: (e: React.ChangeEvent<any>) => void
}

const BulkActions: FC<Props> = ({
  labelBulk,
  disabledBulk: disabled,
  onClickBulk,
  optionsBulk,
  placeholderBulk,
  nameBulk,
  valueBulk,
  handleBulk,
}) => {
  return (
    <div className={css.host}>
      <DropdownBulk
        value={valueBulk}
        name={nameBulk}
        placeholderText={placeholderBulk}
        options={optionsBulk}
        onChange={handleBulk}
      />

      {valueBulk !== '' && (
        <button
          type={'button'}
          disabled={disabled}
          className={css['delete']}
          onClick={() => onClickBulk()}
        >
          {labelBulk}
        </button>
      )}
    </div>
  )
}

BulkActions.defaultProps = {
  disabledBulk: false,
}

export default BulkActions
