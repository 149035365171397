import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

// TODO: update the url's according to the environment
const serverUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
      return 'https://staging.api.styleyield.com/v1/graphql'

    case 'production':
      return 'https://api.styleyield.com/v1/graphql'

    default:
      return 'http://localhost:4000/v1/graphql'
  }
}

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }

  return forward(operation).map((data) => data)
})

const httpLink = createHttpLink({
  uri: serverUrl(),
  credentials: 'include',
})

const httpLinkWithTypenameHandling = ApolloLink.from([cleanTypeName, httpLink])

const client = new ApolloClient({
  link: httpLinkWithTypenameHandling,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

export default client
